import { getActiveBrand } from "../../../utils/brand/brand";
import type {
  LocalisedOfferDataMetaLinks,
  OfferDataMeta,
  OfferDataMetaLinks,
  OfferTitleAndTermsAndConditions,
} from "../../../types/api/offer";
import { isEmptyObject } from "../../../utils/object/object";
import { getSiteIds } from "../../../utils/http/offers";
import { FP_BRAND } from "../../../constants";
import { gaEvent } from "../../../utils/gtag/gtag";

export const isLocalisedOffer = (
  offer: {
    meta: {
      location?: string[];
    };
  },
  locale: string
): boolean => {
  const upperCaseLocale = locale.toUpperCase();
  const localisedOfferLocations = offer.meta.location;

  if (!localisedOfferLocations) return false;

  return localisedOfferLocations.includes(upperCaseLocale);
};

export const localisedOfferTitleAndTermsAndConditions = (
  offer: {
    meta: {
      title_and_tc_locations?: Record<string, OfferTitleAndTermsAndConditions>;
      title: string;
      terms_and_conditions: string;
      location?: string[];
    };
  },
  locale: string
): OfferTitleAndTermsAndConditions => {
  const upperCaseLocale = locale.toUpperCase();

  // Destructure default and localised titles and terms and conditions
  const {
    title_and_tc_locations: titleAndTcLocations,
    title,
    terms_and_conditions: termsAndConditions,
  } = offer.meta;

  // Returns default title and terms and conditions if there are no localised versions
  if (!titleAndTcLocations || !isLocalisedOffer(offer, locale))
    return { title, terms_and_conditions: termsAndConditions.trim() };

  // Sets title and terms and condtions as default value if the localised version is null or an empty string
  const offerTitle = titleAndTcLocations[upperCaseLocale].title
    ? titleAndTcLocations[upperCaseLocale].title
    : title;
  const offerTermsAndconditions = titleAndTcLocations[upperCaseLocale]
    .terms_and_conditions
    ? titleAndTcLocations[upperCaseLocale].terms_and_conditions
    : termsAndConditions;

  return {
    title: offerTitle,
    terms_and_conditions: offerTermsAndconditions.trim(),
  };
};

export const localisedOfferLink = (
  offer: {
    meta: {
      links?: OfferDataMetaLinks;
      sidebar_links?: OfferDataMetaLinks;
    };
  },
  locale: string,
  sidebar?: boolean
): string | undefined => {
  const brand = getActiveBrand();
  const upperCaseLocale = locale.toUpperCase();
  const { links, sidebar_links: sidebarLinks } = offer.meta;

  // If localised links are added in the CMS for standard or sidebar offers, an optional key is added to the data
  // in the format of {brand}_locations. The value of which is an object with localised links for each locale
  const localisedKey =
    `${brand}_locations` as keyof LocalisedOfferDataMetaLinks;

  // Returns localised version of link for a sidebar offer if there is a localised version of the offer link
  if (sidebar && sidebarLinks?.[localisedKey]?.[upperCaseLocale]) {
    return sidebarLinks[localisedKey]?.[upperCaseLocale];
  }

  // Returns default link for sidebar offer if there is no localised version of the offer link
  if (sidebar && sidebarLinks?.[brand]) {
    return sidebarLinks[brand];
  }

  // Returns localised version of link for a non-sidebar offer if there is a localised version of the offer link
  if (links?.[localisedKey]?.[upperCaseLocale]) {
    return links[localisedKey]?.[upperCaseLocale];
  }

  // Returns undefined if no links are added in the CMS because offer.meta.links is an optional property
  if (!links || isEmptyObject(links)) return;

  // Returns default link for -nonsidebar offer if there is no localised version of the offer link
  return links[brand];
};

export const getBookmakerLogo = (bookmaker: string): string =>
  `https://static.teamfa.com/accatracker/bookmaker/${bookmaker}.png`;

export const localisedOfferScoreAndFeatures = (
  offer: {
    meta: Pick<
      OfferDataMeta,
      | "score"
      | "feature1"
      | "feature2"
      | "feature3"
      | "score_and_features_locations"
      | "location"
    >;
  },
  locale: string
): {
  score: string;
  feature1: string;
  feature2: string;
  feature3: string;
} => {
  const upperCaseLocale = locale.toUpperCase();

  // Destructure default and localised score and features
  const { score, feature1, feature2, feature3, score_and_features_locations } =
    offer.meta;

  // Return default score and features if the offer is not localised
  if (!isLocalisedOffer(offer, locale)) {
    return { score, feature1, feature2, feature3 };
  }

  const localisedScoreAndFeatures =
    score_and_features_locations[upperCaseLocale];

  // Sets features to localised versions if the first localised feature exists
  const features = localisedScoreAndFeatures.feature1
    ? {
        feature1: localisedScoreAndFeatures.feature1,
        feature2: localisedScoreAndFeatures.feature2,
        feature3: localisedScoreAndFeatures.feature3,
      }
    : { feature1, feature2, feature3 };

  // Returns the localised score if it exists or the default score if not, in addition to the features
  return {
    score: localisedScoreAndFeatures.score || score,
    ...features,
  };
};

function handleErrors<A extends unknown[]>(
  p: (...args: A) => Promise<void>
): (...args: A) => void {
  return (...args: A) => {
    p(...args).catch((err) => {
      console.error("Error thrown asynchronously", err);
    });
  };
}

export const gaOfferPressedEvent = handleErrors(
  async ({
    offer,
    sidebar,
  }: {
    offer: {
      bookmaker: {
        name_cleansed: string;
        name: string;
      };
      meta: {
        title?: string;
      };
    };
    sidebar?: boolean;
  }): Promise<void> => {
    const { title: offerTitle } = offer.meta;

    if (!offerTitle) return;

    const brand = getActiveBrand();
    const siteIdLocation = sidebar ? "sidebar_codes" : "offer_codes";
    const offerCategory = sidebar ? "Side Menu" : "Bookmaker Offer Widget";
    const { data } = await getSiteIds(siteIdLocation);
    const { name, name_cleansed: nameCleansed } = offer.bookmaker;

    const siteId = data[siteIdLocation]?.[nameCleansed];

    if (brand !== FP_BRAND) {
      const gaEventParams = {
        bookmaker: name,
        category: offerCategory,
        offer: offerTitle,
        site_id: siteId ?? "",
      };

      gaEvent("offer_pressed", gaEventParams);
    }

    // For the fp brand, no site_id's are available as we have no offer or sidebar codes coming back from the data
    // as all the sub affiliates have their own codes so it became too a big a job to include them, so send the
    // event without the site_id parameter.
    if (brand === FP_BRAND) {
      const gaEventParams = {
        bookmaker: name,
        location: offerCategory,
        offer: offerTitle,
      };
      gaEvent("offer_click", gaEventParams);
    }
  }
);
