import { CardWrapper } from "../../CardWrapper";
import { PpcAdTrackingBulletpointOfferCard } from "../../OfferCard/components/PpcAdTrackingBulletpointOfferCard";
import styles from "./PpcTable.module.scss";

export interface PpcTableOfferProps {
  ppcTableOffers: {
    bookmaker: {
      name_cleansed: string;
      name: string;
      tracking_parameter?: string;
    };
    meta: {
      cta?: string;
      link?: string;
      title?: string;
      terms_and_conditions?: string;
      score: string;
      features: string[];
    };
  }[];
  listHeader: string;
}

export function PpcTable({ ppcTableOffers, listHeader }: PpcTableOfferProps) {
  return (
    <div className={styles.ppcTable}>
      <CardWrapper listHeader={listHeader}>
        <>
          {ppcTableOffers.map((offer) => {
            return (
              <PpcAdTrackingBulletpointOfferCard
                key={offer.bookmaker.name}
                offer={offer}
              />
            );
          })}
        </>
      </CardWrapper>
    </div>
  );
}
