import Image from "next/image";
import { useState } from "react";
import { bannerLogoUrlFromName } from "../../../utils/media/media";
import { ExternalLinkIcon } from "../../../components/icons/ExternalLinkIcon";
import { TickIcon } from "../../../components/icons/TickIcon";
import { AngleDownIcon } from "../../../components/icons/AngleDownIcon";
import { AngleUpIcon } from "../../../components/icons/AngleUpIcon";
import { useCheckMobileDevice } from "../../../hooks/Window";
import { gaOfferPressedEvent } from "../utils/offer";
import breakpoints from "../../../styles/breakpoints.module.scss";
import { Score } from "./Score";
import styles from "./BulletpointOfferCard.module.scss";

export interface BulletpointOfferCardProps {
  offer: {
    bookmaker: {
      name_cleansed: string;
      name: string;
      tracking_parameter?: string;
    };
    meta: {
      cta?: string | null;
      link?: string;
      title?: string;
      terms_and_conditions?: string;
      score: string;
      features: string[];
    };
  };
}

export function BulletpointOfferCard({ offer }: BulletpointOfferCardProps) {
  const { bookmaker, meta } = offer;
  const { name_cleansed: nameCleansed, name } = bookmaker;
  const {
    cta,
    features,
    title,
    score,
    link,
    terms_and_conditions: termsAndConditions,
  } = meta;

  const [toggleFeaturesOnMobile, setToggleFeaturesOnMobile] = useState(false);
  const isMobileDevice = useCheckMobileDevice();

  return (
    <div className={styles.container} data-testid="bulletpoint-offer-card">
      <div className={styles.contentContainer}>
        <div className={styles.contentHeader}>
          <h3 className={styles.title}>{title}</h3>
          <Image
            alt={`${name} logo`}
            className={styles.brand}
            height={48}
            sizes={`(min-width: ${breakpoints.tablet}) 56px, 46px`}
            src={bannerLogoUrlFromName(nameCleansed)}
            width={48}
          />
        </div>
        <div className={styles.ctaAndScoreContainer}>
          <a
            className={styles.cta}
            href={link}
            onClick={() => {
              gaOfferPressedEvent({ offer });
            }}
            rel="noopener"
            target="_blank"
          >
            <span>{cta ? cta : "Claim Now"} </span>
            <ExternalLinkIcon />
          </a>
          <div className={styles.scoreContainer}>
            <Score score={Number(score)} />
            <span aria-label="offer rating" className={styles.scoreDisplay}>
              {score} / 5
            </span>
          </div>
        </div>
        <button
          className={`${styles.showFeatures} ${
            isMobileDevice && !toggleFeaturesOnMobile
              ? styles[`showFeatures--show`]
              : ""
          }`}
          onClick={() => {
            setToggleFeaturesOnMobile(!toggleFeaturesOnMobile);
          }}
          type="button"
        >
          <span>
            {toggleFeaturesOnMobile ? "Hide Features" : "Show Features"}
          </span>
          {toggleFeaturesOnMobile ? <AngleUpIcon /> : <AngleDownIcon />}
        </button>
        <ul
          aria-label="Feature List"
          className={`${styles.features} ${
            isMobileDevice && !toggleFeaturesOnMobile
              ? styles[`features--hide`]
              : ""
          }`}
        >
          {features.map((feature) =>
            !feature ? null : (
              <li key={feature}>
                <TickIcon />
                <span>{feature}</span>
              </li>
            )
          )}
        </ul>
      </div>
      <div className={styles.tAndCs}>
        <p>{termsAndConditions}</p>
      </div>
    </div>
  );
}
