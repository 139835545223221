import { ReactElement } from "react";

export const CheckdMediaLogo = (): ReactElement => (
  <svg
    width="483px"
    height="227px"
    viewBox="0 0 483 227"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Checkd Group Logo</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Landing" transform="translate(-720.000000, -352.000000)">
        <g id="::-Header" transform="translate(0.000000, -49.000000)">
          <g id="::-Content" transform="translate(535.000000, 401.000000)">
            <g id="Checkd-Group" transform="translate(185.000000, 0.000000)">
              <path
                d="M462.097179,94.0617849 C462.097179,79.5936129 451.304736,68.7052258 436.681058,68.7052258 C422.052846,68.7052258 411.363174,79.4957634 411.363174,94.0617849 C411.363174,108.632323 422.052846,119.421355 436.681058,119.421355 C451.304736,119.421355 462.097179,108.427591 462.097179,94.0617849 L462.097179,94.0617849 Z M476.933955,27.7093118 L476.933955,132.709312 L464.873501,132.709312 L462.82262,123.945011 C455.841763,130.645441 446.305239,134.636194 435.425139,134.636194 C411.960151,134.636194 395.086096,117.234043 395.086096,94.0663011 C395.086096,70.7916774 411.960151,53.5370538 435.425139,53.5370538 C445.383325,53.5370538 454.211033,56.8473763 460.987859,62.5286667 L460.987859,27.7093118 L476.933955,27.7093118 Z"
                id="Fill-199"
                fill="#FFFFFF"
              ></path>
              <polygon
                id="Fill-201"
                fill="#FFFFFF"
                points="366.531688 103.202129 358.594156 103.202129 379.406751 132.710516 398.909018 132.710516 371.993652 95.490086"
              ></polygon>
              <path
                d="M-1.37455119e-13,93.9816989 C-1.37455119e-13,70.8937419 17.5118388,53.5367527 40.7093199,53.5367527 C59.8866499,53.5367527 74.8745592,65.2350323 79.1848866,82.9668602 L63.3748111,82.9668602 C59.2926952,74.0761075 50.7838791,68.701914 40.6685139,68.701914 C26.6992443,68.701914 16.2377834,79.6956774 16.2377834,94.0208387 C16.2377834,108.344495 26.8413098,119.421054 40.6685139,119.421054 C50.8231738,119.421054 59.0478589,113.962559 63.2720403,104.562989 L79.3662469,104.562989 C75.1027708,122.621484 59.8231738,134.592237 40.6685139,134.592237 C17.5511335,134.592237 -1.37455119e-13,117.06514 -1.37455119e-13,93.9816989"
                id="Fill-203"
                fill="#FFFFFF"
              ></path>
              <path
                d="M158.456977,86.1263441 L158.456977,132.710215 L142.504836,132.710215 L142.504836,89.402043 C142.504836,75.4035484 136.498791,67.9067742 124.704332,67.9067742 C112.642368,67.9067742 103.86,77.6315054 103.86,91.3274194 L103.86,132.710215 L87.9123929,132.710215 L87.9123929,27.7102151 L103.86,27.7102151 L103.86,64.202043 C109.817683,57.6009677 118.689219,53.6568817 129.061511,53.6568817 C147.170327,53.6568817 158.456977,66.2117204 158.456977,86.1263441"
                id="Fill-205"
                fill="#FFFFFF"
              ></path>
              <path
                d="M183.810982,86.551914 L230.730378,86.551914 C227.864887,74.8129892 218.984282,67.8807312 207.164131,67.8807312 C195.164131,67.8807312 186.422569,75.0884731 183.810982,86.551914 L183.810982,86.551914 Z M246.721814,94.2428817 C246.721814,95.9830968 246.579748,97.7233118 246.392343,99.4635269 L183.408967,99.4635269 C185.405441,111.986753 194.455315,119.688258 207.164131,119.688258 C216.431637,119.688258 223.93995,115.419011 228.043224,108.370839 L244.832645,108.370839 C238.655819,124.689118 224.491587,134.591484 207.164131,134.591484 C184.478992,134.591484 167.293602,117.148688 167.293602,94.0622366 C167.293602,70.9336344 184.435164,53.5314839 207.164131,53.5314839 C230.911738,53.5314839 246.726348,71.7510538 246.726348,94.2428817 L246.721814,94.2428817 Z"
                id="Fill-207"
                fill="#FFFFFF"
              ></path>
              <path
                d="M250.798791,93.9816989 C250.798791,70.8937419 268.309118,53.5367527 291.511134,53.5367527 C310.685441,53.5367527 325.67335,65.2350323 329.983678,82.9668602 L314.178136,82.9668602 C310.09602,74.0761075 301.581159,68.701914 291.467305,68.701914 C277.498035,68.701914 267.036574,79.6956774 267.036574,94.0208387 C267.036574,108.344495 277.644635,119.421054 291.467305,119.421054 C301.621965,119.421054 309.84665,113.962559 314.075365,104.562989 L330.169572,104.562989 C325.901562,122.621484 310.626499,134.592237 291.467305,134.592237 C268.354458,134.592237 250.798791,117.06514 250.798791,93.9816989"
                id="Fill-209"
                fill="#FFFFFF"
              ></path>
              <path
                d="M343.537783,165.475333 L343.537783,173.575763 L338.993199,173.575763 C331.8733,173.575763 328.317128,177.622215 328.317128,185.176194 L328.317128,207.425656 L319.708564,207.425656 L319.708564,165.705656 L325.527204,165.705656 L326.988665,171.400495 C329.98262,167.445871 334.09194,165.475333 339.812343,165.475333 L343.537783,165.475333 Z"
                id="Fill-211"
                fill="#009FE3"
              ></path>
              <path
                d="M377.643929,186.566108 C377.643929,178.581591 371.944685,172.879226 364.013199,172.879226 C356.086247,172.879226 350.387003,178.581591 350.387003,186.566108 C350.387003,194.550624 356.086247,200.252989 364.013199,200.252989 C371.944685,200.252989 377.643929,194.550624 377.643929,186.566108 L377.643929,186.566108 Z M364.013199,208.43772 C351.207657,208.43772 341.618237,199.104387 341.618237,186.566108 C341.618237,173.952559 351.207657,164.689978 364.013199,164.689978 C376.800605,164.689978 386.408161,174.005247 386.408161,186.566108 C386.408161,199.104387 376.800605,208.442237 364.013199,208.442237 L364.013199,208.43772 Z"
                id="Fill-213"
                fill="#009FE3"
              ></path>
              <path
                d="M390.522015,189.810495 L390.522015,165.706409 L399.130579,165.706409 L399.130579,188.607699 C399.130579,195.85157 403.10539,200.25329 409.570882,200.25329 C416.08927,200.25329 419.98398,195.774796 419.98398,188.607699 L419.98398,165.706409 L428.592544,165.706409 L428.592544,189.810495 C428.592544,201.034581 421.125038,208.441032 409.570882,208.441032 C398.065088,208.441032 390.522015,201.034581 390.522015,189.810495"
                id="Fill-215"
                fill="#009FE3"
              ></path>
              <path
                d="M471.289118,186.566108 C471.289118,178.702022 465.518841,172.879226 457.623627,172.879226 C449.731436,172.879226 443.908262,178.75471 443.908262,186.566108 C443.908262,194.372989 449.731436,200.252989 457.623627,200.252989 C465.518841,200.252989 471.289118,194.430194 471.289118,186.566108 M480.000453,186.566108 C480.000453,199.068258 470.834207,208.464817 458.300705,208.464817 C452.882569,208.464817 448.065945,206.592129 444.397935,203.408258 L444.397935,222.379011 L435.78937,222.379011 L435.78937,165.706108 L441.591385,165.706108 L443.208514,170.831914 C446.991385,166.984172 452.27199,164.689978 458.300705,164.689978 C470.834207,164.689978 480.000453,174.005247 480.000453,186.566108"
                id="Fill-217"
                fill="#009FE3"
              ></path>
              <path
                d="M303.367859,185.48329 C303.367859,178.093398 297.984484,172.687591 290.507909,172.687591 C283.058539,172.687591 277.599597,178.146086 277.599597,185.48329 C277.599597,192.784366 283.035869,198.24286 290.507909,198.24286 C297.958791,198.24286 303.367859,192.837054 303.367859,185.48329 L303.367859,185.48329 Z M311.829824,165.705656 L311.829824,201.962645 C311.829824,215.273183 303.884736,223.394688 290.92806,223.394688 C280.202116,223.394688 272.586499,217.850387 270.44796,208.691677 L279.038388,208.691677 C280.643426,213.266516 285.571889,215.791032 290.864584,215.791032 C298.100856,215.791032 303.395063,211.038559 303.395063,203.133828 L303.395063,201.211462 C299.767859,204.34114 294.955768,206.20028 289.533098,206.20028 C277.876171,206.20028 268.835365,197.174043 268.835365,185.48329 C268.835365,173.819634 277.876171,164.689527 289.533098,164.689527 C295.676675,164.689527 301.055516,167.117699 304.811184,171.088882 L306.183476,165.705656 L311.829824,165.705656 Z"
                id="Fill-219"
                fill="#009FE3"
              ></path>
              <polygon
                id="Fill-221"
                fill="#009FE3"
                points="377.210025 55.418172 356.267456 85.305914 356.267456 55.418172 340.319849 55.418172 340.319849 99.737957 356.267456 99.737957 364.729421 99.737957 373.418086 87.4706452 396.119849 55.418172"
              ></polygon>
              <polygon
                id="Fill-223"
                fill="#009FE3"
                points="401.103476 48.4911828 435.533199 0 416.041511 0 382.063678 48.4911828"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
