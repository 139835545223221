import { BASE_API_URL, BASE_ODDS_PLATFORM_API_URL } from "@/constants/api";
import { NotFound, Redirect } from "@/types/page";
import { getActiveBrand } from "@/lib/brand";
import { removeUndefinedValuesForSearchParams } from "@/lib/filter";
import { ContentRedirect } from "@/types/api/content";
import { addLeadingSlash } from "../string";

type QueryParams = Record<string, string | number | boolean | undefined | null>;

interface GetDataFromPathAndQueryParamsProps {
  path: string;
  queryParams?: QueryParams;
  disableBrand?: boolean;
  isOddsPlatformRequest?: boolean;
}

export const getResponseMessage = async (
  response: Response
): Promise<string> => {
  const res = await response.json();

  return res.message;
};

export const getDataFromPathAndQueryParams = async <ResponseType>({
  path,
  queryParams,
  disableBrand = false,
  isOddsPlatformRequest = false,
}: GetDataFromPathAndQueryParamsProps): Promise<ResponseType> => {
  const brand = getActiveBrand();
  let url: URL;

  if (isOddsPlatformRequest) {
    url = new URL(`${BASE_ODDS_PLATFORM_API_URL}${path}`);
  } else if (disableBrand) {
    url = new URL(`${BASE_API_URL}${path}`);
  } else {
    url = new URL(`${BASE_API_URL}brand/${brand}/${path}`);
  }

  if (queryParams) {
    const searchParams = new URLSearchParams(
      removeUndefinedValuesForSearchParams(queryParams)
    );

    url.search = searchParams.toString();
  }

  const res = await fetch(url.toString());

  if (res.status !== 200) {
    const errorMessage = await getResponseMessage(res);

    // Logging 404's overloads our logging service and is not useful
    if (res.status !== 404) {
      console.error({
        errorMessage,
        status: res.status,
        statusText: res.statusText,
        path,
        url: res.url,
      });
    }

    return { message: "Not found", data: {} } as ResponseType;
  }

  return res.json();
};

export const handleDeletedOrExpiredContent = ({
  redirect,
  revalidate,
}: {
  redirect?: ContentRedirect | null;
  revalidate: number;
}): Redirect | NotFound => {
  // handle redirect being undefined
  if (!redirect) return { notFound: true, revalidate };
  const [redirectStatus = 0, redirectUrl = "/"] = redirect;

  const redirectMap = new Map<number, Redirect>([
    [
      301,
      {
        redirect: {
          destination: addLeadingSlash(redirectUrl),
          permanent: true,
        },
      },
    ],
    // 302 is a temporary redirect so we want to revalidate the page,
    // for when the content is restored
    [
      302,
      {
        redirect: {
          destination: addLeadingSlash(redirectUrl),
          permanent: false,
        },
        revalidate,
      },
    ],
  ]);

  return redirectMap.get(redirectStatus) || { notFound: true, revalidate };
};
