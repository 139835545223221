import { checkIfPathStartsWithLocale } from "ui";
import { PAGE_STRING_TRANSLATIONS } from "@/constants/staticTranslations";
import { ContentTemplate } from "@/types/api/content";
import { NotFound, PageUnion, Redirect } from "@/types/page";
import { getHtmlLangCode } from "./geo";
import { getSiteUrl } from "./sitemap";

// type guard function to check if the page data returned is a 404 or redirect
// if either of these are true then we don't want to render the page
export function isNotFoundOrRedirect(
  obj: PageUnion
): obj is NotFound | Redirect {
  return (
    (obj as NotFound).notFound !== undefined ||
    (obj as Redirect).redirect !== undefined
  );
}

interface PageCanonicalLinksParams {
  fullUrlPath: string;
  currentPage?: number;
  pageTemplate?: ContentTemplate;
  region?: string | null;
}

interface CanonicalLink {
  rel: string;
  href: string;
  hrefLang?: string;
}

export const getPageCanonicalLinks = ({
  pageTemplate,
  currentPage,
  fullUrlPath,
  region,
}: PageCanonicalLinksParams): CanonicalLink[] => {
  const siteUrl = getSiteUrl();

  if (region && checkIfPathStartsWithLocale(fullUrlPath, region)) {
    // localised homepage
    if (fullUrlPath.endsWith(region)) {
      return [
        {
          rel: "canonical",
          href: `${siteUrl}/${fullUrlPath}`,
        },
        {
          rel: "alternate",
          href: `${siteUrl}/${fullUrlPath}`,
          hrefLang: getHtmlLangCode(region),
        },
        {
          rel: "alternate",
          href: `${siteUrl}`,
          hrefLang: "x-default",
        },
        {
          rel: "alternate",
          href: `${siteUrl}`,
          hrefLang: getHtmlLangCode(""),
        },
      ];
    }
    // localised content
    const translatedPageString = PAGE_STRING_TRANSLATIONS.get(region) ?? "page";
    const page =
      currentPage && Number(currentPage) > 1
        ? `?${translatedPageString}=${currentPage}`
        : "";
    const href = `${siteUrl}/${fullUrlPath}${page}`.trim();
    return [
      {
        rel: "canonical",
        href: href,
      },
      {
        rel: "alternate",
        href: href,
        hrefLang: getHtmlLangCode(region),
      },
    ];
  }

  const page =
    currentPage && Number(currentPage) > 1 ? `?page=${currentPage}` : "";

  const defaultBrandHref =
    pageTemplate && pageTemplate === "homepage"
      ? siteUrl
      : `${siteUrl}/${fullUrlPath}${page}`.trim();

  // default brand behavior
  return [
    {
      rel: "canonical",
      href: defaultBrandHref,
    },
    {
      rel: "alternate",
      href: `${defaultBrandHref}`,
      hrefLang: "x-default",
    },
    {
      rel: "alternate",
      href: `${defaultBrandHref}`,
      hrefLang: getHtmlLangCode(""),
    },
  ];
};

export const isPromotionsPage = (url: string | undefined | null) => {
  if (!url || url === "") return false;

  // If the url starts with 'promotions' or '/promotions' return true
  return url.startsWith("promotions") || url.startsWith("/promotions");
};
