import {
  BRAND_GAMBLEAWARE_CONTENT_MAP,
  FOOTER_MAP,
} from "@/constants/staticTranslations";
import { SPORTS_BLOG_BRAND_SHORT_NAMES } from "@/constants/brands";
import { SportBrandShortNames } from "@/types/brand";
import { supportedStatesUSA } from "@/constants/countries";
export const getFooterConfig = (brand: SportBrandShortNames) => {
  if (!brand || !SPORTS_BLOG_BRAND_SHORT_NAMES.includes(brand)) {
    throw new Error(`Brand ${brand} is not supported`);
  }
  return FOOTER_MAP.get(brand);
};

export const getGambleAwareContent = (brand: SportBrandShortNames) => {
  if (!brand || !SPORTS_BLOG_BRAND_SHORT_NAMES.includes(brand)) {
    throw new Error(`Brand ${brand} is not supported`);
  }
  return BRAND_GAMBLEAWARE_CONTENT_MAP.get(brand);
};

// Makes it easy to set a default config across each US state
export const generateDefaultConfigForEachUsState = (
  defaultConfig: Record<string, string>
) => {
  return supportedStatesUSA.reduce<Record<string, Record<string, string>>>(
    (acc, state) => {
      acc[state.toLowerCase()] = defaultConfig;
      return acc;
    },
    {}
  );
};
