import { NavResponse } from "@/types/api/nav";
import { getDataFromPathAndQueryParams } from "@/lib/http/helpers";

export const getNavForBrand = async (
  region?: string | null
): Promise<NavResponse> => {
  const path = "nav";
  const queryParams = {
    menu_region: region,
  };

  return await getDataFromPathAndQueryParams({ path, queryParams });
};
