import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { BetSlipActions, BetSlipState } from "@/types/betslip";
import { getOddsByCommonAndSelectionIdPairs } from "@/lib/http/odds";
import { useLocaleFromRouter } from "@/hooks/LocationFromRouter";
import { betSlipReducer, gaAddToBetSlipEvent } from "@/lib/betslip";
import { SelectionInterface } from "@/types/api/selections";
import { getActiveBrand } from "@/lib/brand";
import { FA_BRAND, FP_BRAND } from "@/constants/brands";
import { getDefaultFormattedOdds } from "@/lib/odds";
import { createCookieString, getCookieValueByName } from "@/lib/cookies";
import { ODDS_FORMAT_COOKIE_NAME } from "@/constants/odds";
import { OddsFormats } from "@/types/odds";

// Two separate providers created to avoid performance concerns, as described in this [article](https://hswolff.com/blog/how-to-usecontext-with-usereducer/)
export const BetSlipDispatchContext =
  createContext<Dispatch<BetSlipActions> | null>(null);
export const BetSlipStateContext = createContext<BetSlipState | null>(null);

export const initialBetSlipState: BetSlipState = {
  isOpen: false,
  activeCommonAndEventIdPairs: [],
  selectionsDataAndState: [],
  bookmakers: [],
  portalBookmaker: null,
  betAddedSource: null,
  oddsChanged: false,
  oddsFormat: getDefaultFormattedOdds(),
  bestOdds: {
    odds: "",
    oddsAmerican: "",
    oddsDecimal: 0,
  },
};

export const retrieveLocalStorageBetSlipSelections =
  (): Promise<BetSlipState> =>
    Promise.resolve().then(() => {
      const storedSelections = localStorage.getItem("betSlipSelections");

      if (storedSelections) {
        const { activeCommonAndEventIdPairs } = JSON.parse(storedSelections);

        initialBetSlipState.activeCommonAndEventIdPairs = Array.isArray(
          activeCommonAndEventIdPairs
        )
          ? activeCommonAndEventIdPairs
          : [];
      }

      return initialBetSlipState;
    });

const BetSlipProvider: FC<PropsWithChildren> = ({ children }) => {
  const [
    {
      activeCommonAndEventIdPairs,
      isOpen,
      selectionsDataAndState,
      bookmakers,
      betAddedSource,
      portalBookmaker,
      oddsChanged,
      oddsFormat,
      bestOdds,
    },
    dispatch,
  ] = useReducer(betSlipReducer, initialBetSlipState);
  const [locale] = useLocaleFromRouter();
  const brand = getActiveBrand();

  const [hasRetrievedStoredSelections, setHasRetrievedStoredSelections] =
    useState(false);

  const waitForLocalStorage = useCallback(async () => {
    const { activeCommonAndEventIdPairs } =
      await retrieveLocalStorageBetSlipSelections();

    if (activeCommonAndEventIdPairs && activeCommonAndEventIdPairs.length > 0) {
      if (brand === FA_BRAND) {
        dispatch({
          type: "ADD_ACCA",
          commonAndSelectionIdPairsArray: activeCommonAndEventIdPairs,
          source: "localStorage",
        });
      }
      // We only want to update local storage for FP
      if (brand === FP_BRAND) {
        localStorage.setItem(
          "betSlipSelections",
          JSON.stringify({
            activeCommonAndEventIdPairs,
          })
        );
      }
    }
  }, [brand]);

  useEffect(() => {
    const oddsFormatCookie = getCookieValueByName(
      ODDS_FORMAT_COOKIE_NAME
    ) as OddsFormats;

    if (!oddsFormatCookie) {
      document.cookie = createCookieString({
        name: ODDS_FORMAT_COOKIE_NAME,
        value: oddsFormat,
      });
      dispatch({
        type: "SET_ODDS_FORMAT",
        oddsFormat: oddsFormat,
      });
    } else if (oddsFormatCookie !== oddsFormat) {
      dispatch({
        type: "SET_ODDS_FORMAT",
        oddsFormat: oddsFormatCookie,
      });
    }
  }, [oddsFormat]);

  useEffect(() => {
    waitForLocalStorage();
    setHasRetrievedStoredSelections(true);
  }, [waitForLocalStorage, setHasRetrievedStoredSelections]);

  const getOddsData = useCallback(async () => {
    const { selections, bookmakers } = await getOddsByCommonAndSelectionIdPairs(
      activeCommonAndEventIdPairs,
      locale
    );
    const validatedSelections = Array.isArray(selections) ? selections : [];
    const validatedBookmakers = Array.isArray(bookmakers) ? bookmakers : [];
    if (activeCommonAndEventIdPairs.length > 0) {
      dispatch({
        type: "UPDATE_SELECTIONS",
        selections: validatedSelections,
        bookmakers: validatedBookmakers,
      });
    }
    if (
      betAddedSource &&
      betAddedSource !== "localStorage" &&
      betAddedSource !== "removed"
    ) {
      // we don't want to send a GA event for selections retrieved from local storage
      // or if the selection was removed from the betslip
      if (betAddedSource === "Tipster") {
        // if the user has added a bet from Tipster, then that will replace everything in the
        // betslip, so we need to send off all of the selections
        gaAddToBetSlipEvent(selections, betAddedSource);
      } else {
        // if they have not added a bet from the tipster this will be an individual selection
        // meaning we only want to send off the most recent bet selection.
        const recentlyAdded = selections.find((selection) =>
          selectionsDataAndState.every(
            (item) =>
              selection.id !== item.id ||
              (selection.id === item.id && selection.commonID !== item.commonID)
          )
        ) as SelectionInterface;
        if (recentlyAdded) {
          gaAddToBetSlipEvent([recentlyAdded], betAddedSource);
        }
      }
    }

    if (hasRetrievedStoredSelections) {
      localStorage.setItem(
        "betSlipSelections",
        JSON.stringify({
          activeCommonAndEventIdPairs,
        })
      );
    }

    if (oddsChanged) {
      dispatch({
        type: "SET_ODDS_HAVE_CHANGED",
        oddsChanged: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeCommonAndEventIdPairs,
    locale,
    hasRetrievedStoredSelections,
    oddsChanged,
  ]);

  useEffect(() => {
    // We only want to make this request in FA as we handle odds per bookmaker in FP
    if (brand === FA_BRAND) {
      getOddsData();
    }
    // We only want to update local storage for FP
    if (brand === FP_BRAND) {
      if (hasRetrievedStoredSelections) {
        localStorage.setItem(
          "betSlipSelections",
          JSON.stringify({
            activeCommonAndEventIdPairs,
          })
        );
      }
    }
  }, [
    activeCommonAndEventIdPairs,
    locale,
    hasRetrievedStoredSelections,
    getOddsData,
    brand,
  ]);

  useEffect(() => {
    if (!oddsChanged) return;
    getOddsData();
  }, [oddsChanged, getOddsData]);

  return (
    <BetSlipDispatchContext.Provider value={dispatch}>
      <BetSlipStateContext.Provider
        value={{
          isOpen,
          selectionsDataAndState,
          activeCommonAndEventIdPairs,
          bookmakers,
          portalBookmaker,
          betAddedSource,
          oddsChanged,
          oddsFormat,
          bestOdds,
        }}
      >
        {children}
      </BetSlipStateContext.Provider>
    </BetSlipDispatchContext.Provider>
  );
};

export default BetSlipProvider;
