import type { Block } from "../../../types/draftManager";

export default function HeaderTwo(
  block: Block,
  originalText: string
): React.JSX.Element {
  const { data } = block;

  return <h2 style={{ textAlign: data?.alignment }}>{originalText}</h2>;
}
