import { ContestantData, Tables } from "@/types/api/standings";
import { queryParamsToString } from "./object";

interface RowIndices {
  startingRow: number;
  endingRow: number;
}

interface FilterArguments {
  tablesToFilter: Tables[];
  tableType?: string;
  focusedTableId?: string;
  focusedGroupId?: null | string;
  highlightedContestantId?: string;
}

export const removeUndefinedValuesForSearchParams = (
  object: Record<string, string | number | boolean | null | undefined>
): Record<string, string> => {
  Object.keys(object).forEach(
    (key) =>
      (object[key] === undefined ||
        object[key] === null ||
        object[key] === "") &&
      delete object[key]
  );

  const res = queryParamsToString(object);

  return res;
};

// The methods below are used for the LeagueTable

export const findHighlightedTeamInCondensedTable = (
  allContestants: ContestantData[],
  highlightedContestantId?: string
): RowIndices => {
  // If a condensed table with a highlighted team is selected
  const contestantIndex = highlightedContestantId
    ? allContestants.findIndex(
        (contestant) => contestant.contestantId === highlightedContestantId
      )
    : -1;

  if (contestantIndex <= 0) {
    // If the highlighted team does not exist in the group table, or is at the top, display the top 3 teams
    return {
      startingRow: 0,
      endingRow: 3,
    };
  }

  if (contestantIndex === allContestants.length - 1) {
    // If team is at the bottom, display next 2 above
    return {
      startingRow: contestantIndex - 2,
      endingRow: allContestants.length,
    };
  }

  // Default, display 1 above and 1 below
  return {
    startingRow: contestantIndex - 1,
    endingRow: contestantIndex + 2,
  };
};

export const filterTableDataForCondensedView = (
  allTables: Tables[],
  highlightedContestantId?: string
): Tables[] => {
  return allTables.map((leagueTable) => {
    const updatedTable = leagueTable.table.map((groupTable) => {
      const { startingRow, endingRow } = findHighlightedTeamInCondensedTable(
        groupTable.ranking,
        highlightedContestantId
      );

      // Note: endingRow is exclusive and data is sliced up to that row number, not including
      const focusedTable = groupTable.ranking.slice(startingRow, endingRow);

      return { ...groupTable, ranking: focusedTable };
    });

    return { ...leagueTable, table: updatedTable };
  });
};

export const filterTableDataByTableAndGroup = (
  allTables: Tables[],
  focusedTableId?: null | string,
  focusedGroupId?: null | string
): Tables[] => {
  let filteredTables = allTables;

  if (focusedTableId) {
    filteredTables = allTables.filter(
      (league) => focusedTableId === league.name
    );
  }

  if (focusedGroupId) {
    filteredTables = filteredTables.map((league) => {
      const filteredGroup = league.table.filter(
        (groupTable) => focusedGroupId === groupTable.groupId
      );

      return { ...league, table: filteredGroup };
    });
  } else {
    // If we don't have a table or groupTable return the first group table from the first league
    filteredTables = filteredTables.slice(0, 1).map((league) => {
      const filteredFirstGroup = league.table[0];

      return { ...league, table: [filteredFirstGroup] };
    });
  }

  return filteredTables;
};

export const filterTableData = ({
  tablesToFilter,
  tableType,
  focusedTableId,
  focusedGroupId,
  highlightedContestantId,
}: FilterArguments): Tables[] => {
  let filteredTables = filterTableDataByTableAndGroup(
    tablesToFilter,
    focusedTableId,
    focusedGroupId
  );

  if (tableType === "condensed" || !tableType) {
    filteredTables = filterTableDataForCondensedView(
      filteredTables,
      highlightedContestantId
    );
  }

  return filteredTables;
};
