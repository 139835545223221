/* eslint-disable @next/next/no-img-element -- img element required for draftManager */
import type { Entity } from "../../../types/draftManager";

export default function media(entity: Entity): React.JSX.Element {
  const {
    text,
    data: {
      media: { size = "md", sizes, alt },
      url,
    },
  } = entity;

  if (url) {
    return (
      <figure>
        <a href={url}>
          <img
            alt={alt || text}
            height={556}
            loading="lazy"
            src={sizes[size]}
            width={998}
          />
        </a>
        {text ? <figcaption>{text}</figcaption> : null}
      </figure>
    );
  }

  return (
    <figure>
      <img
        alt={alt || text}
        height={556}
        loading="lazy"
        src={sizes[size]}
        width={988}
      />
      {text ? <figcaption>{text}</figcaption> : null}
    </figure>
  );
}
