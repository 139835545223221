import { getDataFromPathAndQueryParams } from "@/lib/http/helpers";
import { BookmakerAPIResponse } from "@/types/api/bookmaker";

export const getBookmakerData = async (
  brandingOnly: boolean
): Promise<BookmakerAPIResponse> => {
  const path = "bookmakers";
  const queryParams = { brandingOnly };

  return await getDataFromPathAndQueryParams({
    path,
    queryParams,
    disableBrand: true,
  });
};
