import type { ChangeEvent } from "react";
import { selectCurrencySymbol } from "../../utils/content/content";
import styles from "./BetStakeInput.module.scss";

interface BetStakeInputProps {
  handleStakeChange: (event: ChangeEvent) => void;
  handleInputFocus: () => void;
  convertTo2Decimals: (event: ChangeEvent) => void;
  locale: string;
  stakeValue: string;
  stakeInput: string;
}

export function BetStakeInput({
  locale,
  stakeValue,
  stakeInput,
  handleStakeChange,
  handleInputFocus,
  convertTo2Decimals,
}: BetStakeInputProps): React.JSX.Element {
  const currencySymbol = selectCurrencySymbol(locale);

  return (
    <div
      className={styles.inputWrapper}
      // Used within the styles as we cannot use ::before inline
      data-before-content={currencySymbol}
      data-testid="inputWrapper"
      style={{ content: `"${currencySymbol}"` }}
    >
      <input
        className={styles.input}
        id="otherAmount"
        name="otherAmount"
        onBlur={convertTo2Decimals}
        onChange={handleStakeChange}
        onFocus={handleInputFocus}
        type="number"
        value={stakeInput === "input" ? stakeValue : ""}
      />
    </div>
  );
}
