import { getEmbeddedTweetHtml } from "../../http/content";
import type { Entity } from "../../../types/draftManager";

export const getTweet = async (url: string): Promise<string> => {
  const tweetHtml = await getEmbeddedTweetHtml(url).then((tweet) => {
    return tweet?.html ?? "";
  });
  return tweetHtml;
};

export default function twitter(entity: Entity): React.JSX.Element {
  const { text } = entity;
  // Returned empty div here due to the string utility removeEmptyElements stripping out
  // any empty elements.
  if (!text) return <div />;
  return (
    <figure>
      <div
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </figure>
  );
}
