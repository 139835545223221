import { neutrals } from "./crossBrand";
// Rather than trying to come up with color names we're just giving
// setting the key as the next character in the alphabet

export const ocColors = {
  ...neutrals,
  ocOrange: "#FF9E00",
  ocYellow: "#F5FF00",
  ocBlacks: {
    100: "#121212",
    200: "#242424",
  },
};
