/* eslint-disable @next/next/no-img-element -- img element required for draftManager*/
import Image from "next/image";
import type { Entity } from "../../../types/draftManager";
import { isValidGifDomain } from "../../string/string";
import styles from "./atomic:giphy.module.scss";

// This component returns an empty span if !giphy instead of null because convertToHTML cannot handle null
// Empty span is still not rendered in the DOM by removeEmptyElements
export default function atomicGiphy(entity: Entity): React.JSX.Element {
  const {
    data: { giphy },
  } = entity;

  if (!giphy) return <span />;

  const { size, sizes } = giphy;
  const url = sizes[size];

  // Check that url starts with valid domain name for gifs
  return isValidGifDomain(url) ? (
    <div className={styles.imageWrapper}>
      <Image alt="giphy-gif" className={styles.image} fill src={url} />
    </div>
  ) : (
    <img alt="gif" src={url} />
  );
}
