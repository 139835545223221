import { useCallback, useEffect, useState } from "react";
import { checkIsDesktopDevice, checkIsMobileDevice } from "@/lib/device";

export interface WindowDimensions {
  width: number;
  height: number;
}

export function useWindowDimensions(): WindowDimensions {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
    width: 0,
    height: 0,
  });
  const handleWindowResize = (): void => {
    const { innerWidth: width, innerHeight: height } = window;
    setWindowDimensions({
      width,
      height,
    });
  };

  useEffect(() => {
    handleWindowResize();

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return windowDimensions;
}

export function useCheckMobileDevice(): boolean {
  const [isMobileDevice, setIsMobileDevice] = useState(true);
  const handleWindowResize = useCallback((): void => {
    const { innerWidth: width, innerHeight: height } = window;
    const isMobile = checkIsMobileDevice({ width, height });
    if (isMobile !== isMobileDevice) {
      setIsMobileDevice(isMobile);
    }
  }, [isMobileDevice]);

  useEffect(() => {
    handleWindowResize();

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [handleWindowResize]);

  return isMobileDevice;
}

export const useCheckDesktopDevice = (): boolean => {
  const [isDesktopDevice, setIsDesktopDevice] = useState(false);
  const handleWindowResize = useCallback((): void => {
    const { innerWidth: width, innerHeight: height } = window;
    const isDesktop = checkIsDesktopDevice({ width, height });
    if (isDesktop !== isDesktopDevice) {
      setIsDesktopDevice(isDesktop);
    }
  }, [isDesktopDevice]);

  useEffect(() => {
    handleWindowResize();

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [handleWindowResize]);

  return isDesktopDevice;
};
