export const UK_LOCALE = "UK";
export const DEFAULT_LOCALE = UK_LOCALE;
export const IE_LOCALE = "IE";

export const supportedRegionsEurope = [UK_LOCALE, IE_LOCALE] as const;
export const supportedRegionsAfrica = ["GH", "KE", "NG", "ZA", "UG"] as const;
export const supportedStatesUSA = [
  "US-AR",
  "US-AZ",
  "US-CO",
  "US-CT",
  "US-DC",
  "US-DE",
  "US-IA",
  "US-IL",
  "US-IN",
  "US-KS",
  "US-LA",
  "US-MA",
  "US-MD",
  "US-ME",
  "US-MI",
  "US-MS",
  "US-NH",
  "US-NJ",
  "US-NY",
  "US-OH",
  "US-OR",
  "US-PA",
  "US-RI",
  "US-TN",
  "US-VA",
  "US-WV",
  "US-WY",
  "US-KY",
  "US-VT",
  // "US-AK",
  // "US-AL",
  // "US-CA",
  // "US-FL",
  // "US-GA",
  // "US-HI",
  // "US-ID",
  // "US-MN",
  // "US-MO",
  // "US-MT",
  // "US-NC",
  // "US-ND",
  // "US-NE",
  // "US-NM",
  // "US-NV",
  // "US-OK",
  // "US-SC",
  // "US-SD",
  // "US-TX",
  // "US-UT",
  // "US-WA",
  // "US-WI",
] as const;
export const supportedRegionsNorthAmerica = [
  ...supportedStatesUSA,
  "CA-ON",
] as const;

export type supportedRegionsNorthAmerica =
  (typeof supportedRegionsNorthAmerica)[number];

export type supportedRegions =
  | (typeof supportedRegionsEurope)[number]
  | (typeof supportedRegionsAfrica)[number]
  | supportedRegionsNorthAmerica;

export const allSupportedRegions = new Map<string, supportedRegions>([
  ["GB", "UK"],
  ["IE", "IE"],
  ["GH", "GH"], // Ghana
  ["KE", "KE"], // Kenya
  ["NG", "NG"], // Nigeria
  ["ZA", "ZA"], // South Africa
  ["UG", "UG"], // Uganda
  ["US_AR", "US-AR"], // US-Arkansas
  ["US_AZ", "US-AZ"], // US-Arizona
  ["US_OH", "US-OH"], // US-Ohio
  ["CA_ON", "CA-ON"], // Canada - Ontario
  ["US_OR", "US-OR"], // US-Oregon
  ["US_CO", "US-CO"], // US-Colarado
  ["US_DC", "US-DC"], // US-District of Columbia
  ["US_IL", "US-IL"], // US-Illinois
  ["US_IA", "US-IA"], // US-Iowa
  ["US_NJ", "US-NJ"], // US-New Jersey
  ["US_NY", "US-NY"], // US-New York
  ["US_WY", "US-WY"], // US-Wyoming
  ["US_CT", "US-CT"], // US-Connecticut
  ["US_DE", "US-DE"], // US-Delaware
  ["US_KS", "US-KS"], // US-Kansas
  ["US_LA", "US-LA"], // US-Louisiana
  ["US_MA", "US-MA"], // US-Massachusetts
  ["US_MD", "US-MD"], // US-Maryland
  ["US_ME", "US-ME"], // US-Maine
  ["US_MI", "US-MI"], // US-Michigan
  ["US_MS", "US-MS"], // US-Mississippi
  ["US_NH", "US-NH"], // US-New Hampshire
  ["US_PA", "US-PA"], // US-Pennsylvania
  ["US_RI", "US-RI"], // US-Rhode Island
  ["US_TN", "US-TN"], // US-Tennessee
  ["US_VA", "US-VA"], // US-Virginia
  ["US_WV", "US-WV"], // US-West Virginia
  ["US_KY", "US-KY"], // US-Kentucky
  ["US_VT", "US-VT"], // US-Vermont
  // ["US_AL", "US-AL"], // US-Alabama
  // ["US_AK", "US-AK"], // US-Alaska
  // ["US_CA", "US-CA"], // US-California
  // ["US_FL", "US-FL"], // US-Florida
  // ["US_GA", "US-GA"], // US-Georgia
  // ["US_HI", "US-HI"], // US-Hawaii
  // ["US_ID", "US-ID"], // US-Idaho
  // ["US_MN", "US-MN"], // US-Minnesota
  // ["US_MO", "US-MO"], // US-Missouri
  // ["US_MT", "US-MT"], // US-Montana
  // ["US_NE", "US-NE"], // US-Nebraska
  // ["US_NV", "US-NV"], // US-Nevada
  // ["US_NM", "US-NM"], // US-New Mexico
  // ["US_NC", "US-NC"], // US-North Carolina
  // ["US_ND", "US-ND"], // US-North Dakota
  // ["US_OK", "US-OK"], // US-Oklahoma
  // ["US_SC", "US-SC"], // US-South Carolina
  // ["US_SD", "US-SD"], // US-South Dakota
  // ["US_TX", "US-TX"], // US-Texas
  // ["US_UT", "US-UT"], // US-Utah
  // ["US_WA", "US-WA"], // US-Washington
  // ["US_WI", "US-WI"], // US-Wisconsin
  // ["US_AS", "US-AS"], // US-American Samoa
  // ["US_GU", "US-GU"], // US-Guam
  // ["US_MP", "US-MP"], // US-Northern Mariana Islands
  // ["US_PR", "US-PR"], // US-Puerto Rico
  // ["US_UM", "US-UM"], // US-United States Minor
  // ["US_VI", "US-VI"], // US-Virgin Islands
]);

export const states = new Map<string, string>([
  ["US-AK", "Alaska"],
  ["US-AL", "Alabama"],
  ["US-AR", "Arkansas"],
  ["US-AZ", "Arizona"],
  ["US-CA", "California"],
  ["US-CO", "Colorado"],
  ["US-CT", "Connecticut"],
  ["US-DC", "Washington DC"],
  ["US-DE", "Delaware"],
  ["US-FL", "Florida"],
  ["US-GA", "Georgia"],
  ["US-HI", "Hawaii"],
  ["US-IA", "Iowa"],
  ["US-ID", "Idaho"],
  ["US-IL", "Illinois"],
  ["US-IN", "Indiana"],
  ["US-KS", "Kansas"],
  ["US-KY", "Kentucky"],
  ["US-LA", "Louisiana"],
  ["US-MA", "Massachusetts"],
  ["US-MD", "Maryland"],
  ["US-ME", "Maine"],
  ["US-MI", "Michigan"],
  ["US-MN", "Minnesota"],
  ["US-MO", "Missouri"],
  ["US-MS", "Mississippi"],
  ["US-MT", "Montana"],
  ["US-NC", "North Carolina"],
  ["US-ND", "North Dakota"],
  ["US-NE", "Nebraska"],
  ["US-NH", "New Hampshire"],
  ["US-NJ", "New Jersey"],
  ["US-NM", "New Mexico"],
  ["US-NV", "Nevada"],
  ["US-NY", "New York"],
  ["US-OH", "Ohio"],
  ["US-OK", "Oklahoma"],
  ["CA-ON", "Ontario"],
  ["US-OR", "Oregon"],
  ["US-PA", "Pennsylvania"],
  ["US-RI", "Rhode Island"],
  ["US-SC", "South Carolina"],
  ["US-SD", "South Dakota"],
  ["US-TN", "Tennessee"],
  ["US-TX", "Texas"],
  ["US-UT", "Utah"],
  ["US-VA", "Virginia"],
  ["US-VT", "Vermont"],
  ["US-WA", "Washington"],
  ["US-WI", "Wisconsin"],
  ["US-WV", "West Virginia"],
  ["US-WY", "Wyoming"],
]);

export const LOCALES_THAT_SUPPORT_API_BETTING = ["uk"];

export const BRAZIL_LOCALE = "br";

export const DEFAULT_ENGLISH_LANG_CODE = "en";
export const BRITISH_ENGLISH_LANG_CODE = "en-GB";
export const US_ENGLISH_LANG_CODE = "en-US";
export const BRAZIL_LANG_CODE = "pt-BR";

export const LOCALE_LANGUAGES = new Map<string, string>([
  [BRAZIL_LOCALE, BRAZIL_LANG_CODE],
]);

export const LICENSED_STATE = "licensed";
export const SOON_TO_BE_LICENSED_STATE = "soon to be licensed";
export const UNLICENSED_STATE = "unlicensed";
