import { CONTENT_LIMIT } from "@/constants/api";
import { SlugsResponse } from "@/types/api/slugs";
import {
  ContentResponse,
  ContentTipArrayResponse,
  LatestContent,
  LatestContentResponse,
} from "@/types/api/content";
import { TweetData } from "@/types/api/tweet";
import { FreeBetOfferCountResponse } from "@/types/api/offer";
import {
  AuthorPageData,
  AuthorPageResponse,
  GetContentByAuthorIdParams,
} from "@/types/api/authors";
import { getDataFromPathAndQueryParams } from "@/lib/http/helpers";
import { isEmptyObject } from "@/lib/object";
import { isEmptyArray } from "@/lib/array";
import { getLocalisedContentRegionByBrand } from "@/lib/content";
import { getActiveBrand } from "@/lib/brand";

const fetchAllSlugs = async (): Promise<SlugsResponse> => {
  const path = "content/slugs";

  return await getDataFromPathAndQueryParams({ path, disableBrand: true });
};

const fetchArticleBySlug = async (slug: string): Promise<ContentResponse> => {
  const path = `content/slugs/${slug}`;

  return await getDataFromPathAndQueryParams({ path, disableBrand: true });
};

const getLatestContentByFullUrlPath = async (
  full_url_path: string,
  limit = CONTENT_LIMIT,
  page = "1",
  type: "page" | "post" = "post"
): Promise<LatestContentResponse> => {
  const path = "content/parent/page";
  const queryParams = { full_url_path, limit, page, type };

  return await getDataFromPathAndQueryParams({ path, queryParams });
};

// Makes request for latest content but returns only the data
// This means the integration for the data mapper works as before we implemented pagination
const retrieveLatestContentOnlyByFullUrlPath = async (
  fullUrlPath: string,
  limit = CONTENT_LIMIT
): Promise<{ data: LatestContent[] }> => {
  const { data } = await getLatestContentByFullUrlPath(
    fullUrlPath,
    limit || CONTENT_LIMIT
  );

  if (isEmptyObject(data)) {
    console.error(`Error fetching data for content/parent/page/${fullUrlPath}`);
    return { data: [] };
  }

  const { content } = data;

  if (isEmptyArray(content)) {
    console.error(
      `Error destructuring data for content/parent/page/${fullUrlPath}`
    );
    return { data: [] };
  }

  return { data: content };
};

const getLatestTipsLink = async (): Promise<ContentTipArrayResponse> => {
  const path = "tip-page-links";

  return await getDataFromPathAndQueryParams({ path });
};

const getEmbeddedTweetHtml = async (url: string): Promise<TweetData | null> => {
  // This is a fix for if a user uses an x.com embed instead of a twitter.com embed in the CMS
  const twitterOrXSiteLink = url.includes("twitter.com")
    ? "twitter.com"
    : "x.com";

  const res = await fetch(
    `https://publish.${twitterOrXSiteLink}/oembed?url=${url}`
  );
  if (res.status === 200) {
    return res.json();
  }
  console.error({ status: res.status, reason: res.statusText });
  return null;
};

const getContentByFullUrlPath = async (
  fullUrlPath: string
): Promise<ContentResponse> => {
  const path = `content/post`;

  return await getDataFromPathAndQueryParams({
    path,
    queryParams: { fullUrlPath },
  });
};

const getFreeBetCount = async (
  locale: string
): Promise<FreeBetOfferCountResponse> => {
  const brand = getActiveBrand();
  const path = "free-bet-count";
  const contentRegion = getLocalisedContentRegionByBrand(brand, locale);
  const queryParams = { locale, content_region: contentRegion };

  return await getDataFromPathAndQueryParams({ path, queryParams });
};

export const getContentByAuthorId = async ({
  authorId,
  page,
  limit = CONTENT_LIMIT,
}: GetContentByAuthorIdParams): Promise<AuthorPageResponse> => {
  const path = `content/author/${authorId}`;
  const queryParams = { page, limit };

  return await getDataFromPathAndQueryParams({ path, queryParams });
};

export const retrieveContentOnlyByAuthorId = async ({
  authorId,
  page,
  limit = CONTENT_LIMIT,
}: GetContentByAuthorIdParams): Promise<{ data: AuthorPageData[] }> => {
  const {
    data: { content },
  } = await getContentByAuthorId({
    limit,
    page,
    authorId,
  });

  return { data: content };
};

export {
  fetchAllSlugs,
  fetchArticleBySlug,
  getLatestContentByFullUrlPath,
  getLatestTipsLink,
  getEmbeddedTweetHtml,
  retrieveLatestContentOnlyByFullUrlPath,
  getContentByFullUrlPath,
  getFreeBetCount,
};
