export const isValidIntegerOrDecimal = (value: string): boolean => {
  const numberRegex = /^[.]?\d+(?:[.]\d*?)?$/g;
  const emptyRegex = /$^/g;

  if (!value.match(numberRegex) && !value.match(emptyRegex)) {
    return false;
  }
  if (value.match(/\./g)) {
    const [, decimal] = value.split(".");

    if (decimal.length > 2) {
      return false;
    }
  }

  return true;
};

export const calculateStakeReturnsFromOdds = (
  stake: number,
  numerator: number,
  denominator: number
): number => {
  return stake + (stake * numerator) / denominator;
};

export const twoDecimalFloor = (num: number): string => {
  return (Math.floor(num * 100) / 100).toFixed(2);
};
