import { Dispatch, FC, ReactElement, useContext, useState } from "react";
import dynamic from "next/dynamic";
import {
  BetSlipDispatchContext,
  BetSlipStateContext,
} from "@/contexts/betSlipContext";
import { BetSlipActions, BetSlipState } from "@/types/betslip";
import { BookmakerPayload } from "@/types/api/bookmaker";
import { TimesIcon } from "@/components/icons/TimesIcon";
import { FpBookiesRendererProps } from "@/components/FpBookiesRenderer/FpBookiesRenderer";
import { BookmakerPortalProps } from "@/components/BookmakerPortal/BookmakerPortal";
import { BetslipStakeState } from "@/components/BetslipTabs/BetslipTabs";
import { defaultStakeValueMapping } from "@/constants/stakeSelector";
import { getActiveBrand } from "@/lib/brand";
import { FA_BRAND, FP_BRAND } from "@/constants/brands";
import styles from "./Betslip.module.scss";

const DynamicBookmakerPortal = dynamic<BookmakerPortalProps>(() =>
  import("../BookmakerPortal/BookmakerPortal").then(
    (mod) => mod.BookmakerPortal
  )
);
const DynamicBetSlipTabs = dynamic<BetslipStakeState>(() =>
  import("../BetslipTabs/BetslipTabs").then((mod) => mod.BetslipTabs)
);
const DynamicFpBookiesRenderer = dynamic<FpBookiesRendererProps>(() =>
  import("../FpBookiesRenderer/FpBookiesRenderer").then(
    (mod) => mod.FpBookiesRenderer
  )
);

interface BetSlipProps {
  bookmakers: BookmakerPayload[];
}

export const BetSlip: FC<BetSlipProps> = ({
  bookmakers,
}): ReactElement | null => {
  const dispatch = useContext(
    BetSlipDispatchContext
  ) as Dispatch<BetSlipActions>;
  const { isOpen, portalBookmaker } = useContext(
    BetSlipStateContext
  ) as BetSlipState;
  const brand = getActiveBrand();
  const [stakeValue, setStakeValue] = useState<string>(
    defaultStakeValueMapping.get(brand) ?? "10"
  );
  const [stakeInput, setStakeInput] = useState<"button" | "input">("button");

  const closeBetSlip = () => {
    dispatch({ type: "CLOSE" });
  };

  const clearBetSlip = () => {
    // Reset the selected stake value after clearing the bet slip
    setStakeValue(defaultStakeValueMapping.get(brand) ?? "10");

    dispatch({ type: "CLEAR" });
  };

  return (
    <>
      {brand === FA_BRAND && portalBookmaker ? (
        <DynamicBookmakerPortal
          bookmakerBranding={
            bookmakers.find(
              (bookmaker) => bookmaker.name_cleansed === portalBookmaker
            )?.branding
          }
          bookmaker={portalBookmaker}
          stakeValue={stakeValue}
          setStakeValue={setStakeValue}
          setStakeInput={setStakeInput}
        />
      ) : (
        <section
          className={`${styles.betslip} ${isOpen ? styles.isOpen : ""}`.trim()}
        >
          <header className={styles.betslip__header}>
            <h3 className={styles.betslip__heading}>Bet Slip</h3>
            <button className={styles.betslip__close} onClick={closeBetSlip}>
              Close
              <TimesIcon />
            </button>
            <button className={styles.betslip__clear} onClick={clearBetSlip}>
              Clear
            </button>
          </header>
          <main>
            {brand !== FP_BRAND ? (
              <DynamicBetSlipTabs
                stakeValue={stakeValue}
                setStakeValue={setStakeValue}
                stakeInput={stakeInput}
                setStakeInput={setStakeInput}
              />
            ) : null}
            {brand === FP_BRAND ? (
              <DynamicFpBookiesRenderer
                stakeValue={+stakeValue}
                setStakeValue={setStakeValue}
                stakeInput={stakeInput}
                setStakeInput={setStakeInput}
              />
            ) : null}
          </main>
        </section>
      )}
    </>
  );
};
