import { sizes } from "../../constants/devices";

export interface WindowDimensions {
  width: number;
  height: number;
}

export const checkIsMobileDevice = (windowDimensions: WindowDimensions) =>
  windowDimensions.width < sizes.tablet;

export const checkIsDesktopDevice = (windowDimensions: WindowDimensions) =>
  windowDimensions.width >= sizes.laptop;
