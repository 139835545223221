import {
  CM_BRAND,
  FA_BRAND,
  FP_BRAND,
  OC_BRAND,
  PPC_BRAND,
  TBA_BRAND,
  TWE_BRAND,
} from "../../constants";
import type { BrandShortNames } from "../../types";

export const getSiteUrl = (): string => {
  const brand = process.env.NEXT_PUBLIC_BRAND as BrandShortNames;

  const brandMap = new Map<BrandShortNames, string>([
    [FA_BRAND, "https://footyaccumulators.com"],
    [TWE_BRAND, "https://thewinnersenclosure.com"],
    [FP_BRAND, "https://flashpicks.com"],
    [OC_BRAND, "https://oddschanger.com"],
    [PPC_BRAND, "https://thebonuslounge.com"],
    [CM_BRAND, "https://checkd.group"],
    [TBA_BRAND, "https://thebettinganalysts.com"],
  ]);

  const siteUrl = brandMap.get(brand);

  if (!siteUrl) {
    throw new Error(`Brand ${brand} not found`);
  }

  return siteUrl;
};
