import type { Entity } from "../../../types/draftManager";

export default function atomicsScript(
  entity: Entity
): React.JSX.Element | null {
  const {
    data: { script },
  } = entity;
  if (!script) return null;
  return (
    <figure>
      <div dangerouslySetInnerHTML={{ __html: script }} />
    </figure>
  );
}
