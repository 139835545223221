import dynamic from "next/dynamic";
import Image from "next/image";
import type { AllContentWidgets } from "../../../types/api/content";
import type { DataTableProps } from "../../DataTable";
import type {
  LocalisedBulletpointOfferCardProps,
  OfferCardProps,
} from "../../OfferCard";
import type { EditorProps } from "../../Editor";
import type { BettingTipProps } from "../../BettingTip";
import type { CardWrapperProps } from "../../CardWrapper";
import type { AccordionGroupProps } from "../../AccordionGroup";
import type { TipsterProps } from "../../Tipster";
import type { MediaProps } from "../../Media";
import {
  DATA_TABLE_COMPONENT,
  EDITOR_COMPONENT,
  OFFER_COMPONENT,
  BETTING_TIP_COMPONENT,
  CODE_BLOCK_COMPONENT,
  TWITTER_CARD_COMPONENT,
  MEDIA_COMPONENT,
  ACCORDION_COMPONENT,
  TIPSTER_COMPONENT,
} from "../../../constants/content";
import { bannerLogoUrlFromName } from "../../../utils/media/media";
import { checkAccordionHasContent } from "../utils/widgetContent";
import { isEmptyObject } from "../../../utils/object/object";
import breakpoints from "../../../styles/breakpoints.module.scss";

const DynamicDataTable = dynamic<DataTableProps>(async () =>
  import("../../DataTable").then((mod) => mod.DataTable)
);
const DynamicOfferCard = dynamic<OfferCardProps>(async () =>
  import("../../OfferCard").then((mod) => mod.OfferCard)
);
const DynamicLocalisedBulletpointOfferCard =
  dynamic<LocalisedBulletpointOfferCardProps>(async () =>
    import("../../OfferCard").then((mod) => mod.LocalisedBulletpointOfferCard)
  );
const DynamicBettingTip = dynamic<BettingTipProps>(async () =>
  import("../../BettingTip").then((mod) => mod.BettingTip)
);
const DynamicStarIcon = dynamic(async () =>
  import("../../../components/icons/StarIcon").then((mod) => mod.StarIcon)
);

const DynamicEditor = dynamic<EditorProps>(async () =>
  import("../../Editor").then((mod) => mod.Editor)
);

const DynamicCardWrapper = dynamic<CardWrapperProps>(async () =>
  import("../../CardWrapper").then((mod) => mod.CardWrapper)
);
const DynamicMedia = dynamic<MediaProps>(() =>
  import("../../Media").then((mod) => mod.Media)
);
const DynamicTipster = dynamic<TipsterProps>(() =>
  import("../../Tipster").then((mod) => mod.Tipster)
);

const DynamicAccordionGroup = dynamic<AccordionGroupProps>(async () =>
  import("../../AccordionGroup").then((mod) => mod.AccordionGroup)
);

interface WidgetRendererProps {
  widget: AllContentWidgets;
  contentLastUpdated: number;
}

export function WidgetRenderer({
  widget,
}: WidgetRendererProps): React.JSX.Element | null {
  switch (widget.component) {
    case DATA_TABLE_COMPONENT:
      return widget.leaderboard ? (
        <DynamicDataTable data={widget.leaderboard} />
      ) : null;
    case OFFER_COMPONENT: {
      if (
        isEmptyObject(widget.data) ||
        !widget.data?.[0].meta ||
        widget.data.length === 0
      )
        return null;

      // This needs to be done as feature1 comes back as a string
      // and is the only key signifying whether the incoming widget
      // is a normal offer or a bulletpoint offer
      const { feature1, score } = widget.data[0].meta;

      const isBulletpointWidget = feature1 && score;

      return isBulletpointWidget ? (
        <DynamicLocalisedBulletpointOfferCard offer={widget.data[0]} />
      ) : (
        <DynamicOfferCard offer={widget.data[0]} />
      );
    }
    case EDITOR_COMPONENT: {
      return (
        <DynamicCardWrapper
          hideHeader={!widget.title}
          listHeader={widget.title ?? ""}
        >
          <DynamicEditor formattedContent={widget.formattedContent} />
        </DynamicCardWrapper>
      );
    }
    case BETTING_TIP_COMPONENT: {
      const { title, rating, bookmaker, tip, odds, url, reason, cta, data } =
        widget;

      let site_id = "noBookmakerPredictionCode";

      if (data && bookmaker && data[bookmaker]) {
        site_id = data[bookmaker];
      }

      return title && bookmaker && tip && odds && url ? (
        <DynamicCardWrapper
          headerChildren={
            <Image
              alt={`${bookmaker} logo`}
              fill
              sizes={`(min-width: ${breakpoints.latop}) 28px, 24px`}
              src={bannerLogoUrlFromName(bookmaker)}
            />
          }
          icon={rating && rating === 1 ? <DynamicStarIcon /> : null}
          listHeader={title}
        >
          <DynamicBettingTip
            bookmaker={bookmaker}
            cta={cta}
            odds={odds}
            reason={reason}
            site_id={site_id}
            tip={tip}
            url={url}
          />
        </DynamicCardWrapper>
      ) : null;
    }
    case CODE_BLOCK_COMPONENT:
      return widget.code ? (
        <DynamicEditor formattedContent={widget.code} />
      ) : null;
    case TWITTER_CARD_COMPONENT: {
      const { formattedContent } = widget;

      if (!formattedContent) return null;
      return <DynamicEditor formattedContent={widget.formattedContent} />;
    }
    case MEDIA_COMPONENT:
      return widget.slug ? (
        <DynamicMedia
          alt={widget.alt}
          caption={widget.caption}
          slug={widget.slug}
          target={widget.target}
          url={widget.url}
        />
      ) : null;
    case TIPSTER_COMPONENT: {
      const { tip_category_id: tipCategoryId, brand_id: brandId } = widget;

      if (!tipCategoryId || !brandId || !widget.data) return null;

      return (
        <DynamicTipster
          tipCategoryId={tipCategoryId}
          tipsterData={widget.data}
        />
      );
    }
    case ACCORDION_COMPONENT:
      return checkAccordionHasContent(widget) && widget.title ? (
        <DynamicCardWrapper listHeader={widget.title}>
          <DynamicAccordionGroup questions={widget.questions} />
        </DynamicCardWrapper>
      ) : null;
    default:
      return null;
  }
}
