import { ReactElement } from "react";
import Image from "next/image";
import { CheckdMediaLogo } from "@/components/logos/CheckdMediaLogo";
import { InstagramLogo } from "@/components/icons/InstagramLogo";
import { FacebookLogo } from "@/components/icons/FacebookLogo";
import { LinkedInLogo } from "@/components/icons/LinkedInLogo";
import {
  CheckdAwardsContainer,
  CheckdAwardsImageContainer,
  CheckdLogoContainer,
  CheckdMediaFooterContainer,
  CheckdMediaFooterDesktopRow,
  CheckdMediaFooterH4,
  CopyrightParagraph,
  LegalLink,
  LegalSection,
  OfficeAddress,
  OfficeAddressSection,
  OfficeDetail,
  OfficeName,
  SocialGrid,
} from "@/components/footer/CheckdMediaFooter/CheckdMediaFooter.style";
import IgbAffiliateAwardsLogo from "@/public//images/Logo_Awards.png";
import AffiliateLeadersLogo from "@/public/images/Logo_Affiliate.png";
import EgrAwardsLogo from "@/public/images/EGR.png";
import { XLogo } from "@/components/icons/XLogo";

export const CheckdMediaFooter = (): ReactElement => {
  const socialTitlePrefix = "Checkd Group on ";

  return (
    <CheckdMediaFooterContainer>
      <CheckdMediaFooterDesktopRow>
        <section>
          <CheckdLogoContainer>
            <CheckdMediaLogo />
          </CheckdLogoContainer>
          <CheckdAwardsContainer>
            <CheckdAwardsImageContainer>
              <a
                href="https://www.igbaffiliate.com/igb-affiliate-awards/"
                target="_blank"
                rel="noopener noreferrer"
                title="iGB Affiliate Awards home page"
              >
                <Image
                  src={IgbAffiliateAwardsLogo}
                  data-testid="igbAffiliateAwardsLogo"
                  alt=""
                  fill
                />
              </a>
            </CheckdAwardsImageContainer>
            <CheckdAwardsImageContainer>
              <a
                href="https://affiliateleaders.com/"
                target="_blank"
                rel="noopener noreferrer"
                title="Affiliate Leaders home page"
              >
                <Image
                  src={AffiliateLeadersLogo}
                  data-testid="affiliateLeadersLogo"
                  alt=""
                  fill
                />
              </a>
            </CheckdAwardsImageContainer>
            <CheckdAwardsImageContainer>
              <a
                href="https://www.egroperatorawards.com/"
                target="_blank"
                rel="noopener noreferrer"
                title="EGR Operator Awards home page"
              >
                <Image
                  src={EgrAwardsLogo}
                  data-testid="egrOperatorsLogo"
                  alt=""
                  fill
                />
              </a>
            </CheckdAwardsImageContainer>
          </CheckdAwardsContainer>
        </section>
        <OfficeAddressSection>
          <CheckdMediaFooterH4>Find Us</CheckdMediaFooterH4>
          <OfficeAddress>
            <OfficeName className="building">Spaces Deansgate</OfficeName>
            <OfficeDetail>125 Deansgate, 6th Floor, Manchester</OfficeDetail>
            <OfficeDetail>M3 2LH United Kingdom</OfficeDetail>
          </OfficeAddress>
          <OfficeAddress>
            <OfficeName className="building">1740 Broadway</OfficeName>
            <OfficeDetail>15th Floor, New York</OfficeDetail>
            <OfficeDetail>10019 United States of America</OfficeDetail>
          </OfficeAddress>
        </OfficeAddressSection>
        <section>
          <CheckdMediaFooterH4>Social</CheckdMediaFooterH4>
          <SocialGrid>
            <a
              href="https://x.com/CheckdGroup"
              target="_blank"
              rel="noreferrer"
              title={`${socialTitlePrefix}X`}
            >
              <XLogo />
            </a>
            <a
              href="https://www.instagram.com/checkdgroup/"
              target="_blank"
              title={`${socialTitlePrefix}Instagram`}
              rel="noreferrer"
            >
              <InstagramLogo />
            </a>
            <a
              href="https://www.facebook.com/checkdGroup"
              target="_blank"
              title={`${socialTitlePrefix}Facebook`}
              rel="noreferrer"
            >
              <FacebookLogo />
            </a>
            <a
              href="https://www.linkedin.com/company/checkdgroup/"
              target="_blank"
              title={`${socialTitlePrefix}LinkedIn`}
              rel="noreferrer"
            >
              <LinkedInLogo />
            </a>
          </SocialGrid>
        </section>
      </CheckdMediaFooterDesktopRow>
      <LegalSection>
        <CopyrightParagraph>
          © Checkd Group 2012-{new Date().getFullYear()}
        </CopyrightParagraph>
        <LegalLink href="/privacy-policy">Privacy Policy</LegalLink> |{" "}
        <LegalLink href="/terms-and-conditions">Terms Of Use</LegalLink>
      </LegalSection>
    </CheckdMediaFooterContainer>
  );
};
