import React from "react";
import Image from "next/image";
import { joinWordsWithHyphen } from "../../../utils/string/string";
import breakpoints from "../../../styles/breakpoints.module.scss";
import styles from "./CardWrapper.module.scss";

export interface CardWrapperProps {
  children: React.JSX.Element;
  hideHeader?: boolean;
  competitionHeader?: boolean;
  listHeader: string;
  listImage?: string;
  headerChildren?: React.JSX.Element;
  icon?: React.JSX.Element | null;
  listSubheader?: string;
  date?: string;
}

export function CardWrapper({
  children,
  hideHeader = false,
  competitionHeader = false,
  listHeader,
  listSubheader,
  listImage,
  headerChildren,
  icon,
  date,
}: CardWrapperProps): React.JSX.Element {
  const id = joinWordsWithHyphen(`card list ${listHeader}`);

  const cardListHeaderClassNamesObject = {
    [styles.cardListHeader]: true,
    [styles["cardListHeader--imageMarginRight"]]: Boolean(listImage),
    // By default, this is set to false and will render all headers in uppercase
    [styles["cardListHeader--competitionHeader"]]: competitionHeader,
  };

  const cardListHeaderClassNamesObjectString = Object.keys(
    cardListHeaderClassNamesObject
  )
    .filter((className) => cardListHeaderClassNamesObject[className])
    .join(" ");

  return (
    <div className={styles.cardListContainer} data-testid="cardWrapper">
      {!hideHeader ? (
        <div
          className={`${styles.cardListHeaderContainer} ${
            competitionHeader
              ? styles["cardListHeaderContainer--competitionHeader"]
              : ""
          }`}
          id={id}
        >
          <div className={styles.titleContainer}>
            {icon ? <div className={styles.iconContainer}>{icon}</div> : null}
            <h2
              className={cardListHeaderClassNamesObjectString}
              suppressHydrationWarning
            >
              {listImage ? (
                <Image
                  alt="Header logo"
                  height={24}
                  sizes={`(min-width: ${breakpoints.latop}) 28px, 24px`}
                  src={listImage}
                  width={24}
                />
              ) : (
                ""
              )}
              {listHeader}
            </h2>
          </div>
          {headerChildren ? (
            <div className={styles.headerChildrenContainer}>
              {headerChildren}
            </div>
          ) : null}
          {listSubheader ? (
            <>
              <div className={styles.flexBreak} />
              <h3 className={styles.subheader}>
                {listSubheader}{" "}
                {date ? <span className={styles.date}>{date}</span> : null}
              </h3>
            </>
          ) : null}
        </div>
      ) : null}
      {children}
    </div>
  );
}
