export const getCookieValueByName = (cookieName: string) => {
  const cookieArray = document.cookie.split(";");

  for (const cookie of cookieArray) {
    const [name, value] = cookie.split("=");
    if (name.trim() === cookieName) {
      // We URL decode the cookie value in case it's from the old FA site
      return decodeURIComponent(value);
    }
  }

  return undefined;
};

export const createCookieString = ({
  name,
  value,
  maxAge = 31536000,
  insecure,
}: {
  name: string;
  value: string;
  maxAge?: number;
  insecure?: boolean;
}) => {
  const defaultCookieValue = `${name}=${value}; Max-Age=${maxAge}`;
  return insecure ? defaultCookieValue : `${defaultCookieValue}; Secure`;
};
