import { FP_BRAND } from "@/constants/brands";
import {
  AMERICAN_ODDS_FORMAT,
  DECIMAL_ODDS_FORMAT,
  FRACTIONAL_ODDS_FORMAT,
} from "@/constants/odds";
import { OddsFormats } from "@/types/odds";
import { Bookmaker } from "@/types/api/tipster";
import { normalizeToDecimal } from "@/lib/number";
import { getActiveBrand } from "./brand";

// convert decimal odds to american odds
export function decimalToAmerican(decimalOdds: number): string {
  // if the odds are less than, then the odds are negative
  if (decimalOdds >= 2) {
    return `+${Math.round((decimalOdds - 1) * 100)}`;
  }
  return `${Math.round(-100 / (decimalOdds - 1))}`;
}

// convert fractional odds to decimal odds
export function fractionalToDecimal(fractionalOdds: string): number {
  const [numerator, denominator] = fractionalOdds.split("/");
  return (parseInt(numerator) + parseInt(denominator)) / parseInt(denominator);
}

// format the odds to display based off of locale
export function formatOddsToDisplay(
  odds: string | number,
  locale: string
): string {
  if (locale.startsWith("us")) {
    const convertOddsToDecimal =
      typeof odds === "number" ? odds : fractionalToDecimal(odds);
    return decimalToAmerican(convertOddsToDecimal);
  }
  return odds.toString();
}

export const getDefaultFormattedOdds = () => {
  const brand = getActiveBrand();
  return brand === FP_BRAND ? AMERICAN_ODDS_FORMAT : FRACTIONAL_ODDS_FORMAT;
};

export const getFormattedOdds = (
  // Some historic data doesn't have the odds object, only a single odds format
  allOdds:
    | {
        odds: string | undefined;
        oddsDecimal: string | number | undefined;
        oddsAmerican: string | undefined;
      }
    | string
    | number,
  oddsFormat: OddsFormats
) => {
  if (typeof allOdds === "string" || typeof allOdds === "number") {
    return allOdds;
  }

  const { odds, oddsDecimal, oddsAmerican } = allOdds;

  const MISSING_ODDS_TEXT = odds ?? "0";

  const oddsMap = new Map<OddsFormats, string | number>([
    [AMERICAN_ODDS_FORMAT, oddsAmerican ?? MISSING_ODDS_TEXT],
    [DECIMAL_ODDS_FORMAT, oddsDecimal ?? MISSING_ODDS_TEXT],
    [FRACTIONAL_ODDS_FORMAT, odds ?? MISSING_ODDS_TEXT],
  ]);

  return oddsMap.get(oddsFormat) as OddsFormats;
};

export const getBestOddsFromBookmakers = (
  bookmakers: Bookmaker[]
): Bookmaker[] =>
  bookmakers.slice().sort(
    (a, b) =>
      // Descending order
      normalizeToDecimal(b.oddsDecimal) - normalizeToDecimal(a.oddsDecimal)
  );
