import {
  BrandShortNames,
  BrandThemeNames,
  SportBrandShortNames,
  SportBrandWithTipsShortNames,
} from "@/types/brand";
import {
  BRAND_SHORT_NAMES,
  FA_BRAND,
  FP_BRAND,
  OC_BRAND,
  SPORTS_BLOG_BRAND_SHORT_NAMES,
  SPORTS_BLOG_BRAND_WITH_TIPS_SHORT_NAMES,
} from "@/constants/brands";
import { FA_FREEBETS_ROUTE_BY_REGION } from "@/constants/brandLocalisedContent";

// @ts-expect-error - we're using this to validate so we need to handle non-expected types
export const isValidBrand = (param): param is BrandShortNames =>
  BRAND_SHORT_NAMES.includes(param);

export const checkIsSportsBlogBrandWithTips = (
  param: string
): param is SportBrandWithTipsShortNames =>
  // @ts-expect-error - we're using this to validate so we will receive non-expected values
  SPORTS_BLOG_BRAND_WITH_TIPS_SHORT_NAMES.includes(param);

export const checkIsSportsBlogBrand = (
  param: string
): param is SportBrandShortNames =>
  // @ts-expect-error - we're using this to validate so we will receive non-expected values
  SPORTS_BLOG_BRAND_SHORT_NAMES.includes(param);

export const getActiveBrand = (): BrandShortNames => {
  const brand = process.env.NEXT_PUBLIC_BRAND;
  if (!brand) throw new Error("No brand env var has been set");
  const validBrand = isValidBrand(brand);
  if (!validBrand) throw new Error(`${brand} is not a valid brands`);
  return brand;
};

export const isSidebarDisabled = (page: {
  meta: { sidebar?: boolean };
}): boolean => page.meta.sidebar === false;

export const getBrandTheme = (colourMode: string): BrandThemeNames => {
  const brand = getActiveBrand();
  if (!colourMode || colourMode === "light") {
    return brand;
  }
  if (brand === "fa" && colourMode === "dark") {
    return `${brand}-dark`;
  }
  return brand;
};

export const getFreeBetPath = (
  brand: string,
  contentRegion?: string | null
) => {
  if (brand === FP_BRAND) {
    return "/sportsbooks";
  }
  if (brand === OC_BRAND) {
    return "/bookmaker-offers";
  }
  if (brand === FA_BRAND && contentRegion) {
    return FA_FREEBETS_ROUTE_BY_REGION.get(contentRegion) ?? "/free-bets";
  }
  return "/free-bets";
};
