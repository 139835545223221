import type { OfferData } from "../../types/api/offer";
import { getOffersById } from "../http/offers";
import {
  BETTING_TIP_COMPONENT,
  OFFER_COMPONENT,
  TIPSTER_COMPONENT,
  TWITTER_CARD_COMPONENT,
} from "../../constants/content";
import type {
  ContentTipsterWidget,
  ContentWidgetBase,
  ContentWidgetsArray,
  ContentWidgetsThatNeedData,
} from "../../types/api/content";
import { isEmptyObject } from "../object/object";
import { getOffersForSidebar } from "../http/offerSidebar";
import { isEmptyArray } from "../array/array";
import { DEFAULT_LOCALE } from "../../constants/countries";
import { getTweet } from "../draftManager/blocks/atomic:twitter";
import { getBrunoTipsterData, getCustomTipsByCategory } from "../http/tipster";
import type { BrandPredictionCodes } from "../../types/brand";
import { getPredictionCodes } from "../http/predictionCodes";

function doesWidgetNeedData(
  widget: ContentWidgetBase
): widget is ContentWidgetsThatNeedData {
  return (
    widget.component === OFFER_COMPONENT ||
    widget.component === TWITTER_CARD_COMPONENT ||
    widget.component === TIPSTER_COMPONENT ||
    widget.component === BETTING_TIP_COMPONENT
  );
}

export const filterToWidgetsThatNeedData = (
  widgets: ContentWidgetsArray
): ContentWidgetsThatNeedData[] => widgets.filter(doesWidgetNeedData);

const attachWidgetPromises = <Widget extends ContentWidgetBase>(
  widgets: Widget[],
  locale: string
): Promise<Widget>[] =>
  widgets.map((widget) => {
    const widgetDataNeeded = doesWidgetNeedData(widget);
    if (!widgetDataNeeded) return Promise.resolve(widget);

    switch (widget.component) {
      case OFFER_COMPONENT:
        return enrichOfferWidget<OfferData[], Widget>(
          getOffersById([widget.offer_id], locale),
          widget,
          locale
        );
      case TWITTER_CARD_COMPONENT: {
        const { tweet_url: tweetUrl } = widget;
        if (!tweetUrl) return Promise.resolve(widget);

        return enrichTwitterCardWidget<Widget>(
          getTweet(tweetUrl).then((data) => {
            return { data };
          }),
          widget
        );
      }
      case "Tipster": {
        const { tip_category_id: tipCategoryId } = widget;

        if (!tipCategoryId) return Promise.resolve(widget);

        return enrichWidgetWithCmsAndOptionalBrunoData(
          widget,
          locale
        ) as Promise<Widget>;
      }
      case BETTING_TIP_COMPONENT:
        return enrichWidget<BrandPredictionCodes, Widget>(
          getPredictionCodes().then(({ data }) => {
            return { data };
          }),
          widget
        );
      default:
        return Promise.resolve(widget);
    }
  });

const enrichOfferWidget = async <
  T,
  W extends ContentWidgetBase & { geo_handler?: string }
>(
  promise: Promise<{ data: T }>,
  widget: W,
  locale: string
) => {
  const res = await promise.then(({ data }) => data);
  // if no offer data is returned and the widget has a geo_handler of random,
  // fetch a random offer for the locale from the sidebar offers endpoint
  if (
    isEmptyObject(res) &&
    widget.geo_handler &&
    widget.geo_handler === "random"
  ) {
    const sidebarOffers = await getOffersForSidebar(locale);
    if (sidebarOffers.data.length > 0) {
      const freeBets = sidebarOffers.data.find(
        (offer) => offer._id === "freebets"
      )?.brandOffers;

      // find a random offer from the freeBets array
      const randomOffer =
        freeBets && !isEmptyArray(freeBets) && freeBets.length > 0
          ? freeBets[Math.floor(Math.random() * freeBets.length)]
          : null;
      if (randomOffer && !isEmptyObject(randomOffer)) {
        // if randomOffer is defined update the widget data with the random offer
        // details, replacing the offer_id and the bookmaker properties
        // with the relevant values from the random offer from the sidebar offers endpoint
        return {
          ...widget,
          data: [randomOffer.offer],
          offer_id: randomOffer.offer._id,
          bookmaker: randomOffer.offer.bookmaker.name_cleansed,
        };
      }
    }
  }
  return { ...widget, data: res };
};

export const getWidgetData = async (
  widgets: ContentWidgetsArray,
  locale = DEFAULT_LOCALE
): Promise<ContentWidgetsArray> => {
  return Promise.all(attachWidgetPromises(widgets, locale));
};

const enrichWidget = <T, W extends ContentWidgetBase>(
  promise: Promise<{ data: T }>,
  widget: W
): Promise<W & { data: T }> => {
  return promise.then(({ data }) => ({ ...widget, data }));
};

const enrichTwitterCardWidget = <W extends ContentWidgetBase>(
  promise: Promise<{ data: string }>,
  widget: W
): Promise<W & { formattedContent: string | null }> => {
  return promise.then(({ data }) => ({
    ...widget,
    formattedContent: data,
  }));
};

const enrichWidgetWithCmsAndOptionalBrunoData = async (
  widget: ContentTipsterWidget,
  locale: string
): Promise<ContentTipsterWidget> => {
  const { tip_category_id: tipCategoryId } = widget;

  if (!tipCategoryId) return Promise.resolve(widget);

  const enrichedWidget = await enrichWidget(
    getCustomTipsByCategory(tipCategoryId),
    widget
  );

  const { data } = enrichedWidget;
  const { tips } = data;

  if (isEmptyArray(tips)) return enrichedWidget;

  for (const tip of tips) {
    if (tip.meta.type === "checkd-stats") {
      // eslint-disable-next-line no-await-in-loop -- legacy code
      const brunoResponse = await getBrunoTipsterData(tip, locale);
      // If the response is successful, we enrich the widget with the Bruno data
      if (brunoResponse.code === 0) {
        const index = tips.findIndex((tipRef) => tip._id === tipRef._id);
        enrichedWidget.data.tips[index].outcomes = brunoResponse;
      }
    }
  }

  return enrichedWidget;
};
