import type { Block } from "../../../types/draftManager";

export default function HeaderThree(
  block: Block,
  originalText: string
): React.JSX.Element {
  const { data } = block;

  return <h3 style={{ textAlign: data?.alignment }}>{originalText}</h3>;
}
