import type { ContentPayload, PageMetaData } from "../../types";

export const pageMetaDataFromContentPayload = (
  page: ContentPayload
): PageMetaData => {
  const { meta, full_url_path: fullUrlPath, title: pageTitle } = page;
  const {
    title: metaTitle,
    description,
    schema,
    featured_image: featuredImage,
  } = meta;

  return {
    title: metaTitle || pageTitle,
    description,
    fullUrlPath,
    schema,
    featuredImage,
  };
};
