import type { Block } from "../../../types/draftManager";

export default function Blockquote(
  block: Block,
  originalText: string
): React.JSX.Element {
  const { data } = block;

  return (
    <blockquote style={{ textAlign: data?.alignment }}>
      {originalText}
    </blockquote>
  );
}
