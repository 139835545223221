import React, { ReactElement, useEffect, useRef } from "react";

interface InjectedHtmlContainerProps {
  html: string;
}

export const InjectedHtmlContainer = ({
  html,
}: InjectedHtmlContainerProps): ReactElement => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!html) return;

    // Create a 'tiny' document and parse the html string
    const slotHtml = document.createRange().createContextualFragment(html);

    if (divRef.current !== null) {
      // Clear the container
      divRef.current.innerHTML = "";

      // Append the new content
      divRef.current.appendChild(slotHtml);
    }
  }, [html]);

  return <div data-testid="injectedHtmlContainer" ref={divRef}></div>;
};
