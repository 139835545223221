type GenericObjectTypes = {
  [key: string]: unknown;
};

// This util function removes falsey values from objects
// and returns a new object with only the values that are truthy
export const removeFalseyValuesFromObjects = (obj: GenericObjectTypes) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (!newObj[key]) {
      delete newObj[key];
    }
  });
  return newObj;
};

export const isEmptyObject = (val: unknown): val is null => {
  return !val || Object.keys(val).length === 0;
};

export const queryParamsToString = (
  object: Record<string, string | number | boolean | null | undefined>
): Record<string, string> => {
  const res: Record<string, string> = {};

  for (const [key, value] of Object.entries(object)) {
    if (value !== null && value !== undefined) {
      res[key] = String(value);
    }
  }

  return res;
};
