import { useEffect, useState } from "react";
import { BrandShortNames } from "@/types/brand";
import { CM_BRAND, PPC_BRAND } from "@/constants/brands";
import { getFreeBetCount } from "@/lib/http/content";

export const useFreeBetCount = (
  brand: BrandShortNames,
  locale: string
): number => {
  const [offerCount, setOfferCount] = useState<number>(0);

  useEffect(() => {
    if (!locale) return;
    async function fetchFreeBetCount() {
      if (brand === PPC_BRAND || brand === CM_BRAND) return;
      const {
        data: { freeBetsCount },
      } = await getFreeBetCount(locale);
      if (freeBetsCount) {
        setOfferCount(freeBetsCount);
      }
    }
    fetchFreeBetCount();
  }, [brand, locale]);

  return offerCount;
};
