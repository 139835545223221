import { Disclaimer } from "@/types/staticTranslations";
import styles from "./DisclaimerText.module.scss";

const DisclaimerText = ({ disclaimer }: { disclaimer: Disclaimer }) => {
  return (
    <>
      <h4 className={styles.disclaimerH4}>{disclaimer.heading}</h4>
      <p className={styles.disclaimerParagraph} data-testid="footerDisclaimer">
        {disclaimer.content[0]}{" "}
        <a
          className={styles.disclaimerContactLink}
          href={`mailto:${disclaimer.emailLink}`}
        >
          {disclaimer.emailLink}
        </a>{" "}
        {disclaimer.content[1]}
      </p>
    </>
  );
};

export default DisclaimerText;
