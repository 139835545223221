import { OddsFormats } from "@/types/odds";

export const AMERICAN_ODDS_FORMAT = "American";
export const DECIMAL_ODDS_FORMAT = "Decimal";
export const FRACTIONAL_ODDS_FORMAT = "Fractional";
export const DEFAULT_ODDS_FORMAT: OddsFormats[] = [
  FRACTIONAL_ODDS_FORMAT,
  DECIMAL_ODDS_FORMAT,
  AMERICAN_ODDS_FORMAT,
];
export const FP_ODDS_FORMAT: OddsFormats[] = [
  AMERICAN_ODDS_FORMAT,
  DECIMAL_ODDS_FORMAT,
  FRACTIONAL_ODDS_FORMAT,
];
export const ODDS_FORMAT_TEXT_MAP = new Map<OddsFormats, string>([
  [AMERICAN_ODDS_FORMAT, `${AMERICAN_ODDS_FORMAT} (+300)`],
  [DECIMAL_ODDS_FORMAT, `${DECIMAL_ODDS_FORMAT} (3.00)`],
  [FRACTIONAL_ODDS_FORMAT, `${FRACTIONAL_ODDS_FORMAT} (2/1)`],
]);
export const ODDS_FORMAT_COOKIE_NAME = "odds_format";
