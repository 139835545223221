export function TickIcon() {
  return (
    <svg
      height="12px"
      version="1.1"
      viewBox="0 0 12 12"
      width="12px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Tick Icon</title>
      <g
        fill="none"
        fillRule="evenodd"
        id="Mobile"
        stroke="none"
        strokeWidth="1"
      >
        <g
          fill="currentColor"
          fillRule="nonzero"
          id="5.1-mobile_offers"
          transform="translate(-32, -423)"
        >
          <g id="Group-4" transform="translate(16, 240)">
            <g id="Group-2" transform="translate(0, 169)">
              <path
                d="M22,26 C18.6862,26 16,23.3138 16,20 C16,16.6862 18.6862,14 22,14 C25.3138,14 28,16.6862 28,20 C28,23.3138 25.3138,26 22,26 Z M20.9458,22.691 L25.1878,18.4484 L24.3394,17.6 L20.9458,20.9942 L19.2484,19.2968 L18.4,20.1452 L20.9458,22.691 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
