import { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

interface IconProps {
  className?: string;
}

const AngleRightIcon = ({ className }: IconProps): ReactElement => {
  return <FontAwesomeIcon className={className} icon={faAngleRight} />;
};

export default AngleRightIcon;
