import { useRouter } from "next/router";
import { addAdTrackingParameterToDataOfferMetaLink } from "../../../utils/string/string";
import type { PpcAdTrackingBulletpointOfferCardMetaProps } from "../../../types/api/ppc";
import { BulletpointOfferCard } from "./BulletpointOfferCard";

export interface PpcAdTrackingBulletpointOfferCardProps {
  offer: {
    bookmaker: {
      name_cleansed: string;
      name: string;
      tracking_parameter?: string;
    };
    meta: PpcAdTrackingBulletpointOfferCardMetaProps;
  };
}

export function PpcAdTrackingBulletpointOfferCard({
  offer,
}: PpcAdTrackingBulletpointOfferCardProps) {
  const router = useRouter();
  const {
    query: { gclid, msclikid },
  } = router;

  const { bookmaker, meta } = offer;

  const ppcOffer = {
    bookmaker,
    meta: addAdTrackingParameterToDataOfferMetaLink(
      meta,
      bookmaker.tracking_parameter ?? "",
      (gclid as string) || (msclikid as string)
    ),
  };

  return <BulletpointOfferCard offer={ppcOffer} />;
}
