import { ChangeEvent, useEffect, useState } from "react";
import { getDefaultColourMode } from "@/lib/getDefaultColourMode";

export const useDarkMode = (): [
  string,
  // eslint-disable-next-line no-unused-vars
  (evt: ChangeEvent<HTMLInputElement>) => void
] => {
  const [colourMode, setColourMode] = useState<string>("");

  function toggleColourMode(event: ChangeEvent<HTMLInputElement>) {
    const { checked } = event.target;
    if (checked) {
      setColourMode("dark");
      // store the colour mode in a cookie
      document.cookie = `colourMode=dark; path=/; max-age=31536000`;
    } else {
      setColourMode("light");
      document.cookie = `colourMode=light; path=/; max-age=31536000`;
    }
  }

  useEffect(() => {
    setColourMode(getDefaultColourMode());
  }, []);

  return [colourMode, toggleColourMode];
};
