/* eslint-disable @next/next/no-img-element */
import Image from "next/image";
import { Entity } from "@/types/draftManager";
import styles from "@/lib/draftManager/blocks/atomic:giphy.module.scss";
import { isValidGifDomain } from "@/lib/string";

// This component returns an empty span if !giphy instead of null because convertToHTML cannot handle null
// Empty span is still not rendered in the DOM by removeEmptyElements
export default function giphy(entity: Entity): JSX.Element {
  const {
    data: { giphy },
  } = entity;

  if (!giphy) return <span></span>;

  const { size, sizes } = giphy;
  const url = sizes[size];

  // Check that url starts with valid domain name for gifs
  return isValidGifDomain(url) ? (
    <div className={styles.imageWrapper}>
      <Image className={styles.image} src={url} alt="giphy-gif" fill={true} />
    </div>
  ) : (
    <img src={url} alt="gif" />
  );
}
