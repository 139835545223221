import type { BetBuilderNonBrunoEventData } from "../../../types/api/tipster";
import styles from "./BetBuilderTipsterNonBrunoMatchDetails.module.scss";

interface BetBuilderTipsterNonBrunoMatchDetailsProps {
  eventData: BetBuilderNonBrunoEventData;
}

export function BetBuilderTipsterNonBrunoMatchDetails({
  eventData,
}: BetBuilderTipsterNonBrunoMatchDetailsProps): React.JSX.Element {
  return (
    <>
      <span className={styles.competitionInfo}>{eventData.competition}</span>
      <div className={styles.teamInfo}>
        <div>
          <span>{eventData.fixture}</span>
        </div>
      </div>
    </>
  );
}
