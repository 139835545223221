import styles from "./MediaCaption.module.scss";

interface MediaCaptionProps {
  caption: string;
  children: React.JSX.Element;
}

export function MediaCaption({
  caption,
  children,
}: MediaCaptionProps): React.JSX.Element {
  return (
    <figure className={styles.figure}>
      {children}
      <figcaption className={styles.caption}>{caption}</figcaption>
    </figure>
  );
}
