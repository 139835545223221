import { neutrals } from "./crossBrand";
// Rather than trying to come up with color names we're just giving
// setting the key as the next character in the alphabet

export const fpColors = {
  ...neutrals,
  flashPicksRed: "#FF0000",
  flashPicksBlue: {
    100: "#052F6B",
    200: "#074091",
    300: "#003b82",
  },
  flashPicksYellow: "#EFE52E",
  smartAccaGreen: "#44DB5E",
  paleBlue: "#efefef",
  lessDarkGrey: "#cdd5e1",
  lessDarkBlue: "#111a24",
  veryLightGrey: "#f4f5f7",
};
