import { useState } from "react";
import { InformationIcon } from "../../../components/icons/InformationIcon";
import { gaEvent } from "../../../utils/gtag/gtag";
import styles from "./BettingTip.module.scss";

export interface BettingTipProps {
  bookmaker: string;
  tip: string;
  odds: string;
  cta?: string;
  url: string;
  reason?: string;
  site_id: string;
}

export function BettingTip({
  bookmaker,
  tip,
  odds,
  cta = "ADD TO BETSLIP",
  url,
  reason,
  site_id,
}: BettingTipProps) {
  const [displayReason, setDisplayReason] = useState(false);

  const handleClick = (): void => {
    setDisplayReason(!displayReason);
  };

  const offerPressedEvent = (): void => {
    gaEvent("offer_pressed", {
      bookmaker,
      category: "Betting Tip Widget",
      offer: tip,
      site_id,
    });
  };

  return (
    <>
      <div className={styles.tipContainer}>
        <div className={styles.wrapper}>
          <h5 className={styles.tip}>{`${tip} @ ${odds}`}</h5>
          {reason ? (
            <button
              aria-label="Show tip reason"
              className={styles.button}
              onClick={handleClick}
              type="button"
            >
              <InformationIcon className={styles.icon} />
            </button>
          ) : null}
        </div>
        <a
          className={styles.link}
          href={url}
          onClick={offerPressedEvent}
          rel="noopener noreferrer"
          target="_blank"
        >
          {cta}
        </a>
      </div>
      {displayReason ? (
        <div className={styles.reasonContainer}>
          <p className={styles.reason}>{reason}</p>
        </div>
      ) : null}
    </>
  );
}
