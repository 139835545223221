import type { RawDraftContentBlock, RawDraftContentState } from "draft-js";
import { parseString } from "../../../utils/string/string";
import type {
  ContentAccordionQuestion,
  ContentAccordionWidget,
} from "../../../types/api/content";

export const checkIfQuestionAndEditorStateContentEmpty = (
  questions: Record<string, ContentAccordionQuestion> | undefined
): boolean => {
  // Returns false if we don't have any questions (no editorState and no question)
  if (!questions) return false;

  let hasQuestionContent = false;
  let hasSomeEditorStateContent = false;
  for (const key in questions) {
    if (questions[key].question) hasQuestionContent = true;
    const editorState = questions[key].editorState;
    if (editorState && typeof editorState === "string") {
      const formattedEditorState = parseString(
        editorState
      ) as RawDraftContentState;
      hasSomeEditorStateContent = formattedEditorState.blocks.some(
        (block: RawDraftContentBlock) => {
          return block.text !== "";
        }
      );
    }

    if (hasQuestionContent || hasSomeEditorStateContent) break;
  }

  // Returns true if at least one editorState has content or if a question has content
  return hasQuestionContent || hasSomeEditorStateContent;
};

export const checkAccordionHasContent = (
  widget: ContentAccordionWidget
): boolean => {
  if (widget.questions && Object.keys(widget.questions).length > 0) {
    // Check within these questions if there's any content
    return checkIfQuestionAndEditorStateContentEmpty(widget.questions);
  }
  return false;
};
