import { getActiveBrand } from "../brand/brand";
import { FA_BRAND, FP_BRAND, TBA_BRAND } from "../../constants/brand";
import type { GtagEventParams } from "../../types/gtag";

const brand = getActiveBrand();

declare global {
  interface Window {
    gtag: (event: "event", action: string, params?: GtagEventParams) => void;
  }
}

const enableAnalytics = () =>
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, no-constant-binary-expression, valid-typeof -- for some reason NextJS config doesn't expect node
  (typeof window !== undefined && brand === FA_BRAND) ||
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, no-constant-binary-expression, valid-typeof -- for some reason NextJS config doesn't expect node
  (typeof window !== undefined && brand === TBA_BRAND) ||
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, no-constant-binary-expression, valid-typeof -- for some reason NextJS config doesn't expect node
  (typeof window !== undefined && brand === FP_BRAND);

export const gaEvent = (
  eventName: string,
  eventParams?: GtagEventParams
): void => {
  if (enableAnalytics()) {
    window.gtag("event", eventName, eventParams);
  }
};
