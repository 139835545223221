// regex to extract script tags
// explanation:
// <script.*?> matches the opening script tag
// .*? match everything between the opening and closing script tag
// <\/script> matches the closing script tag
const scriptRegex = new RegExp(/<script[^>]*?>.*?<\/script>/gs);

export const extractScriptTagsFromHtmlString = (
  htmlString: string
): string[] => {
  const script = htmlString.match(scriptRegex);
  return script ?? [];
};

export const extractScriptSrc = (scriptTag: string): string => {
  // extract only the url from the scriptTag's src for example: https://www.sporcle.com/embed/embed.js?v=60e6e83a80c2f
  // the regex matches the src attribute and extracts the url
  const url = /src=(?<temp1>[^ >]+)/.exec(scriptTag)?.[1].replace(/['"]/g, "");
  return url || "";
};
