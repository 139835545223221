import { UrlObject } from "url";
import Link from "next/link";

interface Props {
  children: React.ReactNode;
  href: string | UrlObject;
  className?: string;
  "data-testid"?: string;
  "aria-hidden"?: boolean;
  "aria-pressed"?: boolean;
  "aria-label"?: string;
  prefetch?: boolean;
  style?: Record<string, string | number>;
  target?: string;
  rel?: string;
  onClick?: () => void;
}

export const LinkNoPrefetch = ({ children, ...props }: Props) => (
  <Link prefetch={false} {...props}>
    {children}
  </Link>
);
