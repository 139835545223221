import { useState } from "react";
import { fpCompetitionSportsMap } from "../../../constants/competitions";
import type { SmartAccaMatchStatSelection } from "../../../types/api/smartAcca";
import type { Events } from "../../../types/api/tipster";
import { fullDateToSplitShortDate } from "../../../utils/date/date";
import styles from "./Stats.module.scss";

interface StatsProps {
  tipsterStats: Events;
  statsType: string;
  selection: SmartAccaMatchStatSelection;
}

export function Stats({
  tipsterStats,
  statsType,
  selection,
}: StatsProps): React.JSX.Element {
  const [tab, setTab] = useState<"home" | "away">("home");

  const { event, stats } = tipsterStats;
  const { meta } = event;
  const { competition, tournamentCalendar } = meta;

  const awayTeamHistory = stats.find(
    (s) => s.team.position === "away"
  )?.history;
  const homeTeamHistory = stats.find(
    (s) => s.team.position === "home"
  )?.history;

  const teamStats = tab === "away" ? awayTeamHistory : homeTeamHistory;

  const statSelectionTitles = new Map<SmartAccaMatchStatSelection, string>([
    ["season", "Season"],
    ["last5", "Last 5"],
    ["h2h", "H2H"],
    ["homeaway", "Home/Away"],
  ]);

  const isUsSports = Boolean(
    competition.id && fpCompetitionSportsMap.has(competition.id)
  );

  const headerText = isUsSports
    ? `${competition.id} ${tournamentCalendar?.id}`
    : `${competition.name} ${tournamentCalendar?.name}`;

  return (
    <div className={styles.stats} data-testid="stats">
      {statsType === "both" || statsType === "%" ? (
        <div>
          <div className={styles.headerContainer}>
            <span className={styles.header}>{`${statSelectionTitles.get(
              selection
            )} • ${headerText}`}</span>
          </div>
          <div
            className={styles.percentageContainer}
            data-testid="percentageStats"
          >
            {stats.map(({ probability, team }) => {
              return (
                <div key={team.name}>
                  <span>{`${probability.toFixed(2)}%`}</span>
                  <span>{team.name ? team.name : "Draw"}</span>
                  <span>{team.position}</span>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {(statsType === "both" || statsType === "fixture") &&
      homeTeamHistory &&
      awayTeamHistory &&
      homeTeamHistory.length > 0 &&
      awayTeamHistory.length > 0 ? (
        <>
          {/* no need for Home and Away tabs since it is a Head to Head */}
          {/* and therefore shows the last 5 of both teams when they played each other */}
          {selection === "h2h" ? (
            <div className={styles.headerContainer}>
              <span className={styles.header}>Last 5 H2H Results</span>
            </div>
          ) : (
            <div className={`${styles.formTabs} ${styles[`formTabs--${tab}`]}`}>
              <button
                aria-pressed={tab === "home"}
                className={`${styles.formTab} `}
                onClick={() => {
                  setTab("home");
                }}
                type="button"
              >
                Home
              </button>
              <button
                aria-pressed={tab === "away"}
                className={styles.formTab}
                onClick={() => {
                  setTab("away");
                }}
                type="button"
              >
                Away
              </button>
              <hr />
            </div>
          )}

          {teamStats && teamStats.length > 0 ? (
            <table className={styles.table}>
              <tbody>
                {teamStats.map((row) => {
                  const homeTeamWin =
                    row.homeTeamScore > row.awayTeamScore ? styles.win : "";
                  const awayTeamWin =
                    row.awayTeamScore > row.homeTeamScore ? styles.win : "";

                  return (
                    <tr key={row.id}>
                      <td>
                        {fullDateToSplitShortDate(row.date).numericalDate}
                      </td>
                      <td className={homeTeamWin}>{row.homeTeamName}</td>
                      <td className={awayTeamWin}>{row.awayTeamName}</td>
                      <td>{`${row.homeTeamScore} : ${row.awayTeamScore}`}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : null}
        </>
      ) : null}
    </div>
  );
}
