export function XLogo() {
  return (
    <svg
      aria-labelledby="X Logo"
      height="36px"
      version="1.1"
      viewBox="0 0 36 36"
      width="36px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>X Logo</title>
      <defs>
        <path
          d="M20.0951333,16.3165695 L28.2864667,7 L26.3460667,7 L19.2305333,15.0877935 L13.5516,7 L7,7 L15.5895333,19.2313405 L7,29 L8.9404,29 L16.4497333,20.4571857 L22.4484,29 L29,29 M9.64073333,8.43174022 L12.6217333,8.43174022 L26.3446,27.6386012 L23.3628667,27.6386012"
          id="x-logo"
        />
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        id="::-Typography"
        stroke="none"
        strokeWidth="1"
      >
        <g id="twitter-icon">
          <rect height="36" id="Rectangle" width="36" x="0" y="0" />
          <mask fill="white" id="mask-2">
            <use xlinkHref="#x-logo" />
          </mask>
          <use fill="#FFFFFF" id="x-logo" xlinkHref="#x-logo" />
        </g>
      </g>
    </svg>
  );
}
