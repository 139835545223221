export function ExternalLinkIcon() {
  return (
    <svg
      height="12px"
      version="1.1"
      viewBox="0 0 12 12"
      width="12px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>External Link Icon</title>
      <g
        fill="none"
        fillRule="evenodd"
        id="Mobile"
        stroke="none"
        strokeWidth="1"
      >
        <g
          fill="#FFFFFF"
          fillRule="nonzero"
          id="5.1-mobile_offers"
          transform="translate(-217, -368)"
        >
          <g id="Group-4" transform="translate(16, 240)">
            <g id="Group-9" transform="translate(16, 114)">
              <path
                d="M189.666667,16 L189.666667,17.3333333 L186.333333,17.3333333 L186.333333,24.6666667 L193.666667,24.6666667 L193.666667,21.3333333 L195,21.3333333 L195,25.3333333 C195,25.7015333 194.701533,26 194.333333,26 L185.666667,26 C185.29848,26 185,25.7015333 185,25.3333333 L185,16.6666667 C185,16.29848 185.29848,16 185.666667,16 L189.666667,16 Z M197,14 L197,19.3333333 L195.666667,19.3333333 L195.6666,16.2753333 L190.4714,21.4714 L189.528593,20.5286 L194.723267,15.3333333 L191.666667,15.3333333 L191.666667,14 L197,14 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
