interface Props {
  styleColor?: string;
}

const MenuIcon = ({ styleColor = "#B6CBD8" }: Props) => (
  <svg
    width="20px"
    height="12px"
    viewBox="0 0 16 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="menuIcon"
    aria-hidden="true"
  >
    <title>Menu Icon</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="320--Fixtures"
        transform="translate(-24.000000, -35.000000)"
        fill={styleColor}
      >
        <g id="::-Header">
          <g id="Group-3" transform="translate(16.000000, 24.000000)">
            <path
              d="M22.7076923,19.6923077 C22.9795863,19.6923077 23.2,19.9127214 23.2,20.1846154 L23.2,20.3076923 C23.2,20.5795863 22.9795863,20.8 22.7076923,20.8 L9.29230769,20.8 C9.02041366,20.8 8.8,20.5795863 8.8,20.3076923 L8.8,20.1846154 C8.8,19.9127214 9.02041366,19.6923077 9.29230769,19.6923077 L22.7076923,19.6923077 Z M22.7076923,15.4461538 C22.9795863,15.4461538 23.2,15.6665675 23.2,15.9384615 L23.2,16.0615385 C23.2,16.3334325 22.9795863,16.5538462 22.7076923,16.5538462 L9.29230769,16.5538462 C9.02041366,16.5538462 8.8,16.3334325 8.8,16.0615385 L8.8,15.9384615 C8.8,15.6665675 9.02041366,15.4461538 9.29230769,15.4461538 L22.7076923,15.4461538 Z M22.7076923,11.2 C22.9795863,11.2 23.2,11.4204137 23.2,11.6923077 L23.2,11.8153846 C23.2,12.0872786 22.9795863,12.3076923 22.7076923,12.3076923 L9.29230769,12.3076923 C9.02041366,12.3076923 8.8,12.0872786 8.8,11.8153846 L8.8,11.6923077 C8.8,11.4204137 9.02041366,11.2 9.29230769,11.2 L22.7076923,11.2 Z"
              id="Menu-Icon"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MenuIcon;
