import { FA_BRAND, FP_BRAND, TWE_BRAND } from "@/constants/brands";

export const NCAAF = "NCAAF";
export const NCAAB = "NCAAB";

export const activeFaCompetitions = [
  "premier-league",
  "championship",
  "league-one",
  "league-two",
  "champions-league",
  "europa-league",
  "europa-conference-league",
  "fa-cup",
  "league-cup",
  "world-cup",
  "uk-football",
  "european-football",
  "world-football",
  "international-football",
  "manager-transfers",
  "player-transfers",
];

export const faCompetitionNameMap = new Map([
  ["premier-league", "Premier League"],
  ["championship", "Championship"],
  ["league-one", "League One"],
  ["league-two", "League Two"],
  ["champions-league", "Champions League"],
  ["europa-league", "Europa League"],
  ["europa-conference-league", "Europa Conference League"],
  ["fa-cup", "FA Cup"],
  ["league-cup", "League Cup"],
  ["world-cup", "World Cup"],
  ["uk-football", "UK Football"],
  ["european-football", "European Football"],
  ["world-football", "World Football"],
  ["international-football", "International Football"],
]);

export const faFormattedCompetitionNamesMap = new Map([
  ["league-1", "league-one"],
  ["league-2", "league-two"],
]);

export const activeFpCompetitions = [
  "nba",
  "mlb",
  "nhl",
  "soccer",
  "college-football",
  "nfl",
  "college-basketball",
];

export const fpCompetitionSlugToNameMap = new Map([
  ["nba", "NBA"],
  ["mlb", "MLB"],
  ["nhl", "NHL"],
  ["soccer", "Soccer"],
  ["college-football", NCAAF],
  ["nfl", "NFL"],
  ["college-basketball", NCAAB],
]);

export const activeCompetitionsPerBrand = new Map([
  [FA_BRAND, activeFaCompetitions],
  [FP_BRAND, activeFpCompetitions],
  [TWE_BRAND, []],
]);

export const fpCompetitionSportsMap = new Map([
  ["NFL", "American Football"],
  ["MLB", "Baseball"],
  ["NBA", "Basketball"],
  ["NHL", "Ice Hockey"],
  ["NCAAF", "College Football"],
  ["NCAAB", "College Basketball"],
]);
