// Disabled as NextRequest shouldn't be used outside of the middleware.
// But we are only using for the declaration type.
import { NextRequest } from "next/server";
import {
  allSupportedRegions,
  BRITISH_ENGLISH_LANG_CODE,
  LOCALE_LANGUAGES,
  US_ENGLISH_LANG_CODE,
} from "@/constants/countries";
import { UsStates } from "@/types/api/config";
import { BrandShortNames } from "@/types/brand";
import { FP_BRAND } from "@/constants/brands";
import { getActiveBrand } from "./brand";

// dynamically loops over the countries defined in the countries.ts file
// if we support regions, which are identified by the country and region being seperated by a dash
// example: {country}-{region}
// we create a Set of these of regions from the country code so we don't have to manage manually
export function getSupportedRegionFromAreaCode(
  countryCode: string
): Set<string | undefined> {
  const supportedRegionsSet = new Set<string>();
  allSupportedRegions.forEach((country) => {
    if (country.startsWith(`${countryCode}-`)) {
      const [, region] = country.split("-");
      supportedRegionsSet.add(region);
    }
  });
  return supportedRegionsSet;
}

// checks that the country is supported and converts it to a supported country code
export function convertToSupportedGeo(geo: NextRequest["geo"]) {
  // Country code specification by Vercel: https://en.wikipedia.org/wiki/ISO_3166-1
  const { country, region } = geo ?? {};
  if (!country) return "uk";
  const supportedRegions = getSupportedRegionFromAreaCode(country);
  if (supportedRegions.has(region)) {
    return allSupportedRegions.get(`${country}_${region}`)?.toLowerCase();
  }
  if (country === "US") {
    return "us-nj";
  }
  if (allSupportedRegions.has(country)) {
    return allSupportedRegions.get(country)?.toLowerCase();
  }
  return "uk";
}

export type LocalisedConfig<T> = {
  default: T;
} & Partial<{ [locale: string]: T }>;

interface LocalisedConfigParams {
  locale: string;
  fallbackKey: "default" | BrandShortNames;
}

export function getLocalisedConfig<T>({
  locale,
  fallbackKey = "default",
  localisedConfig,
}: LocalisedConfigParams & {
  localisedConfig: LocalisedConfig<T>;
}): T {
  if (locale) {
    const localisedConfigForLocale = localisedConfig[locale];
    if (localisedConfigForLocale) {
      return localisedConfigForLocale;
    }
  }
  return localisedConfig[fallbackKey] ?? localisedConfig.default;
}

export const getStateFromRegion = (region?: string) => {
  if (!region) return null;

  return region.includes("-") ? region.split("-")[1] : region;
};

export const filterStatesByStatus = (
  status: UsStates["status"],
  states?: UsStates[]
) => {
  return states ? states.filter((state) => state.status === status) : [];
};

export const getHtmlLangCode = (region: string) => {
  const brand = getActiveBrand();
  const defaultLang =
    brand === FP_BRAND ? US_ENGLISH_LANG_CODE : BRITISH_ENGLISH_LANG_CODE;
  const langCode = LOCALE_LANGUAGES.get(region ?? "");
  return langCode ?? defaultLang;
};
