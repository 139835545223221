import type { ContentAccordionWidget } from "../../../types/api/content";
import { Accordion } from "./Accordion";
import styles from "./AccordionGroup.module.scss";

export type AccordionGroupProps = Pick<ContentAccordionWidget, "questions">;

export function AccordionGroup({ questions }: AccordionGroupProps) {
  if (!questions) return null;

  const questionKeys = Object.keys(questions);

  return (
    <>
      {questionKeys.map((key) => {
        const { editorState, question } = questions[key];
        if (!question || !editorState) return null;
        return (
          <div
            className={styles.accordionGroup}
            data-testid="accordionContainer"
            key={key}
          >
            <Accordion
              accordionKey={key}
              editorState={editorState}
              question={question}
            />
          </div>
        );
      })}
    </>
  );
}
