import { Entity } from "../../../types/draftManager";

export default function script(entity: Entity): JSX.Element | null {
  const {
    data: { script },
  } = entity;
  if (!script) return null;
  return (
    <figure>
      <div dangerouslySetInnerHTML={{ __html: script }} />
    </figure>
  );
}
