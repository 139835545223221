import type { OfferData } from "../../../types/api/offer";
import { useLocaleFromRouter } from "../../../hooks/LocationFromRouter";
import {
  localisedOfferLink,
  localisedOfferScoreAndFeatures,
  localisedOfferTitleAndTermsAndConditions,
} from "../utils/offer";
import { BulletpointOfferCard } from "./BulletpointOfferCard";

export interface LocalisedBulletpointOfferCardProps {
  offer: OfferData;
}

export function LocalisedBulletpointOfferCard({
  offer,
}: LocalisedBulletpointOfferCardProps) {
  const [locale] = useLocaleFromRouter();

  const {
    bookmaker: { name_cleansed: nameCleansed, name, tracking_parameter },
    meta: { cta },
  } = offer;
  const offerLink = localisedOfferLink(offer, locale);
  const { title, terms_and_conditions: termsAndConditions } =
    localisedOfferTitleAndTermsAndConditions(offer, locale);
  const { feature1, feature2, feature3, score } =
    localisedOfferScoreAndFeatures(offer, locale);

  /* This needs to be done as shape of data from CMS
  comes as three seperate keys rather than in an array */
  const features = [feature1, feature2, feature3];

  const localisedOffer = {
    bookmaker: {
      name_cleansed: nameCleansed,
      name,
      tracking_parameter,
    },
    meta: {
      cta,
      link: offerLink ?? "",
      title,
      terms_and_conditions: termsAndConditions,
      score,
      features,
    },
  };

  return <BulletpointOfferCard offer={localisedOffer} />;
}
