import { ReactElement } from "react";

export const FacebookLogo = (): ReactElement => (
  <svg
    width="17px"
    height="31px"
    viewBox="0 0 17 31"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Facebook Logo</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Landing"
        transform="translate(-1570.000000, -6776.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="::-Footer" transform="translate(0.000000, 6534.000000)">
          <g id="::-Social" transform="translate(1560.000000, 100.000000)">
            <g id="Social-Logos" transform="translate(0.000000, 72.000000)">
              <g id="Path" transform="translate(10.000000, 70.000000)">
                <path d="M14.6881833,0.00618556701 L11.0329255,0 C6.92636884,0 4.2725315,2.87164948 4.2725315,7.31628866 L4.2725315,10.6895876 L0.597333333,10.6895876 C0.279752577,10.6895876 0.0225796105,10.961134 0.0225796105,11.2960825 L0.0225796105,16.1836082 C0.0225796105,16.5185567 0.280045819,16.7897938 0.597333333,16.7897938 L4.2725315,16.7897938 L4.2725315,29.1225773 C4.2725315,29.4575258 4.52970447,29.7287629 4.84728522,29.7287629 L9.64237342,29.7287629 C9.95995418,29.7287629 10.2171271,29.4572165 10.2171271,29.1225773 L10.2171271,16.7897938 L14.514291,16.7897938 C14.8318717,16.7897938 15.0890447,16.5185567 15.0890447,16.1836082 L15.0908041,11.2960825 C15.0908041,11.1352577 15.0301031,10.9812371 14.9224834,10.8674227 C14.8148637,10.7536082 14.6682428,10.6895876 14.5157572,10.6895876 L10.2171271,10.6895876 L10.2171271,7.83 C10.2171271,6.45556701 10.5276701,5.75783505 12.2252463,5.75783505 L14.6875968,5.75690722 C15.0048843,5.75690722 15.2620573,5.48536082 15.2620573,5.15072165 L15.2620573,0.612371134 C15.2620573,0.278041237 15.0051775,0.00680412371 14.6881833,0.00618556701 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
