export const BASE_API_URL =
  process.env.NEXT_PUBLIC_API_URL ||
  "https://sites-api.dev.checkd-media.com/dev/";

export const BASE_ODDS_PLATFORM_API_URL =
  process.env.NEXT_PUBLIC_ODDS_PLATFORM_API_URL ||
  "https://odds-api.dev.checkd-dev.com/dev/";

export const DEFAULT_REVALIDATE = 120 as const;

// Odds Platform error codes:
export const ODDS_PLATFORM_SUCCESSFUL_RESPONSE = 0;
export const ODDS_PLATFORM_GENERAL_ERROR_CODE = 1;
export const ODDS_PLATFORM_NOT_FOUND_ERROR_CODE = 2;
export const ODDS_PLATFORM_VALIDATION_ERROR_CODE = 3;
export const ODDS_PLATFORM_MISSING_DATA_ERROR_CODE = 4;
export const ODDS_PLATFORM_UNUSED_ERROR_CODE = 5;
export const ODDS_PLATFORM_UNAUTHORISED_ERROR_CODE = 6;
export const ODDS_PLATFORM_UPDATED_REQUIRED_ERROR_CODE = 7;
export const ODDS_PLATFORM_BOOKMAKER_API_ERROR_CODE = 8;

export const ODDS_PLATFORM_ERROR_CODE_MAP = new Map<number, string>([
  [ODDS_PLATFORM_SUCCESSFUL_RESPONSE, "Success"],
  [ODDS_PLATFORM_GENERAL_ERROR_CODE, "General Error"],
  [ODDS_PLATFORM_NOT_FOUND_ERROR_CODE, "Not Found"],
  [ODDS_PLATFORM_VALIDATION_ERROR_CODE, "Validation Error"],
  [ODDS_PLATFORM_MISSING_DATA_ERROR_CODE, "Missing Data"],
  [ODDS_PLATFORM_UNUSED_ERROR_CODE, "Unused"],
  [ODDS_PLATFORM_UNAUTHORISED_ERROR_CODE, "Authorisation Error"],
  [ODDS_PLATFORM_UPDATED_REQUIRED_ERROR_CODE, "Update Required"],
  [ODDS_PLATFORM_BOOKMAKER_API_ERROR_CODE, "Bookmaker API Error"],
]);
