import { getDataFromPathAndQueryParams } from "@/lib/http/helpers";
import { FlashPicksConfigResponse } from "@/types/api/config";

export const getFlashPicksConfig = async (
  location: string
): Promise<FlashPicksConfigResponse> => {
  const path = "flashpicks/config";
  const queryParams = {
    location,
  };

  return await getDataFromPathAndQueryParams({
    path,
    queryParams,
    isOddsPlatformRequest: true,
  });
};
