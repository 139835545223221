const IS_PRODUCTION = process.env.NEXT_PUBLIC_VERCEL_ENV === "production";
const BASE_API_URL =
  process.env.NEXT_PUBLIC_API_URL ||
  "https://sites-api.dev.checkd-media.com/dev/";
const BETTING_HUB_API_URL = `https://api-bettinghub.checkd-dev.com/${
  IS_PRODUCTION ? "prod" : "dev"
}`;
const BASE_ODDS_PLATFORM_API_URL =
  process.env.NEXT_PUBLIC_ODDS_PLATFORM_API_URL ||
  "https://odds-api.dev.checkd-dev.com/dev/";
const DEFAULT_REVALIDATE = 120;
const CONTENT_LIMIT = 6;
const NEWS_CONTENT_LIMIT = 9;
const PREDICTIONS_CONTENT_LIMIT = 10;

export {
  IS_PRODUCTION,
  BASE_API_URL,
  DEFAULT_REVALIDATE,
  CONTENT_LIMIT,
  BASE_ODDS_PLATFORM_API_URL,
  BETTING_HUB_API_URL,
  NEWS_CONTENT_LIMIT,
  PREDICTIONS_CONTENT_LIMIT,
};
