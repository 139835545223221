import {
  AMERICAN_ODDS_FORMAT,
  DECIMAL_ODDS_FORMAT,
  FRACTIONAL_ODDS_FORMAT,
} from "../../constants/odds";
import { FP_BRAND } from "../../constants";
import { getActiveBrand } from "../brand/brand";
import type { OddsFormats } from "../../types/odds";

export const getDefaultFormattedOdds = () => {
  const brand = getActiveBrand();
  return brand === FP_BRAND ? AMERICAN_ODDS_FORMAT : FRACTIONAL_ODDS_FORMAT;
};

export const getFormattedOdds = (
  // Some historic data doesn't have the odds object, only a single odds format
  allOdds:
    | {
        odds: string | undefined;
        oddsDecimal: string | number | undefined;
        oddsAmerican: string | undefined;
      }
    | string
    | number,
  oddsFormat: OddsFormats
) => {
  if (typeof allOdds === "string" || typeof allOdds === "number") {
    return allOdds;
  }

  const { odds, oddsDecimal, oddsAmerican } = allOdds;

  const MISSING_ODDS_TEXT = odds ?? "0";

  const oddsMap = new Map<OddsFormats, string | number>([
    [AMERICAN_ODDS_FORMAT, oddsAmerican ?? MISSING_ODDS_TEXT],
    [DECIMAL_ODDS_FORMAT, oddsDecimal ?? MISSING_ODDS_TEXT],
    [FRACTIONAL_ODDS_FORMAT, odds ?? MISSING_ODDS_TEXT],
  ]);

  return oddsMap.get(oddsFormat) as OddsFormats;
};
