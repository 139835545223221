import type { CdnAssetsBrands, MediaSize } from "../../types/media";

export const bannerLogoUrlFromName = (name = "") => {
  return `https://cdn.checkd-dev.com/bettinghub/bookies/web/${name
    .toLowerCase()
    .replace(/\s/g, "_")}.png`;
};

export function mediaUrlFromSlug(slug: string, size: MediaSize = "original") {
  if (!slug) throw new Error("Slug cannot be an empty string");
  if (slug.endsWith("-gif"))
    return `https://cdn.checkd.media/images/${slug}.gif`;
  return `https://cdn.checkd.media/images/${slug}-${size}.jpg`;
}

export function formatIdToTeamBadgeImageUrl(id: string) {
  return `https://cdn.checkd-dev.com/football/teams/150x150/${id}.png`;
}

export function formatIdToCompetitionBadgeImageUrl(id: string) {
  return `https://cdn.fantasyiteam.com/bethub/competitions/150x150/${id}.png`;
}

export function formatCdnAssetsUrl(asset: string, brand: CdnAssetsBrands) {
  return `https://cdn.checkd-dev.com/${brand
    .replaceAll(" ", "")
    .toLowerCase()}/assets/${asset}.png`;
}
