import React, { ReactElement } from "react";
import Image from "next/image";
import { BannerData } from "@/types/api/adverts";
import styles from "@/components/Banner/Banner.module.scss";
import { mediaUrlFromSlug } from "@/lib/media";
import { InjectedHtmlContainer } from "@/components/InnerHtml/InjectedHtmlContainer";
import { getCurrentUnixTimestampMs } from "@/lib/date";

interface BannerProps {
  isMobileDevice: boolean;
  banner: BannerData;
}

export const Banner = ({
  isMobileDevice,
  banner,
}: BannerProps): ReactElement => {
  return (
    <>
      {isMobileDevice && banner.expires_at > getCurrentUnixTimestampMs() ? (
        <div className={styles.banner}>
          {banner.media_type === "image" && "slug" in banner.meta.source ? (
            <a href={banner.meta.source.link} target="_blank" rel="noreferrer">
              <Image
                src={mediaUrlFromSlug(banner.meta.source.slug)}
                alt={banner.meta.source.alt}
                fill
              />
            </a>
          ) : null}

          {banner.media_type === "code" && "code" in banner.meta.source ? (
            <InjectedHtmlContainer html={banner.meta.source.code} />
          ) : null}
        </div>
      ) : null}
    </>
  );
};
