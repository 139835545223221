import { GambleAwareContent, SportBrandShortNames } from "@/types/brand";
import { FooterDetails, FooterNavLinks } from "@/types/staticTranslations";
import {
  BRAND_DETAILS_MAP,
  FA_BRAND,
  FP_BRAND,
  OC_BRAND,
  TWE_BRAND,
} from "./brands";
import { BRAZIL_LOCALE } from "./countries";

const defaultUkGambleAwareContent = {
  ageRestriction: 18,
  gambleAware: [
    {
      id: "beGambleAwareLogo",
      title: "Be gamble aware campaign",
      siteLink: "https://www.begambleaware.org",
      defaultLogo: {
        src: "https://cdn.checkd.media/logos/gambleaware-helpline-logo-white.png",
        height: 98,
        width: 275,
      },
      logos: {
        light: {
          src: "https://cdn.checkd.media/logos/gambleaware-helpline-logo-blue.png",
          height: 98,
          width: 275,
        },
        dark: {
          src: "https://cdn.checkd.media/logos/gambleaware-helpline-logo-white.png",
          height: 98,
          width: 275,
        },
      },
      imageAltText: "BeGambleAware.org logo",
    },
    {
      id: "gamblingCare",
      title: "GamblingCare.ie",
      siteLink: "https://gamblingcare.ie/",
      imageAltText: "GamblingCare.ie logo",
      defaultLogo: {
        src: "https://cdn.checkd.media/logos/gamblingcare.png",
        height: 20,
        width: 154,
      },
      logos: {
        light: {
          src: "https://cdn.checkd.media/logos/gamblingcare-dark.png",
          height: 20,
          width: 154,
        },
        dark: {
          src: "https://cdn.checkd.media/logos/gamblingcare.png",
          height: 20,
          width: 154,
        },
      },
    },
  ],
};

type BrandConfig = {
  default: GambleAwareContent;
} & Partial<{ [locale: string]: GambleAwareContent }>;

export const BRAND_GAMBLEAWARE_CONTENT_MAP: Map<
  SportBrandShortNames,
  BrandConfig
> = new Map([
  [
    FA_BRAND,
    {
      [BRAZIL_LOCALE]: {
        gambleAware: [
          {
            id: "jogue-com-responsabilidade",
            title: "Jogue com Responsabilidade",
            siteLink: "",
            imageAltText: "Jogue com Responsabilidade logo",
            defaultLogo: {
              src: "https://cdn.checkd.media/logos/dark-jogue-com-responsabilidade.svg",
              height: 98,
              width: 275,
            },
            logos: {
              light: {
                src: "https://cdn.checkd.media/logos/dark-jogue-com-responsabilidade.svg",
                height: 98,
                width: 275,
              },
              dark: {
                src: "https://cdn.checkd.media/logos/light-jogue-com-responsabilidade.svg",
                height: 98,
                width: 275,
              },
            },
          },
        ],
      },
      default: defaultUkGambleAwareContent,
    },
  ],
  [
    FP_BRAND,
    {
      default: {
        ageRestriction: 21,
        gambleAware: [
          {
            id: "gamblingProblemHelplineLogo",
            title: "Gambling problem helpline",
            siteLink:
              "https://www.ncpgambling.org/help-treatment/national-helpline-1-800-522-4700",
            defaultLogo: {
              src: "https://cdn.checkd.media/logos/fp_1800_gambler.png",
              height: 98,
              width: 225,
            },
            imageAltText: "Gambling Problem Helpline Logo",
          },
        ],
      },
    },
  ],
  [
    TWE_BRAND,
    {
      default: defaultUkGambleAwareContent,
    },
  ],
  [
    OC_BRAND,
    {
      default: defaultUkGambleAwareContent,
    },
  ],
]);

const DEFAULT_FOOTER_NAV_LINKS: FooterNavLinks[] = [
  {
    link: "/about-us",
    text: "About",
  },
  {
    link: "/responsible-gambling",
    text: "Responsible Gambling",
  },
  {
    link: "/terms-conditions",
    text: "Ts & Cs",
  },
  {
    link: "/privacy-policy",
    text: "Privacy Policy",
  },
  {
    link: "/acceptable-use",
    text: "Acceptable Use",
  },
  {
    link: "/cookie-policy",
    text: "Cookie Policy",
  },
];

const FA_FOOTER_NAV_LINKS: FooterNavLinks[] = [
  ...DEFAULT_FOOTER_NAV_LINKS,
  {
    link: "/fantasy-football-hub",
    text: "Fantasy Hub",
  },
  {
    link: "/betting-hub",
    text: "Betting Hub",
  },
];

const TWE_FOOTER_NAV_LINKS: FooterNavLinks[] = [
  ...DEFAULT_FOOTER_NAV_LINKS,
  {
    link: "/twe-app",
    text: "TWE App",
  },
  {
    link: "/cheltenham-festival",
    text: "Cheltenham",
  },
];

const OC_FOOTER_NAV_LINKS: FooterNavLinks[] = [
  ...DEFAULT_FOOTER_NAV_LINKS,
  {
    link: "https://flashpicks.com/",
    text: "FlashPicks",
  },
];

export const FP_DEFAULT_FOOTER: FooterDetails = {
  timeToThinkBanner: false,
  description: [
    "Covering a wide range of US sports, we aim to be the ultimate sports betting website. Here you’ll find picks and analysis for the biggest sports across the USA.",
    "If you or someone you know has a gambling problem, crisis counseling and referral services can be accessed by calling 1-800-GAMBLER (1-800-426-2537).",
  ],
  disclaimer: {
    heading: "Disclaimer",
    content: [
      `${BRAND_DETAILS_MAP[FP_BRAND].fullName} is owned and operated by Checkd Media. Contact`,
      "for more information.",
    ],
    emailLink: `${BRAND_DETAILS_MAP[FP_BRAND].email}`,
  },
  footerNav: {
    links: DEFAULT_FOOTER_NAV_LINKS,
    copyright: `© 2012 - ${new Date().getFullYear()} Copyright ${
      BRAND_DETAILS_MAP[FP_BRAND].fullName
    }. All Rights reserved.`,
  },
  stateSelector: true,
  oddsFormatSelector: true,
  showTipLinks: true,
};

export const FA_DEFAULT_FOOTER: FooterDetails = {
  timeToThinkBanner: true,
  description: [
    "Facilitating the UK's largest online betting community, we provide our own tips on popular betting markets as well as offering the latest bets and offers.",
    "Gamble Aware aims to promote responsibility in gambling. They provide information to help you make informed decisions about your gambling.",
  ],
  disclaimer: {
    heading: "Disclaimer",
    content: [
      `${BRAND_DETAILS_MAP[FA_BRAND].fullName} is owned and operated by Checkd Media. Contact`,
      "for more information.",
    ],
    emailLink: `${BRAND_DETAILS_MAP[FA_BRAND].email}`,
  },
  footerNav: {
    links: FA_FOOTER_NAV_LINKS,
    copyright: `© 2012 - ${new Date().getFullYear()} Copyright ${
      BRAND_DETAILS_MAP[FA_BRAND].fullName
    }. All Rights reserved.`,
  },
  stateSelector: false,
  oddsFormatSelector: true,
  themeSwitcher: {
    heading: "Display Mode",
    light: "Light",
    dark: "Dark",
  },
  showTipLinks: true,
};

export const TWE_DEFAULT_FOOTER: FooterDetails = {
  timeToThinkBanner: true,
  description: [
    "Facilitating the UK's largest online betting community, we provide our own tips on popular betting markets as well as offering the latest bets and offers.",
    "Gamble Aware aims to promote responsibility in gambling. They provide information to help you make informed decisions about your gambling.",
  ],
  disclaimer: {
    heading: "Disclaimer",
    content: [
      `${BRAND_DETAILS_MAP[TWE_BRAND].fullName} is owned and operated by Checkd Media. Contact`,
      "for more information.",
    ],
    emailLink: `${BRAND_DETAILS_MAP[TWE_BRAND].email}`,
  },
  footerNav: {
    links: TWE_FOOTER_NAV_LINKS,
    copyright: `© 2012 - ${new Date().getFullYear()} Copyright ${
      BRAND_DETAILS_MAP[TWE_BRAND].fullName
    }. All Rights reserved.`,
  },
  stateSelector: false,
  oddsFormatSelector: true,
  showTipLinks: true,
};

export const OC_DEFAULT_FOOTER: FooterDetails = {
  timeToThinkBanner: true,
  description: [
    "Facilitating the UK's largest online betting community, we provide our own tips on popular betting markets as well as offering the latest bets and offers.",
    "Gamble Aware aims to promote responsibility in gambling. They provide information to help you make informed decisions about your gambling.",
  ],
  disclaimer: {
    heading: "Disclaimer",
    content: [
      `${BRAND_DETAILS_MAP[OC_BRAND].fullName} is owned and operated by Checkd Media. Contact`,
      "for more information.",
    ],
    emailLink: `${BRAND_DETAILS_MAP[OC_BRAND].email}`,
  },
  footerNav: {
    links: OC_FOOTER_NAV_LINKS,
    copyright: `© 2012 - ${new Date().getFullYear()} Copyright ${
      BRAND_DETAILS_MAP[OC_BRAND].fullName
    }. All Rights reserved.`,
  },
  stateSelector: false,
  oddsFormatSelector: true,
  showTipLinks: true,
};

export const FA_BR_FOOTER_CONFIG: FooterDetails = {
  timeToThinkBanner: false,
  description: [
    "Facilitando a melhor comunidade de apostas online do Brasil, fornecemos nossas próprias dicas sobre mercados de apostas populares, bem como as últimas apostas e ofertas.",
    "O Jogadores Anônimos tem como objetivo promover a responsabilidade no jogo. Eles fornecem informações para ajudá-lo a tomar decisões informadas sobre suas apostas.",
  ],
  footerNav: {
    links: [
      {
        link: "/br/sobre-nos",
        text: "Sobre nós",
      },
      {
        link: "/br/jogo-responsavel",
        text: "Jogo Responsável",
      },
    ],
    copyright: `© 2012 - ${new Date().getFullYear()} Copyright ${
      BRAND_DETAILS_MAP[FA_BRAND].fullName
    }. Todos os direitos reservados.`,
  },
  disclaimer: {
    heading: "Aviso legal",
    content: [
      `O Footy Accumulators é de propriedade e operado pela Checkd Media. Entre em contato com`,
      "para obter mais informações.",
    ],
    emailLink: `${BRAND_DETAILS_MAP[FA_BRAND].email}`,
  },
  stateSelector: false,
  oddsFormatSelector: false,
  themeSwitcher: {
    heading: "Modo de exibição",
    light: "Claro",
    dark: "Escuro",
  },
  showTipLinks: false,
};

type BrandFooterConfig = {
  default: FooterDetails;
} & Partial<{ [locale: string]: FooterDetails }>;

export const FOOTER_MAP: Map<SportBrandShortNames, BrandFooterConfig> = new Map(
  [
    [
      FA_BRAND,
      {
        [BRAZIL_LOCALE]: FA_BR_FOOTER_CONFIG,
        default: FA_DEFAULT_FOOTER,
      },
    ],
    [
      FP_BRAND,
      {
        default: FP_DEFAULT_FOOTER,
      },
    ],
    [
      TWE_BRAND,
      {
        default: TWE_DEFAULT_FOOTER,
      },
    ],
    [
      OC_BRAND,
      {
        default: OC_DEFAULT_FOOTER,
      },
    ],
  ]
);

/********************************
 * Offers
 ********************************/

type CategoryFormatNames = Record<string, string>;

type BrandOfferCategories = {
  default: CategoryFormatNames;
} & Partial<{ [locale: string]: CategoryFormatNames }>;

export const DEFAULT_OFFER_CATEGORY_HEADINGS: CategoryFormatNames = {
  freebets: "Free Bets",
  casino: "Casino",
  special: "Special",
};

export const FA_OFFER_CATEGORY_HEADINGS: BrandOfferCategories = {
  default: DEFAULT_OFFER_CATEGORY_HEADINGS,
  [BRAZIL_LOCALE]: {
    freebets: "Apostas grátis",
    casino: "Cassino",
    special: "Especial",
  },
};

export const BRAND_CATEGORY_HEADINGS_MAP = new Map([
  [FA_BRAND, FA_OFFER_CATEGORY_HEADINGS],
  [FP_BRAND, { default: DEFAULT_OFFER_CATEGORY_HEADINGS }],
  [TWE_BRAND, { default: DEFAULT_OFFER_CATEGORY_HEADINGS }],
  [OC_BRAND, { default: DEFAULT_OFFER_CATEGORY_HEADINGS }],
]);

export const PAGE_STRING_TRANSLATIONS = new Map<string, string>([
  [BRAZIL_LOCALE, "pagina"],
]);
