import type { TweetData } from "../../types/api/tweet";

export const getEmbeddedTweetHtml = async (
  url: string
): Promise<TweetData | null> => {
  // This is a fix for if a user uses an x.com embed instead of a twitter.com embed in the CMS
  const twitterOrXSiteLink = url.includes("twitter.com")
    ? "twitter.com"
    : "x.com";

  const res = await fetch(
    `https://publish.${twitterOrXSiteLink}/oembed?url=${url}`
  );
  if (res.status === 200) {
    return res.json() as Promise<TweetData>;
  }
  console.error({ status: res.status, reason: res.statusText });
  return null;
};
