import Image from "next/image";
import type { OfferData } from "../../../types/api/offer";
import { bannerLogoUrlFromName } from "../../../utils/media/media";
import { useLocaleFromRouter } from "../../../hooks/LocationFromRouter";
import {
  localisedOfferTitleAndTermsAndConditions,
  localisedOfferLink,
  gaOfferPressedEvent,
} from "../utils/offer";
import { useCheckMobileDevice } from "../../../hooks/Window";
import styles from "./OfferCard.module.scss";

export interface OfferCardProps {
  offer: OfferData;
  sidebar?: boolean;
}

export function OfferCard({ offer, sidebar }: OfferCardProps) {
  const [locale] = useLocaleFromRouter();
  const isMobileDevice = useCheckMobileDevice();
  const offerLink = localisedOfferLink(offer, locale, sidebar);
  const { title, terms_and_conditions: termsAndConditions } =
    localisedOfferTitleAndTermsAndConditions(offer, locale);
  const { cta } = offer.meta;

  return (
    <div
      className={styles.offerCardContainer}
      data-testid={`offer-${offer._id}`}
    >
      <div
        className={`${styles.offerHeader} ${
          sidebar ? styles[`offerHeader--sidebar`] : null
        }`}
        data-testid={`offerHeader-${offer._id}`}
      >
        <Image
          alt={`${offer.bookmaker.name} Logo`}
          height={48}
          src={bannerLogoUrlFromName(offer.bookmaker.name_cleansed)}
          width={48}
        />
        <h2 className={styles.offerHeading}>{title}</h2>
        {!sidebar && !isMobileDevice ? (
          <a
            className={styles.offerButton}
            href={offerLink}
            onClick={() => {
              gaOfferPressedEvent({ offer });
            }}
            style={{
              color: `rgba(${offer.bookmaker.branding.text.join(",")})`,
              backgroundColor: `rgba(${offer.bookmaker.branding.primary.join(
                ","
              )})`,
            }}
            target={offer.meta.target || "_blank"}
          >
            {cta ? cta : "Claim Now"}
          </a>
        ) : null}
      </div>
      <div
        className={`${styles.offerBody} ${
          sidebar ? styles[`offerBody--sidebar`] : null
        }`}
        data-testid={`offer-card-${offer._id}__body`}
      >
        <p
          className={`${styles.offerTerms} ${
            sidebar ? styles[`offerTerms--sidebar`] : null
          }`}
        >
          {termsAndConditions}
        </p>
        {sidebar || isMobileDevice ? (
          <a
            className={styles.offerButton}
            href={offerLink}
            onClick={() => {
              gaOfferPressedEvent({ offer, sidebar });
            }}
            style={{
              color: `rgba(${offer.bookmaker.branding.text.join(",")})`,
              backgroundColor: `rgba(${offer.bookmaker.branding.primary.join(
                ","
              )})`,
            }}
            target={offer.meta.target || "_blank"}
          >
            {cta ? cta : "Claim Now"}
          </a>
        ) : null}
      </div>
    </div>
  );
}
