import { ReactElement } from "react";

export const LinkedInLogo = (): ReactElement => (
  <svg
    width="33px"
    height="31px"
    viewBox="0 0 33 31"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>LinkedIn Logo</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Landing"
        transform="translate(-1636.000000, -6776.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="::-Footer" transform="translate(0.000000, 6534.000000)">
          <g id="::-Social" transform="translate(1560.000000, 100.000000)">
            <g id="Social-Logos" transform="translate(0.000000, 72.000000)">
              <g
                id="004-linkedin-letters"
                transform="translate(76.000000, 70.000000)"
              >
                <rect
                  id="Rectangle"
                  x="0.388947899"
                  y="9.77821429"
                  width="6.75610532"
                  height="20.2094286"
                ></rect>
                <path
                  d="M29.3175216,11.6242143 C27.898335,10.0810714 26.0212885,9.30935714 23.6875294,9.30935714 C22.8276706,9.30935714 22.0460459,9.41471429 21.343014,9.62557143 C20.6401972,9.83628571 20.0465927,10.1319286 19.5619137,10.5126429 C19.0775216,10.8933571 18.6917289,11.2467857 18.4053244,11.5729286 C18.1329748,11.8827143 17.8666487,12.2435714 17.6067765,12.6517857 L17.6067765,9.77807143 L10.8708213,9.77807143 L10.8914734,10.7570714 C10.9053132,11.4098571 10.9121255,13.4218571 10.9121255,16.7933571 C10.9121255,20.1651429 10.8985008,24.5631429 10.8711081,29.9877857 L17.6067765,29.9877857 L17.6067765,18.7101429 C17.6067765,18.0169286 17.6814252,17.4664286 17.83187,17.0582143 C18.1187048,16.3645 18.5516101,15.7837143 19.1320202,15.3148571 C19.7123585,14.8454286 20.4320986,14.6109286 21.2922443,14.6109286 C22.4656852,14.6109286 23.3292728,15.0153571 23.8821468,15.8242857 C24.4348056,16.6331429 24.7111709,17.7514286 24.7111709,19.179 L24.7111709,29.9871429 L31.4468392,29.9871429 L31.4468392,18.4045714 C31.4463373,15.4268571 30.7371384,13.1668571 29.3175216,11.6242143 Z"
                  id="Path"
                ></path>
                <path
                  d="M3.80794622,0.0505714286 C2.67523585,0.0505714286 1.75743641,0.3805 1.05433277,1.03971429 C0.35130084,1.69907143 0,2.5315 0,3.53778571 C0,4.53 0.341189916,5.35957143 1.02364146,6.02557143 C1.70587787,6.69164286 2.60682577,7.02485714 3.72598319,7.02485714 L3.76692885,7.02485714 C4.9135507,7.02485714 5.83837759,6.69192857 6.5411944,6.02557143 C7.2440112,5.35957143 7.58857143,4.53028571 7.57501849,3.53778571 C7.56139384,2.53157143 7.21310476,1.69907143 6.53086835,1.03971429 C5.84870364,0.380214286 4.94072829,0.0505714286 3.80794622,0.0505714286 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
