import Image from "next/image";
import type { MediaSize } from "../../../types/media";
import { mediaUrlFromSlug } from "../../../utils/media/media";
import { isValidOptionalString, isValidUrlOrPath } from "../utils/mediaUtils";
import { ConditionalWrapper } from "../../../components/ConditionalWrapper/ConditionalWrapper";
import styles from "./Media.module.scss";
import { MediaCaption } from "./MediaCaption";

export interface MediaProps {
  slug: string;
  imageSourceSize?: MediaSize;
  caption?: string | null;
  alt?: string | null;
  url?: string | null;
  target?: "_blank" | "_self" | null;
}

export function Media({
  slug,
  imageSourceSize = "lg",
  caption,
  alt,
  url,
  target,
}: MediaProps): React.JSX.Element {
  const mediaUrl = mediaUrlFromSlug(slug, imageSourceSize);

  const linkWrapper = (children: React.JSX.Element) => (
    <a
      className={styles.mediaLink}
      href={url ?? ""}
      rel="noreferrer"
      target={target === "_blank" ? "_blank" : "_self"}
    >
      {children}
    </a>
  );

  const mediaCaptionWrapper = (children: React.JSX.Element) => (
    <MediaCaption caption={caption ?? ""}>{children}</MediaCaption>
  );

  // Using a nested conditional wrapper as we can have both a condition link and caption wrapping the image
  return (
    <div className={styles.mediaContainer}>
      <ConditionalWrapper
        condition={isValidUrlOrPath(url ?? "")}
        wrapper={linkWrapper}
      >
        <ConditionalWrapper
          condition={isValidOptionalString(caption)}
          wrapper={mediaCaptionWrapper}
        >
          <Image
            alt={alt ?? ""}
            className={styles.mediaImage}
            height={585}
            src={mediaUrl}
            width={1020}
          />
        </ConditionalWrapper>
      </ConditionalWrapper>
    </div>
  );
}
