import React, { useEffect, useState } from "react";
import styles from "@/components/StateSelector/StateSelector.module.scss";
import { filterStatesByStatus, getStateFromRegion } from "@/lib/geo";
import DropdownIcon from "@/components/icons/DropdownIcon";
import { StateList } from "@/components/StateList/StateList";
import { getCookieValueByName } from "@/lib/cookies";
import { UsStates } from "@/types/api/config";
import { useLocaleFromRouter } from "@/hooks/LocationFromRouter";
import {
  LICENSED_STATE,
  SOON_TO_BE_LICENSED_STATE,
  states,
  UNLICENSED_STATE,
} from "@/constants/countries";
import { CloseIcon } from "@/components/icons/CloseIcon";
import { gaEvent } from "@/lib/gtag";

export interface StateSelectorProps {
  fpStates?: UsStates[];
  position: "header" | "footer";
}

export const StateSelector = ({ fpStates, position }: StateSelectorProps) => {
  const [showStateSelector, setShowStateSelector] = useState(false);
  const [selectedState, setSelectedState] = useState<string | undefined>(
    undefined
  );

  const [locale] = useLocaleFromRouter();

  const licensedStates = filterStatesByStatus(LICENSED_STATE, fpStates);
  const unlicensedStates = filterStatesByStatus(UNLICENSED_STATE, fpStates);
  const soonToBeLicensedStates = filterStatesByStatus(
    SOON_TO_BE_LICENSED_STATE,
    fpStates
  );

  useEffect(() => {
    const activeStateSelectorCookie =
      getCookieValueByName("state_selector_locale") ?? null;

    if (activeStateSelectorCookie) {
      setSelectedState(activeStateSelectorCookie);
    } else {
      setSelectedState(locale);
    }
  }, [locale]);

  // For US states we display the state name if supported, otherwise we display the region code
  const footerSelectedStateText =
    (position === "footer" && selectedState && states.get(selectedState)) ??
    selectedState?.toUpperCase();

  const selectedStateText =
    position === "header"
      ? getStateFromRegion(selectedState)
      : footerSelectedStateText;

  const selectState = (state: string) => {
    setSelectedState(state);
    gaEvent("state_selected", {
      state_selected: getStateFromRegion(state) || "",
    });
  };

  return (
    <div
      data-testid={`stateSelector--${position}`}
      className={`${styles["stateSelectorContainer"]} ${
        styles[`stateSelectorContainer--${position}`]
      }`}
    >
      {position === "footer" ? <h4>State Selector</h4> : null}
      <button
        className={`${styles["stateSelectorIcon"]} ${
          styles[`stateSelectorIcon--${position}`]
        }`}
        onClick={() => {
          setShowStateSelector(!showStateSelector);
          gaEvent("state_selector_opened", {
            location:
              position === "header" ? "Top Navigation" : "Bottom Navigation",
          });
        }}
      >
        <span>{selectedStateText}</span>
        <DropdownIcon />
      </button>
      {showStateSelector ? (
        <div
          className={`${styles["stateSelectorDropdown"]} ${
            styles[`stateSelectorDropdown--${position}`]
          }`}
        >
          <div className={styles.stateSelectorDropdownHeader}>
            <h2>Select state</h2>
            <button onClick={() => setShowStateSelector(!showStateSelector)}>
              <CloseIcon />
            </button>
          </div>
          <div
            className={`${styles["stateSelectorScrollContainer"]} ${
              styles[`stateSelectorScrollContainer--${position}`]
            }`}
          >
            <StateList
              headerText={"Licensed states"}
              selectedState={selectedState}
              stateList={licensedStates}
              handleClick={(state) => selectState(state)}
            />

            <StateList
              data-testid={"soonToBeLicensedStates"}
              headerText={"Soon-to-be-licensed"}
              selectedState={selectedState}
              stateList={soonToBeLicensedStates}
              handleClick={(state) => selectState(state)}
            />

            <StateList
              data-testid={"unlicensedStates"}
              headerText={"Unlicensed"}
              selectedState={selectedState}
              stateList={unlicensedStates}
              handleClick={(state) => selectState(state)}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
