import { neutrals } from "./crossBrand";
// Rather than trying to come up with color names we're just giving
// setting the key as the next character in the alphabet

export const faColors = {
  ...neutrals,
  faLightModeBackground: "#ebecf5",
  faYellow: "#EFE52E",
  smartAccaGreen: "#44DB5E",
  faTipBlue: "#0089FE",
  darkBlue: "#11171D",
  lightMidBlue: "#1d2a3a",
  midBlue: "#15202C",
  lightBlue: "#1E2A3A",
  pokerWhite: "#FAFAFA",
  pokerDark: "#191919",
  paleBlue: "#b6cbd8",
  lightendedPaleBlue: "#b6cbd833",
  lessDarkGrey: "#cdd5e1",
  lessDarkBlue: "#111a24",
  veryLightGrey: "#f4f5f7",
};
