import { isInternalLink } from "@/lib/string";
import { Entity } from "../../../types/draftManager";

export default function linkToHTML(
  entity: Entity,
  originalText: string
): JSX.Element {
  // Previously, '&' characters would get encoded twice for <a> tags in the Editor widget
  // We now replace '&amp;' with '&' before using this returned <a> tag with convertToHTML(),
  // avoiding the double encoding
  const formattedOriginalText = originalText.replaceAll("&amp;", "&");

  return isInternalLink(entity.data.url) ? (
    <a href={entity.data.url}>{formattedOriginalText}</a>
  ) : (
    <a href={entity.data.url} target="_blank" rel="noreferrer">
      {formattedOriginalText}
    </a>
  );
}
