import { ReactElement } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import styles from "@/components/StateList/StateList.module.scss";
import { UsStates } from "@/types/api/config";

interface StateListProps {
  headerText: string;
  selectedState?: string;
  stateList: UsStates[];
  handleClick: (stateId: string) => void;
}

export const StateList = ({
  headerText,
  selectedState,
  stateList,
  handleClick,
}: StateListProps): ReactElement => {
  const router = useRouter();
  // For testing, all the states within stateList passed will have the same status
  const stateStatus =
    stateList.length > 0 ? `${stateList[0].status} states` : undefined;

  const setSelectedStateCookieAndRefresh = (stateId: string) => {
    // Give the cookie a path value to prevent unexpected errors from duplicate cookies
    document.cookie = `state_selector_locale=${stateId}; Max-Age=31536000; Path=/; Secure`;
    handleClick(stateId);
    router.reload();
  };

  return (
    <div data-testid={stateStatus}>
      <h3>{headerText}</h3>
      <ul className={styles.stateList}>
        {stateList.map((state) => {
          const activeState = selectedState
            ? selectedState.toUpperCase() === state.id
            : null;

          return (
            <li
              data-testid={activeState ? "activeState" : undefined}
              key={state.id}
              className={activeState ? styles.activeState : undefined}
              onClick={() => setSelectedStateCookieAndRefresh(state.id)}
            >
              {state.name}
              {activeState ? (
                <Image
                  src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTNweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTMgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+SWNvbi9TZWxlY3RlZDwvdGl0bGU+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0ic3RhdGVfc2VsZWN0b3JfZGVza3RvcF9kcm9wZG93biIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEzODYuMDAwMDAwLCAtMTY2LjAwMDAwMCkiIGZpbGw9IiM0NERCNUUiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxnIGlkPSJHcm91cC00IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMzE3LjAwMDAwMCwgOTYuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iSWNvbi9TZWxlY3RlZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjkuOTk5OTc1LCA3MC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNNi4wMDAwMzcyLDAgQzkuMzEzNzMwMDIsMCAxMi4wMDAwMjQ4LDIuNjg2MzE5MyAxMi4wMDAwMjQ4LDYuMDAwMDEyMjMgQzEyLjAwMDAyNDgsOS4zMTM3MDUxNyA5LjMxMzczMDAyLDEyIDYuMDAwMDM3MiwxMiBDMi43NjE2NTU1OCwxMiAwLjEyMjQ1NDI1LDkuNDM0NDIxOTMgMC4wMDQxNjMyNzEsNi4yMjQ5NDk0NyBMMi40NzU2MjA0OGUtMDUsNi4wMDAwMTIyMyBDLTAuMDA5Mzc5MjcyMDEsMi42OTU3MTU1OSAyLjY2MTYxMzg3LDAuMDA5NDUzMzg1MjUgNS45NjU4Nzc3NywwIEw2LjAwMDAzNzIsMCBaIE03Ljk3Njk2NzY1LDQgTDQuNTEyODUxNDQsNy4xNTM2MTMyNyBMMy4wMjMxMTYyMSw1LjgxMzczNSBMMi4wMDAwMjQ3Niw2LjcyODc2NTQzIEw0LjUxMjg1MTQ0LDkgTDkuMDAwMDI0NzYsNC45MTUwMzA0MyBMNy45NzY5Njc2NSw0IFoiIGlkPSJDb21iaW5lZC1TaGFwZSI+PC9wYXRoPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4="
                  height={12}
                  width={12}
                  alt="Selected state icon"
                  title="Selected state icon"
                />
              ) : null}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
