import { nanoid } from "nanoid";
import type { ContentDataTableWidgetRow } from "../../../types/api/content";
import styles from "./DataTable.module.scss";

export interface DataTableProps {
  data: ContentDataTableWidgetRow[];
}

export function DataTable({ data }: DataTableProps): React.JSX.Element {
  const headers = Object.keys(data[0]);
  const content = data.map((row) => Object.values(row));

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            {headers.map((header) => {
              return (
                <th className={styles.tableHeader} key={header}>
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {content.map((row) => (
            <tr className={styles.tableRow} key={nanoid()}>
              {row.map((value) => (
                <td className={styles.tableCell} key={value}>
                  {value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
