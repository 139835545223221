import { getCookieValueByName } from "./cookies";

// function to get the colour mode before the page is loaded
export function getDefaultColourMode() {
  // define the supported colour modes
  const supportedColourModes = ["light", "dark"];
  // get the colour mode from the cookie
  // this is used in a useEffect so document is always available
  // at this point
  const colourMode = getCookieValueByName("colourMode") || "";

  // check if the colour mode is valid, by checking if it is a string and that it matches
  // one of the supported colour modes
  const hasPersistedTheme =
    colourMode &&
    typeof colourMode === "string" &&
    supportedColourModes.includes(colourMode);

  if (hasPersistedTheme) {
    return colourMode;
  }

  return "light";
}
