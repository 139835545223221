import {
  DEFAULT_LOCALE,
  LOCALES_THAT_SUPPORT_API_BETTING,
} from "../../constants/countries";
import { getActiveBrand } from "../brand/brand";
import { BRANDS_THAT_SUPPORT_API_BETTING } from "../../constants";
import { bookiesThatSupportFastBet } from "../../constants/bet";
import type { PortalBookmakers } from "../../types/betslip";

export const isSupportedAPIBookmaker = (bookmaker: string): boolean => {
  return bookiesThatSupportFastBet.includes(bookmaker as PortalBookmakers);
};

export const doesBookmakerSupportAPIBetting = (
  bookmaker: string,
  locale: string = DEFAULT_LOCALE
): boolean => {
  const brand = getActiveBrand();

  if (
    !BRANDS_THAT_SUPPORT_API_BETTING.includes(brand) ||
    !LOCALES_THAT_SUPPORT_API_BETTING.includes(locale.toLowerCase())
  ) {
    return false;
  }
  return isSupportedAPIBookmaker(bookmaker);
};
