import { DeviceQueries, DeviceSizes } from "@/types/devices";

export const sizes: DeviceSizes = {
  mobile: 425,
  tablet: 768,
  laptop: 1024,
};

export const devices: DeviceQueries = {
  mobile: `(min-width: ${sizes.mobile}px)`,
  tablet: `(min-width: ${sizes.tablet}px)`,
  laptop: `(min-width: ${sizes.laptop}px)`,
};
