import { BRAND_SHORT_NAMES } from "../../constants";
import type { BrandShortNames } from "../../types/brand";

export const isValidBrand = (param: string): param is BrandShortNames =>
  BRAND_SHORT_NAMES.includes(param as BrandShortNames);

export const getActiveBrand = (): BrandShortNames => {
  const brand = process.env.NEXT_PUBLIC_BRAND;
  if (!brand) throw new Error("No brand env var has been set");
  const validBrand = isValidBrand(brand);
  if (!validBrand) throw new Error(`${brand} is not a valid brands`);
  return brand;
};
