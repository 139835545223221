import { ReactElement } from "react";
import Image from "next/image";
import {
  AgeLimitWrapper,
  DisclaimerWrapper,
  LegalitiesWrapper,
  Ppc1FooterLink,
  Ppc1FooterLinks,
  Ppc1FooterUl,
  PpcFooterStyles,
} from "@/components/footer/Ppc1Footer/Ppc1Footer.style";

export const Ppc1Footer = (): ReactElement => (
  <PpcFooterStyles>
    <a
      target="_blank"
      href="https://800gambler.org/"
      rel="noreferrer"
      title="800Gambler - Helpline: Home page"
    >
      <DisclaimerWrapper>
        <div>
          <p>
            If you or someone you know has a gambling problem, crisis counseling
            and referral services can be accessed by calling 1-800-GAMBLER
            (1-800-426-2537).
          </p>
          <p>
            We are committed to responsible gambling, you must be 21+ to use
            this site. Please bet responsibly.
          </p>
        </div>
        <LegalitiesWrapper>
          <AgeLimitWrapper>
            21
            <span>+</span>
          </AgeLimitWrapper>
          <Image
            width={1527}
            height={334}
            alt="800Gambler - Helpline: 1-800 GAMBLER"
            src="https://cdn.checkd.media/logos/800-Gambler-white.png"
            data-testid="800GamblerLogo"
          />
        </LegalitiesWrapper>
      </DisclaimerWrapper>
    </a>
    <Ppc1FooterLinks>
      <div>
        <Ppc1FooterUl>
          <li>
            <Ppc1FooterLink href="/privacy-policy">
              Privacy Policy
            </Ppc1FooterLink>
          </li>
          <li>
            <Ppc1FooterLink href="/cookie-policy">Cookie Policy</Ppc1FooterLink>
          </li>
        </Ppc1FooterUl>
      </div>
      <div>
        &copy; 2012-{new Date().getFullYear()} Copyright Footy Accumulators. All
        Rights reserved.
      </div>
    </Ppc1FooterLinks>
  </PpcFooterStyles>
);
