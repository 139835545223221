interface ConditionalWrapperProps {
  wrapper: React.FC<React.JSX.Element>;
  condition: boolean;
  children: React.JSX.Element;
}

export const ConditionalWrapper = ({
  children,
  condition,
  wrapper,
}: ConditionalWrapperProps) => {
  return condition ? wrapper(children) : children;
};
