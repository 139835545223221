import { BrandShortNames } from "@/types/brand";
import {
  CM_BRAND,
  FA_BRAND,
  FP_BRAND,
  OC_BRAND,
  PPC_BRAND,
  TWE_BRAND,
} from "@/constants/brands";
import { SitemapItem } from "@/types/api/sitemap";
import { getActiveBrand } from "./brand";

export const getSiteUrl = (): string => {
  const brand = process.env.NEXT_PUBLIC_BRAND as BrandShortNames;

  const brandMap = new Map<BrandShortNames, string>([
    [FA_BRAND, "https://footyaccumulators.com"],
    [TWE_BRAND, "https://thewinnersenclosure.com"],
    [FP_BRAND, "https://flashpicks.com"],
    [OC_BRAND, "https://oddschanger.com"],
    [PPC_BRAND, "https://thebonuslounge.com"],
    [CM_BRAND, "https://checkd.group"],
  ]);

  const siteUrl = brandMap.get(brand);

  if (!siteUrl) {
    throw new Error(`Brand ${brand} not found`);
  }

  return siteUrl;
};

export function groupPathsByParent(
  paths: SitemapItem[]
): Record<string, SitemapItem[]> {
  const groupedPaths: Record<string, SitemapItem[]> = {};

  for (const path of paths) {
    const parent = path.parent || null;
    if (parent && groupedPaths[parent]) {
      groupedPaths[parent].push(path);
    } else if (parent) {
      groupedPaths[parent] = [path];
    }
  }

  return groupedPaths;
}

// This will need to be expanded on as we add more brands
export const parentPathsByBrand = new Map<BrandShortNames, string[]>([
  [FA_BRAND, ["predictions", "news"]],
]);

export function getParentContent(
  paths: SitemapItem[],
  parentId: SitemapItem["parent"]
): SitemapItem | null {
  return paths.find((path) => path._id === parentId) || null;
}

export function shouldChildBeTreatedAsParent(
  sitemapItem: SitemapItem
): boolean {
  const brand = getActiveBrand();
  if (!sitemapItem.parent) return true;
  const supportedParentPaths = parentPathsByBrand.get(brand) || [];
  const splitPath = sitemapItem.full_url_path.split("/");
  // we don't want to treat a path with more than 2 levels of nesting as a parent
  return (
    sitemapItem.type === "page" &&
    splitPath.length === 2 &&
    supportedParentPaths.includes(splitPath[0])
  );
}

export function getParentPaths(paths: SitemapItem[]): SitemapItem[] {
  return paths.filter((sitemapItem) => {
    const parentId = sitemapItem.parent || null;
    // if parent is null, this should be returned as a parent path
    if (!parentId) return true;
    // if path has a parent but does not include a supported parent path, then it should not be treated as a parent
    if (!shouldChildBeTreatedAsParent(sitemapItem)) return false;
    const parentContent = getParentContent(paths, parentId);
    if (!parentContent) return false;
    // check if sitemapItem full_url_path starts with parentContent full_url_path
    return sitemapItem.full_url_path.startsWith(parentContent.full_url_path);
  });
}
