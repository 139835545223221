import { useEffect, useRef, useState } from "react";
import { NavItem } from "@/types/api/nav";
import MenuList from "@/components/nav/MenuList/MenuList";
import styles from "@/components/nav/MobileNav/MobileNav.module.scss";
import MenuIcon from "@/components/icons/MenuIcon";
import BackArrowIcon from "@/components/icons/BackArrowIcon";
import themeSelector from "@/brands/theme";
import { getActiveBrand } from "@/lib/brand";
import { useDetectOutsideClick } from "@/hooks/DetectOutsideClick";
import { UsStates } from "@/types/api/config";
import NavIcons from "../NavIcons/NavIcons";

interface Props {
  navItems: NavItem[];
  fpStates?: UsStates[];
  region: string | null;
}

const brand = getActiveBrand();
const iconStyle = themeSelector(brand);

const MobileNav = ({
  navItems,
  fpStates,
  region,
}: Props): JSX.Element | null => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const moreMenuRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isOpen]);

  const closeMenu = (): void => {
    setIsOpen(false);
  };

  const menuListRef = useDetectOutsideClick(closeMenu, moreMenuRef);

  return (
    <div className={styles.mobileNavContainer}>
      <NavIcons fpStates={fpStates} region={region} />
      <button
        className={`${styles.mobileNavBtn}${
          isOpen ? ` ${styles.mobileNavBtnOpen}` : ""
        }`}
        ref={moreMenuRef}
        aria-label="Main Menu"
        aria-expanded={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? (
          <BackArrowIcon styleColor={iconStyle?.mobileNav?.menuIconFill} />
        ) : (
          <MenuIcon styleColor={iconStyle?.mobileNav?.menuIconFill} />
        )}
      </button>
      <div
        className={`${styles.mobileMenuContainer}${
          isOpen ? ` ${styles.mobileMenuContainerOpen}` : ""
        }`}
        data-testid="mobileMenuContainer"
      >
        <h2 className={styles.menuHeading}>Main Menu</h2>
        <MenuList
          navItems={navItems}
          closeMenu={closeMenu}
          ref={menuListRef as React.RefObject<HTMLUListElement>}
          region={region}
        />
      </div>
    </div>
  );
};

export default MobileNav;
