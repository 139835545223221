import { BrandShortNames } from "@/types/brand";
import { FA_BRAND, FP_BRAND, TWE_BRAND } from "./brands";
import { BRAZIL_LOCALE } from "./countries";

export const LOCALES_THAT_REQUIRE_LOCALISED_CONTENT = [BRAZIL_LOCALE];

export const BRANDS_SUPPORTED_LOCALISED_CONTENT_REGIONS: Map<
  BrandShortNames,
  string[]
> = new Map([
  [FA_BRAND, [BRAZIL_LOCALE]],
  [TWE_BRAND, []],
  [FP_BRAND, []],
]);

export const FA_FREEBETS_ROUTE_BY_REGION: Map<string, string> = new Map([
  [BRAZIL_LOCALE, "/br/apostas-gratis"],
]);
