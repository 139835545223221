import { useEffect, useState } from "react";
import App from "next/app";
import Head from "next/head";
import Script from "next/script";
import type { AppProps, AppContext } from "next/app";
import { nanoid } from "nanoid";
import { Inter, Montserrat } from "@next/font/google";
import { ThemeProvider } from "styled-components";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { useCheckMobileDevice } from "@/hooks/Window";
import Header from "@/components/Header/Header";
import { FooterRenderer } from "@/components/footer/FooterRenderer/FooterRenderer";
import { BetSlip } from "@/components/BetSlip/BetSlip";
import {
  checkIsSportsBlogBrandWithTips,
  getActiveBrand,
  getBrandTheme,
} from "@/lib/brand";
import { getNavForBrand } from "@/lib/http/nav";
import { getLatestTipsLink } from "@/lib/http/content";
import { NavPayload } from "@/types/api/nav";
import { ChildPageContent, ContentTipLink } from "@/types/api/content";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDarkMode } from "@/hooks/useDarkMode";
import BetSlipProvider from "@/contexts/betSlipContext";
import {
  BRAND_SHORT_NAMES_WITH_BETSLIP,
  FA_BRAND,
  FP_BRAND,
} from "@/constants/brands";
import "@/styles/app.scss";
import { AnyPageType } from "@/types/page";
import {
  getActiveAdvertsByType,
  getAllActiveAdverts,
} from "@/lib/http/adverts";
import { Banner } from "@/components/Banner/Banner";
import { AdvertData } from "@/types/api/adverts";
import { addLeadingSlash } from "@/lib/string";
import { BrandThemeNames } from "@/types/brand";
import { getBookmakerData } from "@/lib/http/bookmakers";
import { BookmakerPayload } from "@/types/api/bookmaker";
import themeSelector from "@/brands/theme";
import { getFlashPicksConfig } from "@/lib/http/config";
import { FlashPicksConfigResponse } from "@/types/api/config";
import { getLocalisedContentRegionByBrand } from "@/lib/content";

const inter = Inter({ subsets: ["latin"], display: "swap" });
const montserrat = Montserrat({ subsets: ["latin"], display: "swap" });

config.autoAddCss = false;

const BRAND = getActiveBrand();
const SUB_BRAND = process.env.NEXT_PUBLIC_SUB_BRAND;
const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;

if (process.env.NEXT_PUBLIC_API_MOCKING === "enabled") {
  require("../mocks");
}

interface AugmentedAppProps extends AppProps {
  nav: NavPayload;
  freeBetsCount?: number;
  footerTipLinks?: ContentTipLink[];
  region: string | null;
  pageProps: AnyPageType;
  advertsData: AdvertData;
  theme: BrandThemeNames;
  bookmakers: BookmakerPayload[];
  fpStates?: FlashPicksConfigResponse["usStates"];
}

const getNav = (
  nav: NavPayload,
  childPages: ChildPageContent[] | undefined
): NavPayload => {
  const newNav = { ...nav };
  if (childPages && childPages.length > 0) {
    newNav.meta.menu["sub-header"] = childPages.map(
      ({ full_url_path, title, short_title }) => ({
        id: nanoid(),
        title: short_title || title,
        url: full_url_path ? addLeadingSlash(full_url_path) : "/",
      })
    );
  }
  return newNav;
};

function MyApp({
  Component,
  pageProps,
  nav,
  region,
  footerTipLinks,
  advertsData,
  bookmakers,
  fpStates,
}: AugmentedAppProps) {
  const [brand, setBrand] = useState<string>(BRAND);
  const isMobileDevice = useCheckMobileDevice();
  const betSlipEnabled = BRAND_SHORT_NAMES_WITH_BETSLIP.includes(BRAND);
  const { childPages } = pageProps;
  const updatedNav = getNav(nav, childPages);

  const [colourMode, toggleColourMode] = useDarkMode();
  useEffect(() => {
    if (BRAND !== FA_BRAND) return;
    const preferredTheme = document.body.getAttribute("data-theme") || BRAND;
    if (!preferredTheme) return;
    setBrand(preferredTheme || BRAND);
  }, []);

  useEffect(() => {
    if (!colourMode || BRAND !== FA_BRAND) return;
    const brandTheme = getBrandTheme(colourMode);
    setBrand(brandTheme);
    // update data-theme attribute on body
    document.body.setAttribute("data-theme", brandTheme);
  }, [colourMode]);

  function handleThemeChange(evt: React.ChangeEvent<HTMLInputElement>) {
    toggleColourMode(evt);
  }

  return (
    <>
      <Head>
        <link
          rel="icon"
          href={`https://cdn.checkd.media/favicons/${brand}.ico`}
        />
        <meta name="format-detection" content="telephone=no" />
      </Head>
      <style jsx global>
        {`
          :root {
            --font-inter: ${inter.style.fontFamily};
            --font-montserrat: ${montserrat.style.fontFamily};
          }
        `}
      </style>
      {GA_MEASUREMENT_ID && [FA_BRAND, FP_BRAND].includes(brand) ? (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
          />
          <Script id="google-analytics">
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}');`}
          </Script>
        </>
      ) : null}

      {brand === FA_BRAND ? (
        <Script id="google-tag-manager">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-MXBXMHF');`}
        </Script>
      ) : null}

      {brand === FP_BRAND ? (
        <Script id="google-tag-manager">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WK22FN8');`}
        </Script>
      ) : null}

      <div>
        <div id="portal"></div>
        <ThemeProvider theme={themeSelector(brand, SUB_BRAND)}>
          <BetSlipProvider>
            {betSlipEnabled && <BetSlip bookmakers={bookmakers} />}
            <Header
              nav={{ ...updatedNav }}
              region={region}
              isMobileDevice={isMobileDevice}
              fpStates={fpStates}
            />
            <Component
              {...pageProps}
              region={region}
              advertsData={advertsData}
              isMobileDevice={isMobileDevice}
            />
            <FooterRenderer
              brand={BRAND}
              footerTipLinks={footerTipLinks}
              colourMode={brand === "fa-dark" ? "dark" : "light"}
              handleThemeChange={handleThemeChange}
              fpStates={fpStates}
              region={region}
            />
            {pageProps?.page?.meta.adverts && advertsData?.banner ? (
              <Banner
                isMobileDevice={isMobileDevice}
                banner={advertsData.banner[0]}
              />
            ) : null}
          </BetSlipProvider>
        </ThemeProvider>
      </div>
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  try {
    // calls page's `getInitialProps` and fills `appProps.pageProps`
    const appProps = await App.getInitialProps(appContext);
    const { router } = appContext;
    const { asPath } = router;
    const supportedRegionOnBrand = getLocalisedContentRegionByBrand(
      BRAND,
      asPath
    );
    const { data: nav } = await getNavForBrand(supportedRegionOnBrand);

    // Get active banner data
    // This is done inside _app.tsx because we want the banner to render past the footer
    const { locale } = appContext.router.query;

    // Set all active adverts
    let advertsData;
    let fpStates;
    // if !supportedRegionOnBrand, this means that the user isn't viewing a route
    // that has localised content (e.g. /br)
    if (typeof locale === "string" && !supportedRegionOnBrand) {
      advertsData = await getAllActiveAdverts(
        appContext.router.pathname,
        locale
      );

      if (BRAND === FP_BRAND) {
        const fpConfig = await getFlashPicksConfig(locale);
        fpStates = fpConfig["usStates"];
      }
    }

    // if supportedRegionOnBrand, this means that the user is viewing a route
    // that has localised content (e.g. /br) and they should see the adverts for that region
    // we only want to do this if the user is on the homepage of the localised content
    if (supportedRegionOnBrand && asPath === `/${supportedRegionOnBrand}`) {
      advertsData = {
        interstitials:
          (await getActiveAdvertsByType("interstitial", supportedRegionOnBrand))
            ?.data ?? [],
      };
    }

    const isSportsBlogWithTips = checkIsSportsBlogBrandWithTips(BRAND);
    if (!isSportsBlogWithTips) {
      return { ...appProps, nav, advertsData, fpStates };
    }

    const { data: footerTipLinks } = await getLatestTipsLink();
    const { data: bookmakers } = await getBookmakerData(true);

    return {
      ...appProps,
      nav,
      region: supportedRegionOnBrand,
      footerTipLinks,
      advertsData,
      bookmakers,
      fpStates,
    };
  } catch (error) {
    console.error(error);

    return { props: { errorCode: 500, error } };
  }
};

export default MyApp;
