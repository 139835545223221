import { CardWrapper } from "../../CardWrapper";
import type {
  BookmakerForTip,
  StatsTipsterSelection,
  TipData,
} from "../../../types/api/tipster";
import type { OddsFormats } from "../../../types/odds";
import {
  gaOfferPressedEvent,
  statsTipsterGaEventParamsFromTip,
} from "../utils/tipsterUtils";
import { StatsTip } from "./StatsTip";
import { TipBookmakers } from "./TipBookmakers";

export interface StatsTipsterProps {
  tip: TipData;
  bookmakers: BookmakerForTip[];
  oddsFormat: OddsFormats;
}

export function StatsTipster({
  tip,
  bookmakers,
  oddsFormat,
}: StatsTipsterProps): React.JSX.Element | null {
  const { meta, outcomes } = tip;

  if (!outcomes) return null;

  const { grid, title: tipsterTitle } = meta;
  const { odds } = outcomes;

  const selections: StatsTipsterSelection[] = grid.map(
    (match) => match.selection
  ) as StatsTipsterSelection[];

  const { bookmakerReason } = meta;

  const handleOfferClick = (bookmaker?: string) => {
    const { competition, event, tipster, bettingTip } =
      statsTipsterGaEventParamsFromTip({
        events: outcomes.events,
        title: meta.title,
      });

    gaOfferPressedEvent({
      bookmaker,
      competition,
      event,
      tipster,
      bettingTip,
    });
  };

  return (
    <CardWrapper
      competitionHeader
      hideHeader={!tipsterTitle}
      listHeader={tipsterTitle}
    >
      <>
        {grid.map((match, i) => {
          return (
            <StatsTip
              key={match.match?.id}
              matchDetails={match}
              statsForGame={outcomes.events[i]}
            />
          );
        })}
        <TipBookmakers
          bookmakerReason={bookmakerReason}
          bookmakers={bookmakers}
          odds={odds}
          oddsFormat={oddsFormat}
          onClick={handleOfferClick}
          selections={selections}
        />
      </>
    </CardWrapper>
  );
}
