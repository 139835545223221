import type { OfferSidebarResponse } from "../../types/api/offerSidebar";
import { DEFAULT_LOCALE } from "../../constants/countries";
import { getDataFromPathAndQueryParams } from "./helpers/helpers";

export const getOffersForSidebar = async (
  locale: string = DEFAULT_LOCALE
): Promise<OfferSidebarResponse> => {
  const path = "offers/sidebar";
  const queryParams = { locale };

  return getDataFromPathAndQueryParams({ path, queryParams });
};
