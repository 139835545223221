import { AUTHOR_PAGE_SUPPORTED_BRANDS } from "@/constants/pages/authors";
import { BrandShortNames } from "@/types/brand";
import { AuthorPageSupportedBrands } from "@/types/pages/author";
import { AuthorData } from "@/types/api/authors";

export function isSupportedBrandForAuthorPage(
  brand: BrandShortNames
): brand is AuthorPageSupportedBrands {
  return AUTHOR_PAGE_SUPPORTED_BRANDS.includes(
    brand as AuthorPageSupportedBrands
  );
}

export const getAuthorInfoFromData = (
  authorData?: AuthorData[],
  authorId?: string | null
) => authorData?.find((authorData) => authorData._id === authorId);

export const getAuthorFullNameFromAuthorInfo = (authorInfo?: AuthorData) =>
  authorInfo ? `${authorInfo.first_name} ${authorInfo.surname}` : undefined;

export const getAuthorInfoAndFullName = (
  authorData?: AuthorData[],
  authorId?: string | null
) => {
  const authorInfo = getAuthorInfoFromData(authorData, authorId);
  const authorFullName = getAuthorFullNameFromAuthorInfo(authorInfo);

  return {
    authorInfo,
    authorFullName,
  };
};
