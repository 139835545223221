import {
  BetSlipLabels,
  CtaButton,
  ExternalBrandColours,
  NeutralTheme,
  Notifications,
  Results,
  CardWrapperHighlight,
} from "@/types/brand";
// These are the neutral colours that can be used across the Checkd brands
// If we are unsure about what to use please ask the design team for advice
interface NeutralColours {
  pures: NeutralTheme;
  cools: NeutralTheme;
  pureWhite: string;
  pureWhiteGradients: NeutralTheme;
  pureBlack: string;
  pureBlackGradients: NeutralTheme;
  externalBrands: ExternalBrandColours;
  results: Results;
  notifications: Notifications;
  betSlipLabels: BetSlipLabels;
  ctaButton: CtaButton;
  cardWrapperHighlight: CardWrapperHighlight;
}

// Pures are to be used as the primary colours (backgrounds, stand out text like headers etc...)
const pures: NeutralTheme = {
  100: "#F0F0F0",
  200: "#D4D4D4",
  300: "#B8B8B8",
  400: "#9C9C9C",
  500: "#7F7F7F",
  600: "#636363",
  700: "#474747",
  800: "#2B2B2B",
  900: "#0F0F0F",
};
// Cools are to be used as secondary colours (paragraph text, inactive states for component background etc...)
const cools: NeutralTheme = {
  100: "#F2F3F4",
  200: "#DADEDF",
  300: "#C1C7C9",
  400: "#A7AFB2",
  500: "#8C979A",
  600: "#6F7C80",
  700: "#555F61",
  800: "#373D3F",
  900: "#131516",
};
const pureWhite = "#FFF";
const pureWhiteGradients: NeutralTheme = {
  100: "#FFFFFF19",
  200: "#FFFFFF33",
  300: "#FFFFFF4C",
  400: "#FFFFFF66",
  500: "#FFFFFF80",
  600: "#FFFFFF99",
  700: "#FFFFFFB3",
  800: "#FFFFFFCC",
  900: "#FFFFFFE6",
};
const pureBlack = "#000";
const pureBlackGradients: NeutralTheme = {
  100: "#00000019",
  200: "#00000033",
  300: "#0000004C",
  400: "#00000066",
  500: "#00000080",
  600: "#00000099",
  700: "#000000B3",
  800: "#000000CC",
  900: "#000000E6",
};
const externalBrands: ExternalBrandColours = {
  timeToThink: "#FAFE0A",
};

const results: Results = {
  win: {
    solid: "#44DB5E",
    faded: "#44DB5E33",
  },
  loss: {
    solid: "#E72035",
    faded: "#E7203533",
  },
  draw: {
    solid: "#FECA1D",
    faded: "#EFE52E66",
  },
};

const notifications: Notifications = {
  backgroundColor: "#EA1212",
  fontColor: "#FFFFFF",
};

const betSlipLabels: BetSlipLabels = {
  default: {
    primary: "#44DB5E",
    background: "#1E4536",
  },
  fastBet: {
    primary: "#EFE52E",
    background: "#40472C",
  },
};

const ctaButton: CtaButton = {
  fontColor: pureWhite,
  backgroundColor: "#4CAF50",
  fontColorSecondary: pureBlack,
  backgroundColorSecondary: "#F2E807",
};

const cardWrapperHighlight: CardWrapperHighlight = {
  color: "#EFE52E",
};

export const neutrals: NeutralColours = {
  cools,
  pures,
  pureWhite,
  pureWhiteGradients,
  pureBlack,
  pureBlackGradients,
  externalBrands,
  results,
  notifications,
  betSlipLabels,
  ctaButton,
  cardWrapperHighlight,
};
