import { Block } from "../../../types/draftManager";

export default function HeaderThree(
  block: Block,
  originalText: string
): JSX.Element {
  const { data } = block;

  return (
    <h5 style={{ textAlign: data?.alignment ? data.alignment : "left" }}>
      {originalText}
    </h5>
  );
}
