import {
  BookieDisplayOffer,
  OfferTypesWithoutFastBet,
  BookieDisplayFastBetOffer,
  PortalBookmakers,
} from "@/types/betslip";

export const NON_US_BET_TYPES = new Map([
  [1, "Single"],
  [2, "Double"],
  [3, "Treble"],
]);

export const offerMap: Record<OfferTypesWithoutFastBet, BookieDisplayOffer> = {
  accainsurance: {
    header: "Acca Insurance",
    description:
      "Free bet if one leg of your 4+ fold acca lets you down. Bet restrictions and T&Cs apply. See site for more details.",
  },
  earlypayout: {
    header: "Early Payout",
    description: "Bet restrictions and T&Cs apply. See site for more details.",
  },

  bestodds: {
    header: "Best Odds",
    description: "This bookie is offering you the best odds on your bet.",
  },
  eurosoccerbonus: {
    header: "Euro Soccer Bonus",
    description:
      "Up to 70% bonus for accumulators on selected leagues. Bonus is paid as cash.",
  },
  accaedge: {
    header: "Acca Edge",
    description:
      "Acca Edge is the most competitive form of insurance in the industry. Refunding as cash with no maximum limit.",
  },
};

export const fastBetOffers: BookieDisplayFastBetOffer = {
  header: "Fast Bet",
  description: {
    paddy_power: `Login to your Paddy Power account and place bets without leaving the Footy Accumulators website`,
    bet365:
      "Login to your Bet365 account and place bets without leaving the Footy Accumulators website",
    betfair:
      "Login to your Betfair account and place bets without leaving the Footy Accumulators website",
  },
};

export const bookiesThatSupportFastBet: PortalBookmakers[] = [
  "paddy_power",
  "bet365",
  "betfair",
];
