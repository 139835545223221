import { CommonAndEventIdPair } from "@/types/betslip";
import { commonAndEventIdsToQueryString } from "@/lib/betslip";
import { getActiveBrand } from "@/lib/brand";
import { OddsResponse } from "@/types/api/odds";
import { getDataFromPathAndQueryParams } from "@/lib/http/helpers";

export const getOddsByCommonAndSelectionIdPairs = async (
  commonAndSelectionIds: CommonAndEventIdPair[],
  locale: string
): Promise<OddsResponse> => {
  if (!commonAndSelectionIds?.length)
    return Promise.resolve({
      code: 0,
      bookmakers: [],
      selections: [],
    });
  const brand = getActiveBrand();
  const commonIdAndSelectionString = commonAndEventIdsToQueryString(
    commonAndSelectionIds
  ).join(",");
  const path = "smartacca/odds";
  const queryParams = {
    selections: commonIdAndSelectionString,
    location: locale.toUpperCase(),
    brand,
  };

  return await getDataFromPathAndQueryParams({
    path,
    queryParams,
    isOddsPlatformRequest: true,
  });
};
