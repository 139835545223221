import { ReactElement } from "react";
import { LinkNoPrefetch } from "@/components/LinkNoPrefetch/LinkNoPrefetch";
import { LocalisedFooterNav } from "@/types/staticTranslations";
import styles from "./SportsBlogFooterNav.module.scss";

interface Props {
  footerNav: LocalisedFooterNav;
}

export const SportsBlogFooterNav = ({ footerNav }: Props): ReactElement => {
  return (
    <div className={styles.sportsBlogFooterNavContainer}>
      <ul className={styles.sportsBlogFooterNavUl}>
        {footerNav.links.map((link) => (
          <li key={link.text}>
            <LinkNoPrefetch
              href={link.link}
              className={styles.sportsBlogFooterNavATag}
            >
              {link.text}
            </LinkNoPrefetch>
          </li>
        ))}
      </ul>
      <div>{`${footerNav.copyright} v3.1.4 (1)`}</div>
    </div>
  );
};
