import { useState, useCallback, useEffect } from "react";
import {
  checkIsDesktopDevice,
  checkIsMobileDevice,
} from "../utils/device/device";

export function useCheckMobileDevice(): boolean {
  const [isMobileDevice, setIsMobileDevice] = useState(true);
  const handleWindowResize = useCallback((): void => {
    const { innerWidth: width, innerHeight: height } = window;
    const isMobile = checkIsMobileDevice({ width, height });
    if (isMobile !== isMobileDevice) {
      setIsMobileDevice(isMobile);
    }
  }, [isMobileDevice]);

  useEffect(() => {
    handleWindowResize();

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return isMobileDevice;
}

export const useCheckDesktopDevice = (): boolean => {
  const [isDesktopDevice, setIsDesktopDevice] = useState(false);
  const handleWindowResize = useCallback((): void => {
    const { innerWidth: width, innerHeight: height } = window;
    const isDesktop = checkIsDesktopDevice({ width, height });
    if (isDesktop !== isDesktopDevice) {
      setIsDesktopDevice(isDesktop);
    }
  }, [isDesktopDevice]);

  useEffect(() => {
    handleWindowResize();

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return isDesktopDevice;
};
