import type { Block } from "../../../types/draftManager";

export default function HeaderFour(
  block: Block,
  originalText: string
): React.JSX.Element {
  const { data } = block;

  return <h4 style={{ textAlign: data?.alignment }}>{originalText}</h4>;
}
