import { Dispatch, ReactElement, useContext } from "react";

import FreeBetIcon from "@/components/icons/FreebetIcon";
import styles from "@/components/nav/NavIcons/NavIcons.module.scss";
import { BetSlipIcon } from "@/components/icons/BetSlipIcon";
import { getActiveBrand, getFreeBetPath } from "@/lib/brand";
import { BRAND_SHORT_NAMES_WITH_BETSLIP, FP_BRAND } from "@/constants/brands";
import {
  BetSlipDispatchContext,
  BetSlipStateContext,
} from "@/contexts/betSlipContext";
import { BetSlipActions, BetSlipState } from "@/types/betslip";
import { useLocaleFromRouter } from "@/hooks/LocationFromRouter";
import { useFreeBetCount } from "@/hooks/freeBetCount";
import { gaEvent } from "@/lib/gtag";
import { LinkNoPrefetch } from "@/components/LinkNoPrefetch/LinkNoPrefetch";
import { UsStates } from "@/types/api/config";
import { StateSelector } from "@/components/StateSelector/StateSelector";

interface NavIconsProps {
  fpStates?: UsStates[];
  region: string | null;
}

const NavIcons = ({ fpStates, region }: NavIconsProps): ReactElement => {
  const dispatch = useContext(
    BetSlipDispatchContext
  ) as Dispatch<BetSlipActions>;
  const { isOpen, activeCommonAndEventIdPairs } = useContext(
    BetSlipStateContext
  ) as BetSlipState;
  const brand = getActiveBrand();
  const betSlipEnabled =
    BRAND_SHORT_NAMES_WITH_BETSLIP.includes(brand) && !region;
  const [locale] = useLocaleFromRouter();
  const offerCount = useFreeBetCount(brand, region ?? locale);
  const toggleBetSlip = () => {
    isOpen ? dispatch({ type: "CLOSE" }) : dispatch({ type: "OPEN" });
  };

  const gaBetslipOpenedEvent = (): void => {
    gaEvent(brand === FP_BRAND ? "open_betslip" : "betslip_opened");
  };

  return (
    <div className={styles.navIconsContainer}>
      {brand === FP_BRAND ? (
        <StateSelector position={"header"} fpStates={fpStates} />
      ) : null}

      <LinkNoPrefetch
        className={styles.navIconLink}
        href={getFreeBetPath(brand, region)}
        aria-label="Free bets"
      >
        {offerCount ? (
          <span
            className={styles.navIconNotification}
            data-testid="freeBetCount"
          >
            {offerCount}
          </span>
        ) : null}
        <FreeBetIcon />
      </LinkNoPrefetch>
      {betSlipEnabled && (
        <div className={styles.navIconNotificationContainer}>
          {activeCommonAndEventIdPairs.length > 0 && (
            <span
              className={styles.navIconNotification}
              data-testid="selectionCount"
            >
              {activeCommonAndEventIdPairs.length}
            </span>
          )}
          <button
            className={styles.navIconButton}
            onClick={() => {
              toggleBetSlip();
              gaBetslipOpenedEvent();
            }}
          >
            Open bet slip
            <BetSlipIcon />
          </button>
        </div>
      )}
    </div>
  );
};

export default NavIcons;
