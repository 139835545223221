import { neutrals } from "./crossBrand";
// Rather than trying to come up with color names we're just giving
// setting the key as the next character in the alphabet

export const tweColors = {
  ...neutrals,
  tweGreen: {
    100: "#00561E",
    200: "#006323",
    300: "#006F27",
    400: "#16632B",
    500: "#2F6131",
    600: "#235625",
  },
  tweGold: "#F6C567",
};
