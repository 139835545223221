import type { Block } from "../../../types/draftManager";

export default function unstyled(
  block: Block,
  originalText: string | undefined
): React.JSX.Element {
  const { data } = block;

  return <p style={{ textAlign: data?.alignment }}>{originalText}</p>;
}
