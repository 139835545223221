import { type IConvertToHTMLConfig, convertToHTML } from "draft-convert";
import type { RawDraftContentState } from "draft-js";
import { convertFromRaw } from "draft-js";
import type { Block, Entity } from "../../types/draftManager";
import {
  convertToCamelCase,
  parseString,
  removeEmptyElements,
} from "../string/string";
import { blocks } from "./blocks";
import { styles } from "./styles";
import { entities } from "./entities";

const conversionHelpers = {
  styleToHTML(style: string) {
    const type = style.toLowerCase() as keyof typeof styles;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- conditional required due to type casting
    if (styles[type]) return styles[type]();
    return undefined;
  },
  blockToHTML(block: Block & Entity, originalText: string) {
    const type = convertToCamelCase(
      block.type.toLowerCase().split(":")[0]
    ) as keyof typeof blocks;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- conditional required due to type casting
    if (blocks[type]) return blocks[type](block, originalText) || null;
    return "";
  },
  entityToHTML(entity: Entity, originalText: string) {
    const type = entity.type.toLowerCase() as keyof typeof entities;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- conditional required due to type casting
    if (entities[type]) return entities[type](entity, originalText);
    return originalText;
  },
};

export function contentToHtml(contentBlock: RawDraftContentState): string {
  const contentState = convertFromRaw(contentBlock);
  const renderedHtml = convertToHTML(conversionHelpers as IConvertToHTMLConfig)(
    contentState
  );
  return removeEmptyElements(renderedHtml);
}

export const codeBlockToHtml = (codeBlock: string): string => {
  const context = parseString(codeBlock);
  return contentToHtml(context as RawDraftContentState);
};
