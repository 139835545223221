import type { PredictionCodesAPIResponse } from "../../types/api/predictionCodes";
import { getDataFromPathAndQueryParams } from "./helpers/helpers";

export const getPredictionCodes =
  async (): Promise<PredictionCodesAPIResponse> => {
    const path = `prediction-codes`;

    return getDataFromPathAndQueryParams({
      path,
    });
  };
