import { useRouter } from "next/router";
import { DEFAULT_LOCALE } from "../constants/countries";

export const useLocaleFromRouter = (
  defaultLocale = DEFAULT_LOCALE
): [string] => {
  const router = useRouter();
  const locale = router.query.locale;

  if (!locale) return [defaultLocale];

  const localeIsArray = Array.isArray(locale);

  if (localeIsArray) {
    return locale.length === 0 ? [defaultLocale] : [locale[0]];
  }

  return [locale];
};
