import { Block } from "../../../types/draftManager";

export default function HeaderTwo(
  block: Block,
  originalText: string
): JSX.Element {
  const { data } = block;

  return (
    <h2 style={{ textAlign: data?.alignment ? data.alignment : "left" }}>
      {originalText}
    </h2>
  );
}
