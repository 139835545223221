export const DATA_TABLE_COMPONENT = "DataTable";
export const OFFER_COMPONENT = "Offer";
export const CODE_BLOCK_COMPONENT = "CodeBlock";
export const EDITOR_COMPONENT = "Editor";
export const BETTING_TIP_COMPONENT = "BettingTip";
export const ACCORDION_COMPONENT = "Accordion";
export const TWITTER_CARD_COMPONENT = "TwitterCard";
export const MEDIA_COMPONENT = "Media";
export const TIPSTER_COMPONENT = "Tipster";
export const PPC_TABLE_COMPONENT = "PPC2";

export const WIDGETS_THAT_NEEDS_DATA = [
  OFFER_COMPONENT,
  TWITTER_CARD_COMPONENT,
  TIPSTER_COMPONENT,
  PPC_TABLE_COMPONENT,
  BETTING_TIP_COMPONENT,
] as const;
export const WIDGETS_THAT_DONT_NEED_DATA = [
  DATA_TABLE_COMPONENT,
  CODE_BLOCK_COMPONENT,
  EDITOR_COMPONENT,
  ACCORDION_COMPONENT,
  MEDIA_COMPONENT,
] as const;
export const ALL_WIDGETS = [
  ...WIDGETS_THAT_DONT_NEED_DATA,
  ...WIDGETS_THAT_NEEDS_DATA,
] as const;
