import React from "react";

interface Props {
  fill?: string;
}

const DropdownIcon = ({ fill = "#1D2A3A" }: Props) => (
  <svg
    width="8px"
    height="4px"
    viewBox="0 0 8 4"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
  >
    <title>Icon/Dropdown</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="FA_desktop_header"
        transform="translate(-1016.000000, -50.000000)"
        fill={fill}
      >
        <g id="Nav" transform="translate(244.000000, 27.000000)">
          <g id="More" transform="translate(719.000000, 12.000000)">
            <g id="Icon/Dropdown" transform="translate(53.000000, 11.000000)">
              <polygon
                id="Triangle"
                transform="translate(4.000000, 2.000000) scale(1, -1) translate(-4.000000, -2.000000) "
                points="4 0 8 4 0 4"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DropdownIcon;
