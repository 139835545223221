import { useState } from "react";
import { Editor } from "../../Editor";
import { codeBlockToHtml } from "../../../utils/draftManager/draftManager";
import { InformationIcon } from "../../../components/icons/InformationIcon";
import styles from "./CustomTip.module.scss";

export interface CustomTipProps {
  title: string;
  tip: string;
  reason?: string;
}

export function CustomTip({
  title,
  tip,
  reason,
}: CustomTipProps): React.JSX.Element {
  const [displayReason, setDisplayReason] = useState(false);

  const handleClick = (): void => {
    setDisplayReason(!displayReason);
  };

  return (
    <div className={styles.customTipContainer}>
      <div className={styles.tipWrapper}>
        <div className={styles.headerContainer}>
          <h5 className={styles.header}>{title}</h5>
          <h5 className={styles.header}>{tip}</h5>
        </div>
        {reason ? (
          <button
            aria-label="Show tip reason"
            className={styles.button}
            onClick={handleClick}
            type="button"
          >
            <InformationIcon className={styles.icon} />
          </button>
        ) : null}
      </div>
      {displayReason && reason ? (
        <Editor
          formattedContent={codeBlockToHtml(reason)}
          styleClasses={styles.editor}
        />
      ) : null}
    </div>
  );
}
