import type { NestedBlock } from "../../../types/draftManager";

export default function OrderedList(): NestedBlock {
  return {
    start: "<li>",
    end: "</li>",
    nestStart: "<ol>",
    nestEnd: "</ol>",
    nest: true,
  };
}
