import { Dispatch, useContext } from "react";
import { FP_BRAND } from "@/constants/brands";
import { getActiveBrand } from "@/lib/brand";
import {
  DEFAULT_ODDS_FORMAT,
  FP_ODDS_FORMAT,
  ODDS_FORMAT_TEXT_MAP,
} from "@/constants/odds";
import {
  BetSlipDispatchContext,
  BetSlipStateContext,
} from "@/contexts/betSlipContext";
import { BetSlipActions, BetSlipState } from "@/types/betslip";
import styles from "./OddsFormatPicker.module.scss";

export const OddsFormatPicker = () => {
  const brand = getActiveBrand();
  const allButtons = brand === FP_BRAND ? FP_ODDS_FORMAT : DEFAULT_ODDS_FORMAT;
  const dispatch = useContext(
    BetSlipDispatchContext
  ) as Dispatch<BetSlipActions>;
  const { oddsFormat } = useContext(BetSlipStateContext) as BetSlipState;

  const setActiveOdds = (button: (typeof allButtons)[0]) => {
    dispatch({ type: "SET_ODDS_FORMAT", oddsFormat: button });
  };

  return (
    <>
      <h4>Display Odds as</h4>
      <div className={styles.offerFormatPickerContainer}>
        {allButtons.map((button) => (
          <button
            aria-pressed={oddsFormat === button}
            onClick={() => setActiveOdds(button)}
            key={button}
            className={styles.offerFormatPicker}
          >
            {ODDS_FORMAT_TEXT_MAP.get(button)}
          </button>
        ))}
      </div>
    </>
  );
};
