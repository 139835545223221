import { FALSE_SCHEMA_VALUE } from "../../constants/seo";
import { BRAND_DETAILS_MAP } from "../../constants/brand";
import { getActiveBrand } from "../brand/brand";
import type { PpcAdTrackingBulletpointOfferCardMetaProps } from "../../types/api/ppc";

export const addLeadingSlash = (path: string): string => {
  if (path.startsWith("/")) return path;
  return `/${path}`;
};

export const parseString = (text: string): object => JSON.parse(text) as object;

export const convertToCamelCase = (string: string): string => {
  // find characters broken up by dashes and convert the first character to uppercase
  return string
    .trim()
    .replace(/(-|_|\s)+(.)?/g, (_match, _seperator, char: string) => {
      return char ? char.toUpperCase() : "";
    });
};

export const removeEmptyElements = (htmlString: string): string => {
  // remove any elements that have a closing tag but no content unless it is an iframe or an img element
  // these are the only elements, that the Editor component uses that are self closing
  return htmlString.replace(/<[^/>][^>]*><\/[^>]+>/g, (match) => {
    if (
      match.includes("iframe") ||
      match.includes("img") ||
      match.includes("script")
    ) {
      return match;
    }
    return "";
  });
};

export const isValidGifDomain = (url?: string): boolean => {
  if (!url) return false;
  return url.startsWith("https://media4.giphy.com");
};

export function isInternalLink(url: string): boolean {
  const brand = getActiveBrand();
  const brandDomain = BRAND_DETAILS_MAP[brand].domain;

  return url.includes(brandDomain);
}

export const joinWordsWithHyphen = (sentence: string): string =>
  sentence.split(" ").join("-").toLowerCase();

export const buildSchemaContent = ({
  schema,
  isPaywallContent = false,
}: {
  schema?: string;
  isPaywallContent?: boolean;
}): string | null => {
  const defaultPageType = { "@type": "WebPage" };
  const paywallSchemaContent = {
    isAccessibleForFree: FALSE_SCHEMA_VALUE,
    hasPart: {
      "@type": "WebPageElement",
      isAccessibleForFree: FALSE_SCHEMA_VALUE,
      cssSelector: ".paywall",
    },
  };
  const paywallOnlyShema = isPaywallContent
    ? JSON.stringify({
        ...defaultPageType,
        ...paywallSchemaContent,
      })
    : null;

  if (!schema) {
    return paywallOnlyShema;
  }

  const cmsSchema = schema
    .replace('<script type="application/ld+json">', "")
    .replace("</script>", "");

  try {
    const cmsJson = JSON.parse(cmsSchema) as JSON;

    const finalJson = isPaywallContent
      ? { ...cmsJson, ...paywallSchemaContent }
      : cmsJson;

    return typeof finalJson === "object" ? JSON.stringify(finalJson) : null;
  } catch (error) {
    return paywallOnlyShema;
  }
};

export const addAdTrackingParameterToDataOfferMetaLink = (
  meta: PpcAdTrackingBulletpointOfferCardMetaProps,
  tracking_parameter: string,
  tracking_click_id: string | undefined
) => {
  if (tracking_parameter) {
    if (tracking_click_id) {
      return {
        ...meta,
        link: `${meta.link}?${tracking_parameter}=${tracking_click_id}`,
      };
    }
  }
  return meta;
};
