import { FP_BRAND } from "../../constants/brand";
import { ODDS_FORMAT_COOKIE_NAME } from "../../constants/odds";
import type { OddsFormats } from "../../types/odds";
import type { GtagEventParams } from "../../types/gtag";
import { gaEvent } from "../gtag/gtag";
import { createCookieString } from "../cookie/cookie";
import { getActiveBrand } from "../brand/brand";
import type {
  BetAddedSourceTypes,
  BetSlipActions,
  BetSlipState,
  CommonAndEventIdPair,
} from "../../types/betslip";
import type { SelectionInterface } from "../../types/selections";

const brand = getActiveBrand();

export const commonAndEventIdsInArray = (
  array: CommonAndEventIdPair[],
  pair: CommonAndEventIdPair
) =>
  array.some(
    (selection) =>
      selection.eventId === pair.eventId && selection.commonID === pair.commonID
  );

export const commonAndEventIdsToQueryString = (
  commonAndEventIds: CommonAndEventIdPair[]
) => commonAndEventIds.map(({ commonID, eventId }) => `${eventId};${commonID}`);

// As we are building an acca we can only support 1 selection per event
export const removeSelectionFromArrayByEventId = (
  selections: SelectionInterface[],
  selectionToRemove: CommonAndEventIdPair
) => {
  const removedSelection = selections.filter(
    ({ event: { id } }) => selectionToRemove.eventId === id
  );

  gaRemoveFromBetslipEvent(removedSelection);

  return selections.filter(
    ({ event: { id } }) => selectionToRemove.eventId !== id
  );
};

// As we are building an acca we can only support 1 pair per event
export const removeCommonAndEventIdFromArray = (
  existingCommonAndEventIds: CommonAndEventIdPair[],
  commonAndEventIdPairToRemove: CommonAndEventIdPair
): CommonAndEventIdPair[] =>
  existingCommonAndEventIds.filter(
    ({ eventId }) => commonAndEventIdPairToRemove.eventId !== eventId
  );

interface HandleAddedEventAndCommonIdParams {
  state: BetSlipState;
  commonAndEventIds: CommonAndEventIdPair;
  source: BetAddedSourceTypes;
}

export const handleAddedEventAndCommonId = ({
  state,
  commonAndEventIds,
  source,
}: HandleAddedEventAndCommonIdParams): BetSlipState => {
  const matchingEventIdIndex = state.activeCommonAndEventIdPairs.findIndex(
    ({ eventId }) => eventId === commonAndEventIds.eventId
  );
  const matchingEventAndCommonIdIndex =
    state.activeCommonAndEventIdPairs.findIndex(
      ({ eventId, commonID }) =>
        eventId === commonAndEventIds.eventId &&
        commonID === commonAndEventIds.commonID
    );

  // If no matching event and common id. Append new pair, this triggers a new request to get selection data
  if (matchingEventIdIndex === -1 && matchingEventAndCommonIdIndex === -1) {
    return {
      ...state,
      activeCommonAndEventIdPairs: [
        ...state.activeCommonAndEventIdPairs,
        commonAndEventIds,
      ],
      betAddedSource: source,
      // Always empty bookmakers because odds are irrelevant until HTTP request
      bookmakers: [],
    };
  }

  // If matching event id only
  if (matchingEventAndCommonIdIndex === -1) {
    // Remove old selection and commonAndEventId pair
    const removedPreviousSelectionFromState = {
      ...state,
      selectionsDataAndState: removeSelectionFromArrayByEventId(
        state.selectionsDataAndState,
        commonAndEventIds
      ),
      activeCommonAndEventIdPairs: removeCommonAndEventIdFromArray(
        state.activeCommonAndEventIdPairs,
        commonAndEventIds
      ),
      betAddedSource: source,
      // Always empty bookmakers because odds are irrelevant until HTTP request
      bookmakers: [],
    };

    return {
      ...removedPreviousSelectionFromState,
      activeCommonAndEventIdPairs: [
        ...removedPreviousSelectionFromState.activeCommonAndEventIdPairs,
        commonAndEventIds,
      ],
      betAddedSource: source,
      // Always empty bookmakers because odds are irrelevant until HTTP request
      bookmakers: [],
    };
  }

  // Otherwise the event and common id are a duplicate in which case it's removed
  return {
    ...state,
    selectionsDataAndState: removeSelectionFromArrayByEventId(
      state.selectionsDataAndState,
      commonAndEventIds
    ),
    activeCommonAndEventIdPairs: removeCommonAndEventIdFromArray(
      state.activeCommonAndEventIdPairs,
      commonAndEventIds
    ),
    betAddedSource: source,
    // Always empty bookmakers because odds are irrelevant until HTTP request
    bookmakers: [],
  };
};

interface HandleAddedAccaEventAndCommonIdParams {
  state: BetSlipState;
  commonAndEventIdsArray: CommonAndEventIdPair[];
  source: BetAddedSourceTypes;
  locale?: string;
  isAccaAddedFromLocaleStorage?: boolean;
}

export const handleAddedAccaEventAndCommonIds = ({
  state,
  commonAndEventIdsArray,
  source,
}: HandleAddedAccaEventAndCommonIdParams): BetSlipState => {
  // We also check the length so an acca with fewer selections doesn't clear the bet slip
  const hasMatchingEventIdIndex = commonAndEventIdsArray.every(
    (pair) =>
      state.activeCommonAndEventIdPairs.findIndex(
        ({ eventId }) => eventId === pair.eventId
      ) !== -1 &&
      commonAndEventIdsArray.length === state.activeCommonAndEventIdPairs.length
  );

  const hasMatchingEventAndCommonIdIndex = commonAndEventIdsArray.every(
    (pair) =>
      state.activeCommonAndEventIdPairs.findIndex(
        ({ eventId, commonID }) =>
          eventId === pair.eventId && commonID === pair.commonID
      ) !== -1 &&
      commonAndEventIdsArray.length === state.activeCommonAndEventIdPairs.length
  );

  const { selectionsDataAndState } = state;

  // If it's a duplicate acca we clear the bet slip
  if (hasMatchingEventIdIndex && hasMatchingEventAndCommonIdIndex) {
    if (source !== "localStorage") {
      gaRemoveFromBetslipEvent(selectionsDataAndState);
    }

    return {
      ...state,
      selectionsDataAndState: [],
      activeCommonAndEventIdPairs: [],
      betAddedSource: null,
      bookmakers: [],
    };
  }

  // Overwrite anything in the bet slip with the new acca and send selections to Google Analytics
  if (state.selectionsDataAndState.length > 0) {
    gaRemoveFromBetslipEvent(selectionsDataAndState);
  }

  return {
    ...state,
    selectionsDataAndState: [],
    activeCommonAndEventIdPairs: commonAndEventIdsArray,
    betAddedSource: source,
    bookmakers: [],
  };
};

export const updateSelectionsData = (
  existingSelections: SelectionInterface[],
  selectionsData: SelectionInterface[]
): SelectionInterface[] => {
  const updatedExistingSelections = existingSelections.map(
    (existingSelection) => {
      const updatedSelection = selectionsData.find(
        (selection) =>
          selection.event.id === existingSelection.event.id &&
          selection.commonID === existingSelection.commonID
      );

      return updatedSelection ? updatedSelection : existingSelection;
    }
  );
  const newSelections = selectionsData.filter(
    (selection) =>
      !updatedExistingSelections.some(
        (existingSelection) =>
          existingSelection.event.id === selection.event.id &&
          existingSelection.commonID === selection.commonID
      )
  );

  return [...updatedExistingSelections, ...newSelections];
};

export const enableSelection = (
  state: BetSlipState,
  commonAndEventIdPair: CommonAndEventIdPair
): BetSlipState => {
  const enabledSelectionIndex = state.selectionsDataAndState.findIndex(
    (existingSelection) =>
      existingSelection.event.id === commonAndEventIdPair.eventId &&
      existingSelection.commonID === commonAndEventIdPair.commonID
  );
  const updatedSelectionState = [...state.selectionsDataAndState];
  updatedSelectionState[enabledSelectionIndex].isDisabled = false;

  const updatedCommonAndEventIds = [...state.activeCommonAndEventIdPairs];
  updatedCommonAndEventIds.splice(enabledSelectionIndex, 0, {
    commonID: commonAndEventIdPair.commonID,
    eventId: commonAndEventIdPair.eventId,
  });

  return {
    ...state,
    selectionsDataAndState: updatedSelectionState,
    activeCommonAndEventIdPairs: updatedCommonAndEventIds,
  };
};

export const updateOddsFormat = (
  state: BetSlipState,
  oddsFormat: OddsFormats
) => ({
  ...state,
  oddsFormat,
});

export const addToBetSlipEventParams = (
  selection: SelectionInterface,
  source: BetAddedSourceTypes
): GtagEventParams => {
  const { event, meta } = selection;
  const { type, description } = event;
  const { competitionName = "" } = meta || {};
  const fpCompetitionName = event.competitionName;

  return {
    source,
    bet_sport: type,
    bet_competition: brand === FP_BRAND ? fpCompetitionName : competitionName,
    bet_event: description,
  };
};

export const removeFromBetSlipEventParams = (
  selection: SelectionInterface
): GtagEventParams => {
  const { event, meta, marketName } = selection;
  const { type, description } = event;
  const { competitionName = "" } = meta || {};
  const fpCompetitionName = event.competitionName || "";

  return {
    bet_sport: type,
    bet_competition: brand === FP_BRAND ? fpCompetitionName : competitionName,
    bet_event: description,
    bet_selection: marketName,
  };
};

export const gaAddToBetSlipEvent = (
  selections: SelectionInterface[],
  source: BetAddedSourceTypes
): void => {
  selections.forEach((selection) => {
    const gaEventParams = addToBetSlipEventParams(selection, source);

    gaEvent("add_to_betslip", gaEventParams);
  });
};

const gaRemoveFromBetslipEvent = (selections: SelectionInterface[]): void => {
  selections.forEach((selection) => {
    const gaEventParams = removeFromBetSlipEventParams(selection);

    gaEvent("remove_from_betslip", gaEventParams);
  });
};

const gaClearBetSlipEvent = (selectionsLength: number): void => {
  const gaEventParams = {
    no_of_selections: selectionsLength,
  };

  gaEvent("clear_betslip", gaEventParams);
};

export function betSlipReducer(
  state: BetSlipState,
  action: BetSlipActions
): BetSlipState {
  const { type } = action;

  switch (type) {
    case "OPEN":
      return {
        ...state,
        isOpen: true,
      };
    case "CLOSE":
      return {
        ...state,
        isOpen: false,
      };
    case "ADD": {
      const { source = null, commonAndSelectionIdPair } = action;
      return handleAddedEventAndCommonId({
        state,
        commonAndEventIds: commonAndSelectionIdPair,
        source,
      });
    }
    case "ADD_ACCA": {
      const { source: addAccaSource = null, commonAndSelectionIdPairsArray } =
        action;

      return handleAddedAccaEventAndCommonIds({
        state,
        commonAndEventIdsArray: commonAndSelectionIdPairsArray,
        source: addAccaSource,
      });
    }
    case "DISABLE": {
      const updatedSelections = state.selectionsDataAndState.map(
        (selection) => {
          if (
            selection.event.id === action.commonAndSelectionIdPair.eventId &&
            selection.commonID === action.commonAndSelectionIdPair.commonID
          ) {
            return { ...selection, isDisabled: true };
          }
          return selection;
        }
      );

      const filteredCommonAndEventIdPairs =
        state.activeCommonAndEventIdPairs.filter(
          (pair) => pair.eventId !== action.commonAndSelectionIdPair.eventId
        );

      return {
        ...state,
        selectionsDataAndState: updatedSelections,
        activeCommonAndEventIdPairs: filteredCommonAndEventIdPairs,
        betAddedSource: "removed",
      };
    }
    case "ENABLE": {
      return enableSelection(state, action.commonAndSelectionIdPair);
    }
    case "REMOVE":
      return {
        ...state,
        activeCommonAndEventIdPairs: removeCommonAndEventIdFromArray(
          state.activeCommonAndEventIdPairs,
          action.commonAndSelectionIdPair
        ),
        selectionsDataAndState: removeSelectionFromArrayByEventId(
          state.selectionsDataAndState,
          action.commonAndSelectionIdPair
        ),
        betAddedSource: "removed",
        // Always empty bookmakers because odds are irrelevant until HTTP request
        bookmakers: [],
      };
    case "UPDATE_SELECTIONS": {
      const updatedSelections = updateSelectionsData(
        state.selectionsDataAndState,
        action.selections
      );

      return {
        ...state,
        selectionsDataAndState: updatedSelections,
        bookmakers: action.bookmakers,
      };
    }
    case "SET_PORTAL_BOOKMAKER": {
      return {
        ...state,
        portalBookmaker: action.bookmaker,
      };
    }
    case "SET_ODDS_HAVE_CHANGED": {
      return {
        ...state,
        oddsChanged: action.oddsChanged,
      };
    }
    case "SET_ODDS_FORMAT": {
      const { oddsFormat } = action;
      document.cookie = createCookieString({
        name: ODDS_FORMAT_COOKIE_NAME,
        value: oddsFormat,
      });
      return updateOddsFormat(state, oddsFormat);
    }
    case "CLEAR": {
      const { selectionsDataAndState } = state;

      gaClearBetSlipEvent(selectionsDataAndState.length);

      return {
        ...state,
        selectionsDataAndState: [],
        activeCommonAndEventIdPairs: [],
        bookmakers: [],
        oddsChanged: false,
        isOpen: false,
        portalBookmaker: null,
      };
    }
    // Used to set best odds so we can send odds data to smart picks betting iframe
    case "SET_BEST_ODDS": {
      return {
        ...state,
        bestOdds: action.bestOdds,
      };
    }
    default:
      return state;
  }
}

export function generateBetType(betCount: number): string {
  const betTypeMap = new Map([
    [1, "Single"],
    [2, "Double"],
    [3, "Treble"],
  ]);
  return betTypeMap.get(betCount) || `${betCount} Fold`;
}
