import styled from "styled-components";
import { devices } from "@/constants/devices";
import { LinkNoPrefetch } from "@/components/LinkNoPrefetch/LinkNoPrefetch";

export const CheckdMediaFooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.footer.backgroundColor};
  color: ${({ theme }) => theme.footer.fontColor};
  text-align: center;
  padding: 64px 0;

  @media only screen and ${devices.laptop} {
    text-align: left;
  }
`;

export const CheckdMediaFooterDesktopRow = styled.div`
  @media only screen and ${devices.laptop} {
    display: flex;
    justify-content: center;
    max-width: 1800px;
    margin: 0 auto;
  }
`;

export const CheckdLogoContainer = styled.div`
  display: flex;
  justify-content: center;

  svg {
    max-width: 100px;
    max-height: 100px;
  }

  @media only screen and ${devices.laptop} {
    justify-content: flex-start;
    padding-left: 2rem;

    svg {
      max-width: 200px;
      max-height: 200px;
    }
  }
`;

export const CheckdAwardsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  margin: 2rem 0 3rem;
`;

export const CheckdAwardsImageContainer = styled.div`
  padding: 0 2rem;
  position: relative;
  width: 80px;
  max-width: 120px;
  height: 70px;

  img {
    object-fit: contain;
  }

  @media only screen and ${devices.laptop} {
    width: 125px;
    max-width: 125px;
    height: 100px;
    max-height: 100px;
  }
`;

export const CheckdMediaFooterH4 = styled.h4`
  color: rgb(147, 150, 151);
  font-size: 1rem;

  @media only screen and ${devices.laptop} {
    font-size: 1.15rem;
  }
`;

export const OfficeAddressSection = styled.section`
  @media only screen and ${devices.laptop} {
    margin: 0 10% 0 5%;
  }
`;

export const OfficeAddress = styled.address`
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-style: normal;
  margin-bottom: 2rem;
`;

export const OfficeName = styled.p`
  font-family: var(--font-montserrat);
  font-weight: 700;
  color: rgb(0, 158, 227);

  @media only screen and ${devices.laptop} {
    font-size: 1.15rem;
  }
`;

export const OfficeDetail = styled.p`
  line-height: 1.5;
  margin-bottom: 0;

  @media only screen and ${devices.laptop} {
    font-size: 1.15rem;
  }
`;

export const SocialGrid = styled.div`
  display: grid;
  grid-template-columns: 24px 24px;
  grid-row: auto;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  justify-content: center;
  margin-bottom: 64px;

  a > svg {
    max-width: 24px;
  }

  @media only screen and ${devices.laptop} {
    grid-template-columns: 36px 36px;
    grid-column-gap: 36px;
    grid-row-gap: 36px;
    padding-right: 2rem;

    a > svg {
      max-width: 36px;
    }
  }
`;

export const LegalSection = styled.section`
  color: rgb(147, 150, 151);
  font-size: 10px;
  font-family: var(--font-montserrat);
  font-weight: 700;

  @media only screen and ${devices.laptop} {
    font-size: 13px;
    margin: 64px 96px;
  }
`;

export const CopyrightParagraph = styled.p`
  font-size: 10px;
  margin-bottom: 0;

  @media only screen and ${devices.laptop} {
    font-size: 13px;
    display: inline;

    ::after {
      content: " | ";
    }
  }
`;

export const LegalLink = styled(LinkNoPrefetch)`
  color: rgb(147, 150, 151);
  text-decoration: none;
`;
