export function Padlock() {
  return (
    <svg
      height="39px"
      version="1.1"
      viewBox="0 0 32 39"
      width="32px"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Padlock</title>
      <g
        fill="none"
        fillRule="evenodd"
        id="Desktop"
        stroke="none"
        strokeWidth="1"
      >
        <g
          fill="#051D1D"
          fillRule="nonzero"
          id="0.0-model_locked"
          transform="translate(-944, -450)"
        >
          <path
            d="M970.666667,463 L974.222222,463 C975.204089,463 976,463.83148 976,464.857143 L976,487.142857 C976,488.168557 975.204089,489 974.222222,489 L945.777778,489 C944.795947,489 944,488.168557 944,487.142857 L944,464.857143 C944,463.83148 944.795947,463 945.777778,463 L949.333333,463 L949.333333,461.142857 C949.333333,454.988824 954.10896,450 960,450 C965.891022,450 970.666667,454.988824 970.666667,461.142857 L970.666667,463 Z M958.222222,477.360171 L958.222222,481.571429 L961.777778,481.571429 L961.777778,477.360171 C962.840533,476.717971 963.555556,475.5177 963.555556,474.142857 C963.555556,472.091457 961.963733,470.428571 960,470.428571 C958.036267,470.428571 956.444444,472.091457 956.444444,474.142857 C956.444444,475.5177 957.159467,476.717971 958.222222,477.360171 Z M967.111111,463 L967.111111,461.142857 C967.111111,457.040169 963.927289,453.714286 960,453.714286 C956.07264,453.714286 952.888889,457.040169 952.888889,461.142857 L952.888889,463 L967.111111,463 Z"
            id="Shape"
          />
        </g>
      </g>
    </svg>
  );
}
