import { StarFull } from "../../../components/icons/StarFull";
import { StarEmpty } from "../../../components/icons/StarEmpty";
import { StarHalf } from "../../../components/icons/StarHalf";
import styles from "./Score.module.scss";

export function Score({ score }: { score: number }) {
  const hasHalfStar = score % 1 !== 0;

  return (
    <div aria-label={`${score} stars out of 5`} className={styles.container}>
      {[1, 2, 3, 4, 5].map((star) => {
        if (star <= score) {
          return <StarFull aria-hidden key={star} />;
        }
        if (hasHalfStar && star === Math.ceil(score)) {
          return <StarHalf aria-hidden key={star} />;
        }
        return <StarEmpty aria-hidden key={star} />;
      })}
    </div>
  );
}
