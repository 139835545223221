import { SelectionInterface } from "@/types/api/selections";
import { isEveryStringOrNumberArrayItemIdentical } from "@/lib/array";
import { getActiveBrand } from "@/lib/brand";
import { FA_BRAND, FP_BRAND } from "@/constants/brands";
import { BetAddedSourceTypes } from "@/types/betslip";

declare global {
  interface Window {
    gtag: (event: "event", action: string, params?: GtagEventParams) => void;
  }
}

const brand = getActiveBrand();

const enableAnalytics = () =>
  (typeof window !== undefined && brand === FA_BRAND) ||
  (typeof window !== undefined && brand === FP_BRAND);

export type GtagEventParams =
  | Record<string, string | number>
  | Record<"source", BetAddedSourceTypes>;

export const gaEvent = (
  eventName: string,
  eventParams?: GtagEventParams
): void => {
  if (enableAnalytics()) {
    window.gtag("event", eventName, eventParams);
  }
};

export const betslipClickoutEventParams = (
  bookmaker: string,
  selectionsDataAndState: SelectionInterface[],
  oddsAmerican?: string
): GtagEventParams => {
  const betSportArr = selectionsDataAndState.map(({ event: { type } }) => type);
  const betCompetitionArr = selectionsDataAndState.map(
    ({ meta: { competitionName } = {} }: Partial<SelectionInterface> = {}) =>
      competitionName || ""
  );
  const betEventArr = selectionsDataAndState.map(
    ({ event: { description } }) => description
  );
  const betSelectionArr = selectionsDataAndState.map(
    ({ marketName }) => marketName
  );
  const fpCompetitionArr = selectionsDataAndState.map(
    ({ event: { competitionName } = {} }) => competitionName || ""
  );

  if (brand === FP_BRAND) {
    return {
      bookmaker,
      no_of_selections: selectionsDataAndState.length,
      odds: oddsAmerican ? oddsAmerican : "",
      bet_sport: isEveryStringOrNumberArrayItemIdentical(betSportArr)
        ? betSportArr[0]
        : "Mixed",
      bet_competition: isEveryStringOrNumberArrayItemIdentical(fpCompetitionArr)
        ? fpCompetitionArr[0]
        : "Mixed",
      bet_event: isEveryStringOrNumberArrayItemIdentical(betEventArr)
        ? betEventArr[0]
        : "Mixed",
      bet_selection: isEveryStringOrNumberArrayItemIdentical(betSelectionArr)
        ? betSelectionArr[0]
        : "Mixed",
    };
  }

  return {
    bookmaker,
    bet_sport: isEveryStringOrNumberArrayItemIdentical(betSportArr)
      ? betSportArr[0]
      : "Mixed",
    bet_competition: isEveryStringOrNumberArrayItemIdentical(betCompetitionArr)
      ? betCompetitionArr[0]
      : "Mixed",
    bet_event: isEveryStringOrNumberArrayItemIdentical(betEventArr)
      ? betEventArr[0]
      : "Mixed",
    bet_selection: isEveryStringOrNumberArrayItemIdentical(betSelectionArr)
      ? betSelectionArr[0]
      : "Mixed",
  };
};

export const isPredictionsLink = (urlPath: string): boolean => {
  const splitPath: string[] = urlPath.split("/");
  if (splitPath.length === 3 && splitPath[1] === "predictions") {
    return true;
  } else {
    return false;
  }
};
