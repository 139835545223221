import {
  AdvertData,
  AdvertResponses,
  BannerData,
  InterstitialData,
  MpuData,
  TramlineData,
} from "@/types/api/adverts";
import { getDataFromPathAndQueryParams } from "@/lib/http/helpers";

export const getActiveAdvertsByType = async (
  type: string,
  locale: string
): Promise<AdvertResponses> => {
  const path = `adverts/${type}`;
  const queryParams = {
    locale,
  };

  return await getDataFromPathAndQueryParams({ path, queryParams });
};

export const getAllActiveAdverts = async (
  pathname: string,
  locale: string
): Promise<AdvertData> => {
  let advertsData;
  // Get active interstitials for the homepage only
  if (pathname.startsWith("/dynamic-homepage") || pathname.startsWith("/br")) {
    const { data: interstitialData } = await getActiveAdvertsByType(
      "interstitial",
      locale
    );

    advertsData = {
      interstitials: interstitialData as InterstitialData[],
    };
  }

  // Get other active adverts
  const { data: bannerData } = await getActiveAdvertsByType("banner", locale);
  const { data: tramlineData } = await getActiveAdvertsByType(
    "tramline",
    locale
  );
  const { data: mpuData } = await getActiveAdvertsByType("mpu", locale);

  // Check length before we return data, to ensure we aren't returning empty objects
  advertsData = {
    ...advertsData,
    banner: bannerData.length > 0 ? (bannerData as BannerData[]) : undefined,
    tramlines:
      tramlineData && tramlineData.length > 0
        ? (tramlineData as TramlineData[])
        : undefined,
    mpu: mpuData.length > 0 ? (mpuData as MpuData[]) : undefined,
  };

  return advertsData;
};
