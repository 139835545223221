import { FA_BRAND, FP_BRAND } from "@/constants/brands";

const faStakeAmounts = ["5", "10", "25", "50"];
const fpStakeAmounts = ["20", "100", "250", "500"];

export const defaultStakeValueMapping = new Map([
  [FA_BRAND, faStakeAmounts[1]],
  [FP_BRAND, fpStakeAmounts[1]],
]);

export const stakeAmountsMapping = new Map([
  [FA_BRAND, faStakeAmounts],
  [FP_BRAND, fpStakeAmounts],
]);
