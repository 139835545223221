import Image from "next/image";
import styles from "@/components/FooterLegislation/FooterLegislation.module.scss";
import { FP_BRAND } from "@/constants/brands";
import { SportBrandShortNames } from "@/types/brand";
import { getLocalisedConfig } from "@/lib/geo";
import { getGambleAwareContent } from "@/lib/localisedContent";

interface FooterLegislationProps {
  parent: "modal" | "footer" | "menu";
  brand: SportBrandShortNames;
  region?: string | null;
  colourMode?: string;
}

export const FooterLegislation = ({
  parent,
  brand,
  region,
  colourMode,
}: FooterLegislationProps) => {
  const isBrandFp = brand === FP_BRAND;
  const gamblingConfig = getGambleAwareContent(brand);
  if (!gamblingConfig) return null;
  const gamblingData = getLocalisedConfig({
    locale: region ?? "",
    fallbackKey: "default",
    localisedConfig: gamblingConfig,
  });

  const gambleAware =
    parent === "footer" && gamblingData.gambleAware.length > 1
      ? gamblingData.gambleAware
      : [gamblingData.gambleAware[0]];

  return (
    <div className={styles.footerLegislationContainer}>
      {gamblingData.ageRestriction ? (
        <div className={styles.footerLegalGamblingAge}>
          <span>{gamblingData.ageRestriction}</span>
          <span>+</span>
        </div>
      ) : null}
      <div className={styles.logoContainer}>
        {gambleAware.map((item) => {
          const { id, title, siteLink, imageAltText, defaultLogo, logos } =
            item;

          let logo = defaultLogo;
          if (logos && colourMode) {
            logo = logos[colourMode ?? "light"];
          }

          if (brand === FP_BRAND && parent === "modal") {
            logo.src =
              "https://cdn.checkd.media/logos/fp_1800_gambler_white_bg.png";
          }

          if (!siteLink) {
            return (
              <div
                className={styles.footerLegislationContainer}
                key={`${imageAltText}-${parent}`}
              >
                <Image
                  src={logo.src}
                  width={logo.width}
                  height={logo.height}
                  alt={imageAltText}
                  data-testid={id}
                  className={styles.gambleAwareImage}
                />
              </div>
            );
          }

          return (
            <a
              href={siteLink}
              key={`${imageAltText}-${parent}`}
              rel="noopener noreferrer"
              target="_blank"
              title={title}
              className={isBrandFp ? styles.problemGamblingLink : ""}
            >
              <Image
                src={logo.src}
                width={logo.width}
                height={logo.height}
                alt={imageAltText}
                data-testid={id}
                className={styles.gambleAwareImage}
              />
            </a>
          );
        })}
      </div>
    </div>
  );
};
