import { BrandTheme } from "@/types/brand";
import {
  cmColors,
  faColors,
  fpColors,
  ocColors,
  ppc1Colors,
  tweColors,
} from "@/brands/colors";

// We have a default theme being returned to ensure we don't get any errors if passed in invalid brand
const themeSelector = (brand: string, subBrand?: string): BrandTheme | null => {
  switch (brand.toLowerCase()) {
    case "fa":
      return {
        background: faColors.faLightModeBackground,
        primary: faColors.cools[100],
        secondary: faColors.cools[900],
        defaultText: faColors.pureBlack,
        subText: faColors.cools[700],
        buttonText: faColors.pureBlack,
        accent: faColors.faYellow,
        border: faColors.cools[100],
        darkerBorder: faColors.cools[200],
        contentHover: faColors.pureBlackGradients[200],
        contentBackground: faColors.pureWhite,
        contentHeaderBackground: faColors.darkBlue,
        contentHeaderFontColor: faColors.pureWhite,
        contentHeaderFontColorInactive: faColors.pureWhiteGradients[500],
        activeElement: faColors.cools[900],
        inactiveElement: faColors.cools[200],
        activeSubBrand: subBrand,
        timeToThinkBackground: faColors.externalBrands.timeToThink,
        tabButtonBackground: faColors.cools[100],
        activeTabButtonBackground: faColors.darkBlue,
        activeTabButtonText: faColors.pureWhite,
        winSolidColour: faColors.results.win.solid,
        lossSolidColour: faColors.results.loss.solid,
        drawSolidColour: faColors.results.draw.solid,
        winFadedColour: faColors.results.win.faded,
        drawFadedColour: faColors.results.draw.faded,
        lossFadedColour: faColors.results.loss.faded,
        formResultMetaFontColor: faColors.cools[800],
        linkAngleRightColor: faColors.cools[400],
        header: {
          height: 48,
          background: faColors.faYellow,
          secondary: faColors.cools[900],
          fontColor: faColors.darkBlue,
          moreMenu: {
            background: faColors.darkBlue,
            boxShadow: faColors.pureBlackGradients[400],
            iconsBorder: faColors.pureBlackGradients[200],
            iconsBackground: faColors.darkBlue,
          },
        },
        betSlip: {
          background: faColors.midBlue,
          highlight: faColors.pureWhite,
          secondary: faColors.cools[800],
          clearButtonFontColor: faColors.paleBlue,
          borderBottomColor: faColors.darkBlue,
          disabledButtonBackgroundColor: faColors.results.loss.solid,
          enabledButtonBackgroundColor: faColors.results.win.solid,
          selectionBorderBottomColor: faColors.pureWhiteGradients[100],
          clearAllButtonBackgroundColor: faColors.pureWhite,
          clearAllButtonFontColor: faColors.darkBlue,
          labelPrimaryColor: faColors.betSlipLabels.default.primary,
          labelBackgroundColor: faColors.betSlipLabels.default.background,
          fastBetLabelPrimaryColor: faColors.betSlipLabels.fastBet.primary,
          fastBetLabelBackgroundColor:
            faColors.betSlipLabels.fastBet.background,
        },
        betType: {
          fontColor: faColors.pureWhite,
          backgroundColor: faColors.midBlue,
        },
        secondaryNav: {
          background: faColors.darkBlue,
          desktopLinkBackground: faColors.lightBlue,
          linkBackgroundHover: faColors.faYellow,
          linkHoverText: faColors.pureBlack,
        },
        mobileNav: {
          background: faColors.cools[900],
          menuBorder: faColors.cools[800],
          menuIconFill: faColors.darkBlue,
          menuIconsBorder: faColors.pureBlackGradients[200],
        },
        matchOverview: {
          overlay: faColors.pureWhiteGradients[800],
          scoreBackground: faColors.pureWhite,
          scoreText: faColors.pureBlack,
          competitionFontColor: faColors.pureBlack,
        },
        matchStat: {
          teamSubFontColor: faColors.cools[400],
          goalIconCountColor: faColors.results.win.solid,
          assistIconCountColor: faColors.pures[300],
          ownGoalIconCountColor: faColors.results.loss.solid,
          missedPenIconCountColor: faColors.results.loss.solid,
          iconCountBorderColor: faColors.cools[900],
          positiveStatColor: faColors.results.win.solid,
        },
        leagueTable: {
          winner: faColors.results.win.solid,
          loser: faColors.results.loss.solid,
          playoff: faColors.results.draw.solid,
          border: faColors.lightBlue,
        },
        author: {
          fontColor: faColors.pureBlack,
          secondaryColor: faColors.cools[700],
        },
        footer: {
          fontColor: faColors.pureBlack,
          backgroundColor: faColors.pures[100],
          navBackgroundColor: faColors.pures[100],
        },
        breadCrumbs: {
          fontColor: faColors.cools[700],
        },
        notifications: {
          backgroundColor: faColors.notifications.backgroundColor,
          fontColor: faColors.notifications.fontColor,
        },
        tab: {
          fontColor: faColors.pureBlack,
          activeFontColor: faColors.pureWhite,
          inactiveTabColor: faColors.cools[100],
          underlineFontColor: faColors.pureWhite,
          activeTabColor: faColors.darkBlue,
          activeTabUnderlineColor: faColors.faYellow,
          tabContainerBackgroundColor: faColors.pureWhite,
        },
        smartAccaCompetition: {
          buttonGroupBackgroundColor: faColors.lightMidBlue,
          tableHeaderBackgroundColor: faColors.lessDarkGrey,
          tableRowBackgroundColor: faColors.pureWhite,
          tableRowFirstColumnBackgroundColor: faColors.veryLightGrey,
          tableRowBorderColor: faColors.pures[200],
          activeButtonBackground: faColors.faYellow,
          activeButtonFontColor: faColors.lightMidBlue,
          inactiveButtonBackground: faColors.darkBlue,
          inactiveButtonFontColor: faColors.pureWhite,
          viewMoreButtonFontColor: faColors.pures[400],
          teamSelectionFontColor: faColors.paleBlue,
          scrollBarColor: faColors.veryLightGrey,
          scrollBarBackgroundColor: faColors.lightMidBlue,
        },
        smartAccaMatch: {
          primaryBackgroundColor: faColors.midBlue,
          secondaryBackgroundColor: faColors.darkBlue,
          primaryFontColor: faColors.pureWhite,
          secondaryFontColor: faColors.paleBlue,
          highProbabilityFontColor: faColors.smartAccaGreen,
          buttonBackgroundColor: faColors.pureWhite,
          activeButtonBackgroundColor: faColors.faYellow,
          scrollBarColor: faColors.midBlue,
          scrollBarBackgroundColor: faColors.darkBlue,
          borderColor: faColors.lightBlue,
          formWinColor: faColors.results.win.solid,
          formLossColor: faColors.results.loss.solid,
          formDrawColor: faColors.results.draw.solid,
        },
        suggestedSmartAcca: {
          primaryBackgroundColor: faColors.midBlue,
          secondaryBackgroundColor: faColors.darkBlue,
          primaryFontColor: faColors.pureWhite,
          secondaryFontColor: faColors.paleBlue,
          buttonBackgroundColor: faColors.pureWhite,
          activeButtonBackgroundColor: faColors.faYellow,
          scrollBarColor: faColors.midBlue,
          scrollBarBackgroundColor: faColors.darkBlue,
          borderColor: faColors.lightBlue,
        },
        progressCircle: {
          fontColor: faColors.smartAccaGreen,
        },
        ctaButton: {
          fontColor: faColors.ctaButton.fontColor,
          backgroundColor: faColors.ctaButton.backgroundColor,
          fontColorSecondary: faColors.ctaButton.fontColorSecondary,
          backgroundColorSecondary: faColors.ctaButton.backgroundColorSecondary,
        },
        cardWrapper: {
          highlight: faColors.cardWrapperHighlight.color,
        },
        tipReason: {
          borderColor: faColors.faYellow,
        },
        pagination: {
          activeBackgroundColor: faColors.lightMidBlue,
          activeFontColor: faColors.pureWhite,
        },
      };
    case "fa-dark":
      return {
        background: faColors.darkBlue,
        primary: faColors.cools[100], //!TODO: This is a placeholder
        secondary: faColors.pures[300],
        defaultText: faColors.pureWhite,
        subText: faColors.cools[700], //!TODO: This is a placeholder
        buttonText: faColors.pureBlack,
        accent: faColors.faYellow,
        border: faColors.darkBlue,
        darkerBorder: faColors.darkBlue,
        contentHover: faColors.pureBlackGradients[200],
        contentBackground: faColors.midBlue,
        contentHeaderBackground: faColors.lightBlue,
        contentHeaderFontColor: faColors.pureWhite,
        contentHeaderFontColorInactive: faColors.pureWhiteGradients[500],
        activeElement: faColors.faYellow,
        inactiveElement: faColors.darkBlue,
        activeSubBrand: subBrand,
        timeToThinkBackground: faColors.externalBrands.timeToThink,
        tabButtonBackground: faColors.lightBlue,
        activeTabButtonBackground: faColors.faYellow,
        activeTabButtonText: faColors.pureBlack,
        winSolidColour: faColors.results.win.solid,
        lossSolidColour: faColors.results.loss.solid,
        drawSolidColour: faColors.results.draw.solid,
        winFadedColour: faColors.results.win.faded,
        drawFadedColour: faColors.results.draw.faded,
        lossFadedColour: faColors.results.loss.faded,
        formResultMetaFontColor: faColors.pures[300],
        linkAngleRightColor: faColors.cools[400],
        betSlip: {
          background: faColors.midBlue,
          highlight: faColors.pureWhite,
          secondary: faColors.cools[800],
          clearButtonFontColor: faColors.paleBlue,
          borderBottomColor: faColors.darkBlue,
          disabledButtonBackgroundColor: faColors.results.loss.solid,
          enabledButtonBackgroundColor: faColors.results.win.solid,
          selectionBorderBottomColor: faColors.pureWhiteGradients[100],
          clearAllButtonBackgroundColor: faColors.pureWhite,
          clearAllButtonFontColor: faColors.darkBlue,
          labelPrimaryColor: faColors.betSlipLabels.default.primary,
          labelBackgroundColor: faColors.betSlipLabels.default.background,
          fastBetLabelPrimaryColor: faColors.betSlipLabels.fastBet.primary,
          fastBetLabelBackgroundColor:
            faColors.betSlipLabels.fastBet.background,
        },
        betType: {
          fontColor: faColors.pureWhite,
          backgroundColor: faColors.midBlue,
        },
        header: {
          height: 48,
          background: faColors.faYellow,
          secondary: faColors.cools[900],
          fontColor: faColors.darkBlue,
          moreMenu: {
            background: faColors.darkBlue,
            boxShadow: faColors.pureBlackGradients[400],
            iconsBorder: faColors.pureBlackGradients[200],
            iconsBackground: faColors.darkBlue,
          },
        },
        secondaryNav: {
          background: faColors.midBlue,
          desktopLinkBackground: faColors.lightBlue,
          linkBackgroundHover: faColors.faYellow,
          linkHoverText: faColors.pureBlack,
        },
        mobileNav: {
          background: faColors.cools[900],
          menuBorder: faColors.cools[800],
          menuIconFill: faColors.darkBlue,
          menuIconsBorder: faColors.pureBlackGradients[200],
        },
        matchOverview: {
          overlay: faColors.pureBlackGradients[800],
          scoreBackground: faColors.pureWhite,
          scoreText: faColors.pureBlack,
          competitionFontColor: faColors.paleBlue,
        },
        matchStat: {
          teamSubFontColor: faColors.cools[400],
          goalIconCountColor: faColors.results.win.solid,
          assistIconCountColor: faColors.pures[300],
          ownGoalIconCountColor: faColors.results.loss.solid,
          missedPenIconCountColor: faColors.results.loss.solid,
          iconCountBorderColor: faColors.cools[900],
          positiveStatColor: faColors.results.win.solid,
        },
        leagueTable: {
          winner: faColors.results.win.solid,
          loser: faColors.results.loss.solid,
          playoff: faColors.results.draw.solid,
          border: faColors.lightBlue,
        },
        author: {
          fontColor: faColors.pureWhite,
          secondaryColor: faColors.pures[300],
        },
        footer: {
          fontColor: faColors.pureWhite,
          backgroundColor: faColors.darkBlue,
          navBackgroundColor: faColors.darkBlue,
        },
        notifications: {
          backgroundColor: faColors.notifications.backgroundColor,
          fontColor: faColors.notifications.fontColor,
        },
        breadCrumbs: {
          fontColor: faColors.cools[300],
        },
        tab: {
          fontColor: faColors.pureWhite,
          activeFontColor: faColors.pureBlack,
          underlineFontColor: faColors.pureWhite,
          inactiveTabColor: faColors.lightBlue,
          activeTabColor: faColors.faYellow,
          activeTabUnderlineColor: faColors.faYellow,
          tabContainerBackgroundColor: faColors.pureWhite,
        },
        smartAccaCompetition: {
          buttonGroupBackgroundColor: faColors.midBlue,
          tableHeaderBackgroundColor: faColors.lessDarkBlue,
          tableRowFirstColumnBackgroundColor: faColors.lessDarkBlue,
          tableRowBackgroundColor: faColors.midBlue,
          tableRowBorderColor: faColors.lightendedPaleBlue,
          activeButtonBackground: faColors.faYellow,
          activeButtonFontColor: faColors.lightMidBlue,
          inactiveButtonBackground: faColors.darkBlue,
          inactiveButtonFontColor: faColors.pureWhite,
          viewMoreButtonFontColor: faColors.paleBlue,
          teamSelectionFontColor: faColors.paleBlue,
          scrollBarColor: faColors.midBlue,
          scrollBarBackgroundColor: faColors.lessDarkBlue,
        },
        smartAccaMatch: {
          primaryBackgroundColor: faColors.midBlue,
          secondaryBackgroundColor: faColors.darkBlue,
          primaryFontColor: faColors.pureWhite,
          secondaryFontColor: faColors.paleBlue,
          highProbabilityFontColor: faColors.smartAccaGreen,
          buttonBackgroundColor: faColors.pureWhite,
          activeButtonBackgroundColor: faColors.faYellow,
          scrollBarColor: faColors.midBlue,
          scrollBarBackgroundColor: faColors.darkBlue,
          borderColor: faColors.lightBlue,
          formWinColor: faColors.results.win.solid,
          formLossColor: faColors.results.loss.solid,
          formDrawColor: faColors.results.draw.solid,
        },
        progressCircle: {
          fontColor: faColors.smartAccaGreen,
        },
        suggestedSmartAcca: {
          primaryBackgroundColor: faColors.midBlue,
          secondaryBackgroundColor: faColors.darkBlue,
          primaryFontColor: faColors.pureWhite,
          secondaryFontColor: faColors.paleBlue,
          buttonBackgroundColor: faColors.pureWhite,
          activeButtonBackgroundColor: faColors.faYellow,
          scrollBarColor: faColors.midBlue,
          scrollBarBackgroundColor: faColors.darkBlue,
          borderColor: faColors.lightBlue,
        },
        ctaButton: {
          fontColor: faColors.ctaButton.fontColor,
          backgroundColor: faColors.ctaButton.backgroundColor,
          fontColorSecondary: faColors.ctaButton.fontColorSecondary,
          backgroundColorSecondary: faColors.ctaButton.backgroundColorSecondary,
        },
        cardWrapper: {
          highlight: faColors.cardWrapperHighlight.color,
        },
        tipReason: {
          borderColor: faColors.faYellow,
        },
        pagination: {
          activeBackgroundColor: faColors.lightMidBlue,
          activeFontColor: faColors.pureWhite,
        },
      };
    case "fp":
      return {
        background: fpColors.pures[100],
        primary: fpColors.flashPicksBlue[100],
        secondary: fpColors.flashPicksBlue[200],
        defaultText: fpColors.pureBlack,
        subText: fpColors.cools[300],
        buttonText: fpColors.pureWhite,
        accent: fpColors.flashPicksBlue[200],
        border: fpColors.cools[200],
        darkerBorder: fpColors.pures[200],
        contentHover: fpColors.pureBlackGradients[200],
        contentBackground: fpColors.pureWhite,
        contentHeaderBackground: fpColors.flashPicksBlue[100],
        contentHeaderFontColor: fpColors.pureWhite,
        contentHeaderFontColorInactive: fpColors.pureWhiteGradients[500],
        activeElement: fpColors.flashPicksBlue[100],
        inactiveElement: fpColors.cools[200],
        activeSubBrand: subBrand,
        widgetsBorderBottomDashed: fpColors.cools[100],
        tabButtonBackground: fpColors.cools[100],
        activeTabButtonBackground: fpColors.flashPicksBlue[100],
        activeTabButtonText: fpColors.pureWhite,
        winSolidColour: fpColors.results.win.solid,
        lossSolidColour: fpColors.results.loss.solid,
        drawSolidColour: fpColors.results.draw.solid,
        winFadedColour: fpColors.results.win.faded,
        drawFadedColour: fpColors.results.draw.faded,
        lossFadedColour: fpColors.results.loss.faded,
        formResultMetaFontColor: fpColors.cools[800],
        header: {
          height: 48,
          background: fpColors.flashPicksBlue[100],
          secondary: fpColors.cools[300],
          tertiary: fpColors.flashPicksBlue[200],
          fontColor: fpColors.pureWhite,
          moreMenu: {
            background: fpColors.flashPicksBlue[100],
            boxShadow: fpColors.pureBlackGradients[400],
            iconsBorder: fpColors.pureWhiteGradients[300],
            iconsBackground: fpColors.flashPicksBlue[200],
          },
        },
        betSlip: {
          background: fpColors.flashPicksBlue[300],
          highlight: fpColors.pureWhite,
          secondary: fpColors.cools[800],
          clearButtonFontColor: fpColors.pureWhite,
          borderBottomColor: fpColors.pureWhiteGradients[200],
          disabledButtonBackgroundColor: fpColors.results.loss.solid,
          enabledButtonBackgroundColor: fpColors.results.win.solid,
          selectionBorderBottomColor: fpColors.pureWhiteGradients[100],
          clearAllButtonBackgroundColor: fpColors.pureWhite,
          clearAllButtonFontColor: fpColors.pureBlack,
          labelPrimaryColor: fpColors.betSlipLabels.default.primary,
          labelBackgroundColor: fpColors.betSlipLabels.default.background,
          fastBetLabelPrimaryColor: fpColors.betSlipLabels.fastBet.primary,
          fastBetLabelBackgroundColor:
            fpColors.betSlipLabels.fastBet.background,
        },
        betType: {
          fontColor: fpColors.pureWhite,
          backgroundColor: fpColors.flashPicksBlue[300],
        },
        secondaryNav: {
          background: fpColors.flashPicksBlue[200],
          desktopLinkBackground: fpColors.flashPicksBlue[100],
          linkBackgroundHover: fpColors.flashPicksYellow,
          linkHoverText: fpColors.pureBlack,
        },
        mobileNav: {
          background: fpColors.flashPicksBlue[100],
          menuBorder: fpColors.pureWhiteGradients[200],
          menuIconFill: fpColors.cools[300],
          menuIconsBorder: fpColors.pureWhiteGradients[200],
        },
        matchOverview: {
          overlay: fpColors.pureBlackGradients[800],
          scoreBackground: fpColors.pureWhite,
          scoreText: fpColors.pureBlack,
          competitionFontColor: fpColors.pureBlack,
        },
        matchStat: {
          teamSubFontColor: fpColors.cools[400],
          goalIconCountColor: fpColors.results.win.solid,
          assistIconCountColor: fpColors.pures[300],
          ownGoalIconCountColor: fpColors.results.loss.solid,
          missedPenIconCountColor: fpColors.results.loss.solid,
          iconCountBorderColor: fpColors.cools[900],
          positiveStatColor: fpColors.results.win.solid,
        },
        author: {
          fontColor: fpColors.pureBlack,
          secondaryColor: fpColors.pures[500],
        },
        footer: {
          fontColor: fpColors.pureWhite,
          backgroundColor: fpColors.flashPicksBlue[200],
          navBackgroundColor: fpColors.pureBlackGradients[100],
        },
        notifications: {
          backgroundColor: fpColors.notifications.backgroundColor,
          fontColor: fpColors.notifications.fontColor,
        },
        breadCrumbs: {
          fontColor: fpColors.cools[700],
        },
        tab: {
          fontColor: fpColors.pureBlack,
          activeFontColor: fpColors.pureWhite,
          inactiveTabColor: fpColors.cools[100],
          underlineFontColor: fpColors.pureWhite,
          activeTabColor: fpColors.flashPicksYellow,
          activeTabUnderlineColor: fpColors.flashPicksYellow,
          tabContainerBackgroundColor: fpColors.pureWhite,
        },
        ctaButton: {
          fontColor: fpColors.ctaButton.fontColor,
          backgroundColor: fpColors.ctaButton.backgroundColor,
          fontColorSecondary: fpColors.ctaButton.fontColorSecondary,
          backgroundColorSecondary: fpColors.ctaButton.backgroundColorSecondary,
        },
        cardWrapper: {
          highlight: fpColors.cardWrapperHighlight.color,
        },
        tipReason: {
          borderColor: fpColors.flashPicksBlue[100],
        },
        pagination: {
          activeBackgroundColor: fpColors.flashPicksBlue[100],
          activeFontColor: fpColors.pureWhite,
        },
        smartAccaCompetition: {
          buttonGroupBackgroundColor: fpColors.flashPicksBlue[200],
          tableHeaderBackgroundColor: fpColors.lessDarkGrey,
          tableRowBackgroundColor: fpColors.pureWhite,
          tableRowFirstColumnBackgroundColor: fpColors.veryLightGrey,
          tableRowBorderColor: fpColors.pures[200],
          activeButtonBackground: fpColors.flashPicksYellow,
          activeButtonFontColor: fpColors.pureBlack,
          inactiveButtonBackground: fpColors.flashPicksBlue[100],
          inactiveButtonFontColor: fpColors.pureWhite,
          viewMoreButtonFontColor: fpColors.pures[400],
          teamSelectionFontColor: fpColors.paleBlue,
          scrollBarColor: fpColors.veryLightGrey,
          scrollBarBackgroundColor: fpColors.flashPicksBlue[200],
        },
        smartAccaMatch: {
          primaryBackgroundColor: fpColors.flashPicksBlue[300],
          secondaryBackgroundColor: fpColors.flashPicksBlue[100],
          primaryFontColor: fpColors.pureWhite,
          secondaryFontColor: fpColors.cools[200],
          highProbabilityFontColor: fpColors.smartAccaGreen,
          buttonBackgroundColor: fpColors.pureWhite,
          activeButtonBackgroundColor: fpColors.flashPicksYellow,
          scrollBarColor: fpColors.flashPicksBlue[300],
          scrollBarBackgroundColor: fpColors.flashPicksBlue[100],
          borderColor: fpColors.flashPicksBlue[200],
          formWinColor: fpColors.results.win.solid,
          formLossColor: fpColors.results.loss.solid,
          formDrawColor: fpColors.results.draw.solid,
        },
        suggestedSmartAcca: {
          primaryBackgroundColor: fpColors.flashPicksBlue[300],
          secondaryBackgroundColor: fpColors.flashPicksBlue[100],
          primaryFontColor: fpColors.pureWhite,
          secondaryFontColor: fpColors.cools[200],
          buttonBackgroundColor: fpColors.pureWhite,
          activeButtonBackgroundColor: fpColors.flashPicksYellow,
          scrollBarColor: fpColors.flashPicksBlue[300],
          scrollBarBackgroundColor: fpColors.flashPicksBlue[100],
          borderColor: fpColors.flashPicksBlue[200],
        },
        progressCircle: {
          fontColor: fpColors.smartAccaGreen,
        },
      };
    case "twe":
      return {
        background: tweColors.pures[100],
        primary: tweColors.tweGreen[300],
        secondary: tweColors.pures[200],
        defaultText: tweColors.cools[700],
        subText: tweColors.cools[700],
        buttonText: tweColors.pureWhite,
        accent: tweColors.tweGreen[300],
        border: tweColors.pures[200],
        darkerBorder: tweColors.cools[300],
        contentHover: tweColors.pureBlackGradients[200],
        contentBackground: tweColors.pureWhite,
        contentHeaderBackground: tweColors.tweGreen[400],
        contentHeaderFontColor: tweColors.pureWhite,
        contentHeaderFontColorInactive: tweColors.pureWhiteGradients[500],
        activeElement: tweColors.tweGreen[400],
        inactiveElement: tweColors.pures[200],
        activeSubBrand: subBrand,
        "cheltenham-festival": {
          primary: tweColors.tweGold,
          headingText: tweColors.pureWhite,
        },
        timeToThinkBackground: tweColors.externalBrands.timeToThink,
        widgetsBorderBottomDashed: tweColors.cools[200],
        activeTabButtonBackground: tweColors.tweGreen[400],
        activeTabButtonText: tweColors.pureWhite,
        tabButtonBackground: tweColors.cools[100],
        header: {
          height: 48,
          background: tweColors.tweGreen[300],
          secondary: tweColors.pureWhite,
          tertiary: tweColors.tweGreen[100],
          fontColor: tweColors.pureWhite,
          moreMenu: {
            background: tweColors.tweGreen[100],
            boxShadow: "transparent",
            iconsBorder: tweColors.pureWhiteGradients[200],
            iconsBackground: tweColors.tweGreen[200],
          },
        },
        secondaryNav: {
          background: tweColors.tweGreen[200],
          desktopLinkBackground: tweColors.tweGreen[100],
          linkBackgroundHover: tweColors.tweGold,
          linkHoverText: tweColors.pureBlack,
        },
        mobileNav: {
          background: tweColors.tweGreen[300],
          menuBorder: tweColors.tweGreen[500],
          menuIconFill: tweColors.pureWhite,
          menuIconsBorder: tweColors.tweGreen[500],
        },
        author: {
          fontColor: tweColors.cools[700],
          secondaryColor: tweColors.cools[700],
        },
        footer: {
          fontColor: tweColors.pureWhite,
          backgroundColor: tweColors.tweGreen[400],
          navBackgroundColor: tweColors.pureBlackGradients[100],
        },
        meetingGrid: {
          hover: tweColors.tweGreen[300],
        },
        breadCrumbs: {
          fontColor: tweColors.cools[700],
        },
        ctaButton: {
          fontColor: tweColors.ctaButton.fontColor,
          backgroundColor: tweColors.ctaButton.backgroundColor,
          fontColorSecondary: tweColors.ctaButton.fontColorSecondary,
          backgroundColorSecondary:
            tweColors.ctaButton.backgroundColorSecondary,
        },
        cardWrapper: {
          highlight: tweColors.cardWrapperHighlight.color,
        },
        tipReason: {
          borderColor: tweColors.tweGreen[300],
        },
        pagination: {
          activeBackgroundColor: tweColors.pures[200],
          activeFontColor: tweColors.cools[700],
        },
      };
    case "ppc1":
      return {
        background: ppc1Colors.white.a,
        primary: ppc1Colors.green.a,
        secondary: ppc1Colors.white.b,
        defaultText: ppc1Colors.grey.a,
        subText: ppc1Colors.grey.a,
        buttonText: ppc1Colors.white.c,
        accent: ppc1Colors.green.b,
        border: ppc1Colors.grey.b,
        darkerBorder: ppc1Colors.grey.e,
        contentHover: ppc1Colors.grey.c,
        contentBackground: ppc1Colors.white.c,
        contentHeaderBackground: ppc1Colors.green.c,
        contentHeaderFontColor: ppc1Colors.white.c,
        contentHeaderFontColorInactive: ppc1Colors.white.d,
        activeElement: ppc1Colors.green.c,
        inactiveElement: ppc1Colors.grey.d,
        widgetsBorderBottomDashed: ppc1Colors.grey.f,
        tabButtonBackground: ppc1Colors.grey.g,
        header: {
          height: 48,
          background: ppc1Colors.green.c,
          secondary: ppc1Colors.white.c,
          fontColor: ppc1Colors.white.c,
          moreMenu: {
            iconsBorder: cmColors.pureBlackGradients[100],
          },
        },
        author: {
          fontColor: ppc1Colors.black.a,
          secondaryColor: ppc1Colors.grey.a,
        },
        footer: {
          height: 76,
          fontColor: ppc1Colors.green.c,
          backgroundColor: ppc1Colors.green.d,
          navBackgroundColor: ppc1Colors.green.d,
        },
        breadCrumbs: {
          fontColor: ppc1Colors.cools[700],
        },
      };
    case "cm":
      return {
        background: cmColors.white.a,
        primary: cmColors.blue.a,
        secondary: cmColors.white.b,
        accent: cmColors.blue.a,
        headerHeight: 95,
        defaultText: cmColors.black.a,
        secondaryText: cmColors.grey.a,
        darkBackground: cmColors.grey.b,
        border: cmColors.white.b,
        darkerBorder: cmColors.black.b,
        contentHover: cmColors.grey.c,
        contentBackground: cmColors.white.c,
        contentHeaderBackground: cmColors.black.b,
        contentHeaderFontColor: cmColors.white.c,
        contentHeaderFontColorInactive: cmColors.white.d,
        activeElement: cmColors.black.b,
        inactiveElement: cmColors.grey.d,
        widgetsBorderBottomDashed: cmColors.grey.g,
        tabButtonBackground: cmColors.grey.g,
        header: {
          background: cmColors.grey.e,
          secondary: cmColors.white.c,
          fontColor: cmColors.white.c,
          moreMenu: {
            iconsBorder: cmColors.pureBlackGradients[100],
          },
        },
        mobileNav: {
          background: cmColors.black.c,
          menuBorder: cmColors.grey.f,
        },
        author: {
          fontColor: cmColors.black.a,
          secondaryColor: cmColors.grey.b,
        },
        footer: {
          fontColor: cmColors.white.c,
          backgroundColor: cmColors.grey.b,
        },
        breadCrumbs: {
          fontColor: cmColors.cools[700],
        },
      };
    case "oc":
      return {
        background: ocColors.pures[100],
        primary: ocColors.pureBlack,
        secondary: ocColors.pures[200],
        defaultText: ocColors.cools[700],
        buttonText: ocColors.pureWhite,
        accent: ocColors.ocOrange,
        border: ocColors.pures[100],
        darkerBorder: ocColors.pures[200],
        contentHover: ocColors.pureBlackGradients[200],
        contentBackground: ocColors.pureWhite,
        contentHeaderBackground: ocColors.pureBlack,
        contentHeaderFontColor: ocColors.pureWhite,
        contentHeaderFontColorInactive: ocColors.pureWhiteGradients[500],
        activeElement: ocColors.ocOrange,
        inactiveElement: ocColors.pures[200],
        timeToThinkBackground: ocColors.externalBrands.timeToThink,
        widgetsBorderBottomDashed: ocColors.cools[200],
        tabButtonBackground: ocColors.cools[100],
        winSolidColour: faColors.results.win.solid,
        lossSolidColour: faColors.results.loss.solid,
        drawSolidColour: faColors.results.draw.solid,
        winFadedColour: faColors.results.win.faded,
        drawFadedColour: faColors.results.draw.faded,
        lossFadedColour: faColors.results.loss.faded,
        header: {
          height: 65,
          background: ocColors.pureBlack,
          secondary: ocColors.pures[300],
          tertiary: ocColors.pureWhiteGradients[100],
          fontColor: ocColors.pureWhite,
          moreMenu: {
            background: ocColors.ocBlacks[200],
            boxShadow: "transparent",
            iconsBorder: ocColors.pureWhiteGradients[200],
            iconsBackground: ocColors.ocBlacks[100],
          },
        },
        secondaryNav: {
          background: ocColors.ocBlacks[100],
          desktopLinkBackground: ocColors.ocBlacks[200],
          linkBackgroundHover: ocColors.ocOrange,
          linkHoverText: ocColors.pureBlack,
        },
        mobileNav: {
          background: ocColors.pureBlack,
          menuBorder: ocColors.ocBlacks[200],
          menuIconFill: ocColors.pures[300],
          menuIconsBorder: ocColors.ocBlacks[200],
        },
        author: {
          fontColor: ocColors.pures[300],
          secondaryColor: ocColors.pures[300],
        },
        footer: {
          fontColor: ocColors.pureWhite,
          backgroundColor: ocColors.pureBlack,
          navBackgroundColor: ocColors.ocBlacks[200],
        },
        breadCrumbs: {
          fontColor: ocColors.cools[700],
        },
      };
    default:
      return null;
  }
};

export default themeSelector;
