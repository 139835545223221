import { getActiveBrand } from "../../brand/brand";
import {
  BASE_API_URL,
  BASE_ODDS_PLATFORM_API_URL,
  ODDS_PLATFORM_SUCCESSFUL_RESPONSE,
} from "../../../constants/api";
import { type SitemapResponse } from "./types";

type QueryParams = Record<string, string | number | boolean | undefined | null>;

interface GetDataFromPathAndQueryParamsProps {
  path: string;
  queryParams?: QueryParams;
  disableBrand?: boolean;
  isOddsPlatformRequest?: boolean;
  authToken?: string;
}

export const queryParamsToString = (
  object: QueryParams
): Record<string, string> => {
  const res: Record<string, string> = {};

  for (const [key, value] of Object.entries(object)) {
    if (value !== null && value !== undefined) {
      res[key] = String(value);
    }
  }

  return res;
};

type Valuable<T> = {
  [K in keyof T as T[K] extends null | undefined ? never : K]: T[K];
};

export function removeUndefinedValuesForSearchParams<
  // eslint-disable-next-line @typescript-eslint/ban-types -- https://gist.github.com/miZyind/503c5330016f72c1a0517d3ec0903676
  T extends {},
  V = Valuable<T>
>(obj: T): V {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([, v]) =>
        !(
          (typeof v === "string" && !v.length) ||
          v === null ||
          typeof v === "undefined"
        )
    )
  ) as V;
}

interface ResponseWithMessage extends Response {
  message?: string;
}

export const getResponseMessage = async (
  response: Response
): Promise<string> => {
  const res = (await response.json()) as ResponseWithMessage;

  const message = res.message ? res.message : "Unknown API Error";

  return message;
};

export const getDataFromPathAndQueryParams = async <ResponseType>({
  path,
  queryParams,
  disableBrand = false,
  isOddsPlatformRequest = false,
  authToken,
}: GetDataFromPathAndQueryParamsProps): Promise<ResponseType> => {
  const brand = getActiveBrand();
  let url: URL;

  if (isOddsPlatformRequest) {
    url = new URL(`${BASE_ODDS_PLATFORM_API_URL}${path}`);
  } else if (disableBrand) {
    url = new URL(`${BASE_API_URL}${path}`);
  } else {
    url = new URL(`${BASE_API_URL}brand/${brand}/${path}`);
  }

  if (queryParams) {
    const searchParams = new URLSearchParams(
      removeUndefinedValuesForSearchParams(queryParams)
    );

    url.search = searchParams.toString();
  }

  const headers = authToken
    ? {
        Authorization: `Bearer ${authToken}`,
      }
    : undefined;

  try {
    const res = await fetch(url.toString(), { method: "GET", headers });

    if (res.status !== 200) {
      const errorMessage = await getResponseMessage(res);

      // Logging 404's overloads our logging service and is not useful
      if (res.status !== 404) {
        console.error({
          errorMessage,
          status: res.status,
          statusText: res.statusText,
          path,
          url: res.url,
        });
      }

      return { message: "Not found", data: {} } as ResponseType;
    }

    return res.json() as ResponseType;
  } catch (e) {
    console.error(e);
    return { message: "Unknown error", data: {} } as ResponseType;
  }
};

export const notFound = (): { notFound: true } => {
  return { notFound: true };
};

export const isSuccessfulOddsPlatformResponse = (
  oddsPlatformResponseCode: number
) => {
  return oddsPlatformResponseCode === ODDS_PLATFORM_SUCCESSFUL_RESPONSE;
};

export const getSitemapPaths = async ({
  rootPath,
  excludeRootPath,
}: {
  rootPath?: string;
  excludeRootPath?: string;
}): Promise<SitemapResponse> => {
  const path = `sitemap`;
  const queryParams = { rootPath, excludeRootPath };
  return getDataFromPathAndQueryParams({ path, queryParams });
};

export const checkIfPathStartsWithLocale = (
  path: string,
  contentLocale: string
): boolean => {
  // here's a breakdown of the below regex:
  // ^ - start of string
  // (${contentLocale}|/${contentLocale})$ - a capture group containing the content locale with or without a leading slash, followed by the end of the string
  // (${contentLocale}|/${contentLocale}) - a capture group containing the content locale or with or without a leading slash followed by the content locale
  // (/.*) - a capture group containing a slash followed by any number of characters
  const regex = new RegExp(
    `^(/${contentLocale}|${contentLocale})$|(${contentLocale}|/${contentLocale})(/.*)`
  );
  return regex.test(path);
};

export const getFullUrlPathLength = (fullUrlPath: string): number => {
  const fullUrlPathWithLeadingSlash = fullUrlPath.startsWith("/")
    ? fullUrlPath
    : `/${fullUrlPath}`;

  const splitFullUrlPath = fullUrlPathWithLeadingSlash
    .split("/")
    .filter(Boolean);

  return splitFullUrlPath.length;
};

export type SitemapChangeFreq = "hourly" | "daily" | "monthly";

export const getSitemapChangeFrequency = (
  fullUrlPath: string,
  frequencyMap: Map<number, SitemapChangeFreq>,
  contentLocale?: string
): SitemapChangeFreq => {
  const path =
    contentLocale && checkIfPathStartsWithLocale(fullUrlPath, contentLocale)
      ? fullUrlPath.replace(`${contentLocale}`, "")
      : fullUrlPath;
  const pathLength = getFullUrlPathLength(path);

  return frequencyMap.get(pathLength) || "monthly";
};

export type SitemapPriority = 1 | 0.7 | 0.5 | 0.4;

export const getSitemapPriority = (
  fullUrlPath: string,
  contentLocale?: string
): SitemapPriority => {
  const path =
    contentLocale && checkIfPathStartsWithLocale(fullUrlPath, contentLocale)
      ? fullUrlPath.replace(`${contentLocale}`, "")
      : fullUrlPath;
  const pathLength = getFullUrlPathLength(path);
  const priorityByPathLength = new Map<number, SitemapPriority>([
    [0, 1.0],
    [1, 0.7],
    [2, 0.5],
  ]);

  return priorityByPathLength.get(pathLength) || 0.4;
};
