import styles from "./CtaButton.module.scss";

interface CtaButtonProps {
  title: string;
  clickHandler: () => void;
  isSecondary?: boolean;
}

export function CtaButton({
  title,
  clickHandler,
  isSecondary = false,
}: CtaButtonProps): React.JSX.Element {
  return (
    <button
      className={styles[`cta-button${isSecondary ? "--secondary" : ""}`.trim()]}
      onClick={() => {
        clickHandler();
      }}
      type="button"
    >
      {title}
    </button>
  );
}
