import { neutrals } from "./crossBrand";
// Rather than trying to come up with color names we're just giving
// setting the key as the next character in the alphabet

export const ppc1Colors = {
  ...neutrals,
  white: {
    a: "#efeef1",
    b: "#fefefe",
    c: "rgba(255,255,255,1)",
    d: "rgba(255,255,255,0.5)",
  },
  green: {
    a: "#4CAF50",
    b: "#4caf4f",
    c: "rgb(76,175,80)",
    d: "rgb(53, 120, 55)",
    e: "rgb(68, 219, 94)",
    f: "rgb(80, 174, 86)",
  },
  grey: {
    a: "#525252",
    b: "rgb(228,232,237)",
    c: "rgba(0,0,0,0.2)",
    d: "rgb(235, 236, 245)",
    e: "rgb(202, 207, 217)",
    f: "rgb(226, 226, 226)",
    g: "rgb(246, 246, 246)",
    h: "rgb(164, 164, 168)",
    i: "rgb(30, 42, 58)",
  },
  black: {
    a: "rgba(0, 0, 0, 0.1)",
  },
  red: {
    a: "rgb(231, 32, 53)",
  },
  yellow: {
    a: "rgb(239, 229, 46)",
  },
  blue: {
    a: "rgb(182, 203, 216)",
  },
};
