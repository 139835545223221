import type { RawDraftContentState } from "draft-js";
import { parseString } from "../string/string";
import type { ContentBlock, ContentWidgetsArray } from "../../types";
import { getTweet } from "../draftManager/blocks/atomic:twitter";
import { contentToHtml } from "../draftManager/draftManager";

export const generateFormattedEditorContent = async (
  widgets: ContentWidgetsArray
): Promise<ContentWidgetsArray> => {
  const allWidgets = widgets;
  await Promise.all(
    allWidgets.map(async (widget) => {
      // Check if the widget is an Editor widget with an editor_state
      if (widget.component === "Editor" && widget.editor_state) {
        // parse the editor state to get the editor blocks
        const context = parseString(widget.editor_state) as ContentBlock;
        // We map through all of the blocks in the editor state
        // and use the contentToHtml function to convert the blocks in to html strings
        const blocks = await Promise.all(
          context.blocks.map(async (block) => {
            // If the block has a type of "atomic:twitter", we fetch the tweet and return the html from the api
            if (block.type === "atomic:twitter") {
              const { metadata } = block.data?.twitter ?? {};

              let tweetUrl = "";
              if (metadata?.url) {
                tweetUrl = metadata.url;
              } else {
                // We need to replace part of the tweet url with the screenname of the account the tweet was posted by
                // Example
                // copied from twitter: https://twitter.com/i/web/status/1522172714953416706
                // value after replacing i/web: https://twitter.com/FootyAccums/status/1522172714953416706

                // Check that the required properties are present in the metadata
                // If not we will return the block as is resulting in now formattedContent for this widget
                // Which will result the widget not being rendered
                if (
                  !metadata?.entities?.urls?.length ||
                  !metadata.user?.screen_name
                )
                  return block;
                tweetUrl = metadata.entities.urls[0].expanded_url.replace(
                  "i/web",
                  metadata.user.screen_name
                );
              }

              const tweet = tweetUrl ? await getTweet(tweetUrl) : "";
              if (tweet) {
                block.text = tweet;
              }
            }
            return block;
          })
        );
        const newContext = { ...context, blocks };
        // We convert the editor state to HTML
        const convertBlocksToHtml = contentToHtml(
          newContext as RawDraftContentState
        );
        // assign the converted HTML to the widget
        widget.formattedContent = convertBlocksToHtml;
      }
    })
  );
  return allWidgets;
};

export const isLocaleInUs = (locale: string): boolean => {
  return locale.toLowerCase().startsWith("us-");
};

export const selectCurrencySymbol = (locale: string): string => {
  if (isLocaleInUs(locale)) {
    return "$";
  }
  return "£";
};
