import media from "@/lib/draftManager/blocks/atomic:media";
import { Entity } from "@/types/draftManager";
import script from "@/lib/draftManager/blocks/atomic:script";
import youtube from "@/lib/draftManager/blocks/atomic:youtube";
import twitter from "@/lib/draftManager/blocks/atomic:twitter";
import giphy from "@/lib/draftManager/blocks/atomic:giphy";

export default function atomic(entity: Entity): JSX.Element | null {
  switch (entity.type) {
    case "atomic:media":
      return media(entity);
    case "atomic:script":
      return script(entity);
    case "atomic:youtube":
      return youtube(entity);
    case "atomic:twitter":
      return twitter(entity);
    case "atomic:giphy":
      return giphy(entity);
    default:
      return null;
  }
}
