import { ReactElement, useEffect, useState } from "react";
import { LocalisedThemeSwitchText } from "@/types/staticTranslations";
import styles from "./ToggleSwitch.module.scss";

interface ToggleProps {
  colourMode: string;
  // eslint-disable-next-line no-unused-vars
  handleThemeChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  translations: LocalisedThemeSwitchText;
}

export default function ToggleSwitch({
  colourMode,
  translations,
  handleThemeChange,
}: ToggleProps): ReactElement {
  const [isDarkMode, setIsDarkMode] = useState(colourMode === "dark");

  useEffect(() => {
    setIsDarkMode(colourMode === "dark");
  }, [colourMode]);

  return (
    <>
      <h4 className={styles.toggleHeading}>{translations.heading}</h4>
      <div className={styles.toggleSwitchContainer}>
        <span>{translations.light}</span>
        <input
          className={styles.toggleSwitch}
          type="checkbox"
          data-testid="toggleSwitch"
          id="toggle-switch"
          aria-checked={isDarkMode}
          checked={isDarkMode}
          onChange={handleThemeChange}
        />
        <label className={styles.toggleSwitchLabel} htmlFor="toggle-switch">
          Toggle
        </label>
        <span>{translations.dark}</span>
      </div>
    </>
  );
}
