import dayjs, { extend } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { getActiveBrand } from "../brand/brand";
import { FP_BRAND } from "../../constants";

extend(relativeTime);
extend(utc);
extend(timezone);
extend(advancedFormat);

export const getCurrentUnixTimestampMs = (): number => dayjs().valueOf();

export const unixTimestampToDateMonthYearHourAndMins = (
  date: number
): string => {
  const brand = getActiveBrand();
  const timeFormat = brand !== FP_BRAND ? "HH:mm" : "hh:mmA";
  return dayjs(date).format(`DD-MM-YYYY ${timeFormat}`);
};

export const fullDateToShortDateWithAMorPM = (date: string): string => {
  const brand = getActiveBrand();
  const timeFormat = brand !== FP_BRAND ? "H:mm" : "h:mmA";
  return dayjs(date).format(`ddd Do MMM ${timeFormat}`);
};

export interface SplitShortDate {
  writtenDate: string;
  numericalDate: string;
  time: string;
}

export const fullDateToSplitShortDate = (fullDate: string): SplitShortDate => {
  const writtenDate = dayjs(fullDate).format("ddd DD MMM");
  const numericalDate = dayjs(fullDate).format("DD/MM/YY");
  const time = dayjs(fullDate).format("HH:mm");

  return {
    writtenDate,
    numericalDate,
    time,
  };
};

export const fullDateToFullWrittenDate = (fullDate: string) =>
  dayjs(fullDate).format("dddd Do MMMM");

export const isoDateStringToFullHyphenatedDate = (isoDateString: string) => {
  return dayjs(isoDateString).format("YYYY-MM-DD");
};

export const millisecondsToIsoDateString = (date: number) => {
  return new Date(date).toISOString();
};
