import type {
  Last5Results,
  PredictionResult,
} from "../../../types/api/predictions";
import type { BrandShortNames } from "../../../types";
import type {
  BetBuilderBrunoEventData,
  BetBuilderEventData,
  Bookmaker,
  BookmakerForTip,
  Events,
  GridData,
  MatchData,
  StatsTipsterStats,
} from "../../../types/api/tipster";
import { fpCompetitionSportsMap } from "../../../constants/competitions";
import type { TipOdds } from "../../../types/api/odds";
import { gaEvent } from "../../../utils/gtag/gtag";

export const validTipsterSignUpLink = (
  brand: BrandShortNames,
  bookmakerData: BookmakerForTip | undefined,
  locale: string
): string | undefined => {
  // Immediately return if no bookmaker data
  if (!bookmakerData) return undefined;

  // Check there are links
  const links =
    bookmakerData.links && bookmakerData.links.length > 0
      ? bookmakerData.links[0]
      : undefined;

  // Return if no links
  if (!links) return undefined;

  // Check for localised links
  const localisedLinks = links[`${brand}_locations`];

  // Return the localised link if it exists
  if (localisedLinks?.[locale]) {
    return localisedLinks[locale];
  }

  // Return the default link if it exists
  return links[brand] ?? undefined;
};

export const normalizeToDecimal = (decimalValue: string | number): number => {
  return typeof decimalValue === "string"
    ? parseFloat(decimalValue)
    : decimalValue;
};

export const getBestOddsFromBookmakers = (
  bookmakers: Bookmaker[]
): Bookmaker[] =>
  bookmakers.slice().sort(
    (a, b) =>
      // Descending order
      normalizeToDecimal(b.oddsDecimal) - normalizeToDecimal(a.oddsDecimal)
  );

interface CreateTeamDetailsProps {
  match: MatchData | BetBuilderBrunoEventData;
  competition?: string;
  form?: Last5Results;
}

export const createTeamDetails = ({
  match,
  competition,
  form,
}: CreateTeamDetailsProps): {
  name: string;
  badge: string;
  form: PredictionResult[] | undefined;
}[] => {
  const {
    team_a_badge: teamABadge,
    team_a_name: teamAName,
    team_b_badge: teamBBadge,
    team_b_name: teamBName,
  } = match;

  if (competition && fpCompetitionSportsMap.has(competition)) {
    return [
      {
        name: teamBName,
        badge: teamBBadge,
        form: form?.away,
      },
      {
        name: teamAName,
        badge: teamABadge,
        form: form?.home,
      },
    ];
  }

  return [
    {
      name: teamAName,
      badge: teamABadge,
      form: form?.home,
    },
    {
      name: teamBName,
      badge: teamBBadge,
      form: form?.away,
    },
  ];
};

export const isValidStats = (
  statsTipsterStats: StatsTipsterStats,
  statsForGame: Events
): boolean => {
  return statsTipsterStats.type !== "no-stats" && statsForGame.stats.length > 1;
};

export const bookmakersToDisplay = (
  odds: TipOdds[],
  viewMore: boolean,
  isDesktop: boolean
): TipOdds[] => {
  if (viewMore) return odds;

  if (!isDesktop) return odds.slice(0, 2);

  return odds.slice(0, 4);
};

interface GaOfferPressedEventParams {
  bookmaker?: string;
  competition?: string;
  event?: string;
  tipster: string;
  bettingTip: string;
}

export const betBuilderTipsterGaEventParamsFromTip = ({
  event,
  grid,
  title,
  isPoweredByBruno,
}: {
  event: BetBuilderEventData;
  grid: GridData[];
  title: string;
  isPoweredByBruno: boolean;
}) => {
  let competition;
  let bettingEvent;

  if (isPoweredByBruno && "competition_name" in event) {
    competition = event.competition_name;
  } else if (!isPoweredByBruno && "competition" in event) {
    competition = event.competition;
  }

  if (isPoweredByBruno && "team_a_name" in event) {
    bettingEvent = `${event.team_a_name} vs ${event.team_b_name}`;
  } else if (!isPoweredByBruno && "fixture" in event) {
    bettingEvent = event.fixture;
  }

  return {
    competition: competition ?? "",
    event: bettingEvent ?? "",
    tipster: title,
    bettingTip: grid.map(({ selection }) => selection).join(),
  };
};

export const statsTipsterGaEventParamsFromTip = ({
  events,
  title,
}: {
  events: Events[];
  title: string;
}) => {
  const { competition, eventDescription, bettingTip } = events.reduce<{
    competition: string[];
    eventDescription: string[];
    bettingTip: string[];
  }>(
    (
      acc,
      {
        event: {
          meta: {
            competition: { name: competitionName },
          },
          description,
        },
        market,
        selection,
      }
    ) => {
      if (competitionName) acc.competition.push(competitionName);
      acc.eventDescription.push(description);
      acc.bettingTip.push(`${market.name} - ${selection.name}`);
      return acc;
    },
    { competition: [], eventDescription: [], bettingTip: [] }
  );

  return {
    competition: competition.join(),
    event: eventDescription.join(),
    tipster: title,
    bettingTip: bettingTip.join(),
  };
};

export const gaOfferPressedEvent = ({
  bookmaker,
  competition,
  event,
  tipster,
  bettingTip,
}: GaOfferPressedEventParams) => {
  const gaEventParams = {
    bookmaker: bookmaker ?? "",
    category: "betting_tip_widget",
    bet_competition: competition ?? "",
    bet_event: event ?? "",
    tipster,
    betting_tip: bettingTip,
  };

  gaEvent("offer_pressed", gaEventParams);
};
