import type { Dispatch, PropsWithChildren } from "react";
import {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { FA_BRAND, FP_BRAND } from "../constants";
import { ODDS_FORMAT_COOKIE_NAME } from "../constants/odds";
import {
  createCookieString,
  getCookieValueByName,
} from "../utils/cookie/cookie";
import { betSlipReducer, gaAddToBetSlipEvent } from "../utils/betslip/betslip";
import type { OddsFormats } from "../types/odds";
import { getOddsByCommonAndSelectionIdPairs } from "../utils/http/odds";
import { getActiveBrand } from "../utils/brand/brand";
import { useLocaleFromRouter } from "../hooks/LocationFromRouter";
import { getDefaultFormattedOdds } from "../utils/odds/odds";
import type { BetSlipActions, BetSlipState } from "../types/betslip";

// Two separate providers created to avoid performance concerns, as described in this [article](https://hswolff.com/blog/how-to-usecontext-with-usereducer/)
export const BetSlipDispatchContext =
  createContext<Dispatch<BetSlipActions> | null>(null);
export const BetSlipStateContext = createContext<BetSlipState | null>(null);

export const initialBetSlipState: BetSlipState = {
  isOpen: false,
  activeCommonAndEventIdPairs: [],
  selectionsDataAndState: [],
  bookmakers: [],
  portalBookmaker: null,
  betAddedSource: null,
  oddsChanged: false,
  oddsFormat: getDefaultFormattedOdds(),
  bestOdds: {
    odds: "",
    oddsAmerican: "",
    oddsDecimal: 0,
  },
};

export const retrieveLocalStorageBetSlipSelections =
  (): Promise<BetSlipState> =>
    Promise.resolve().then(() => {
      const storedSelections = localStorage.getItem("betSlipSelections");

      if (storedSelections) {
        const { activeCommonAndEventIdPairs } = JSON.parse(
          storedSelections
        ) as BetSlipState;

        initialBetSlipState.activeCommonAndEventIdPairs = Array.isArray(
          activeCommonAndEventIdPairs
        )
          ? activeCommonAndEventIdPairs
          : [];
      }

      return initialBetSlipState;
    });

function BetSlipProvider({ children }: PropsWithChildren): React.JSX.Element {
  const [
    {
      activeCommonAndEventIdPairs,
      isOpen,
      selectionsDataAndState,
      bookmakers,
      betAddedSource,
      portalBookmaker,
      oddsChanged,
      oddsFormat,
      bestOdds,
    },
    dispatch,
  ] = useReducer(betSlipReducer, initialBetSlipState);
  const [locale] = useLocaleFromRouter();
  const brand = getActiveBrand();

  const [hasRetrievedStoredSelections, setHasRetrievedStoredSelections] =
    useState(false);

  const waitForLocalStorage = useCallback(async () => {
    const { activeCommonAndEventIdPairs: activePairsFromLocalStorage } =
      await retrieveLocalStorageBetSlipSelections();

    if (activePairsFromLocalStorage.length > 0) {
      if (brand === FA_BRAND) {
        dispatch({
          type: "ADD_ACCA",
          commonAndSelectionIdPairsArray: activePairsFromLocalStorage,
          source: "localStorage",
        });
      }
      // We only want to update local storage for FP
      if (brand === FP_BRAND) {
        localStorage.setItem(
          "betSlipSelections",
          JSON.stringify({
            activeCommonAndEventIdPairs: activePairsFromLocalStorage,
          })
        );
      }
    }
  }, [brand]);

  useEffect(() => {
    const oddsFormatCookie = getCookieValueByName(
      ODDS_FORMAT_COOKIE_NAME
    ) as OddsFormats;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Require conditional because of typecasting above
    if (!oddsFormatCookie) {
      document.cookie = createCookieString({
        name: ODDS_FORMAT_COOKIE_NAME,
        value: oddsFormat,
      });
      dispatch({
        type: "SET_ODDS_FORMAT",
        oddsFormat,
      });
    } else if (oddsFormatCookie !== oddsFormat) {
      dispatch({
        type: "SET_ODDS_FORMAT",
        oddsFormat: oddsFormatCookie,
      });
    }
  }, [oddsFormat]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- legacy code
    waitForLocalStorage();
    setHasRetrievedStoredSelections(true);
  }, [waitForLocalStorage, setHasRetrievedStoredSelections]);

  const getOddsData = useCallback(async () => {
    const { selections, bookmakers: bookmakersFromCommonAndSelectionIdPairs } =
      await getOddsByCommonAndSelectionIdPairs(
        activeCommonAndEventIdPairs,
        locale
      );
    const validatedSelections = Array.isArray(selections) ? selections : [];
    const validatedBookmakers = Array.isArray(
      bookmakersFromCommonAndSelectionIdPairs
    )
      ? bookmakersFromCommonAndSelectionIdPairs
      : [];
    if (activeCommonAndEventIdPairs.length > 0) {
      dispatch({
        type: "UPDATE_SELECTIONS",
        selections: validatedSelections,
        bookmakers: validatedBookmakers,
      });
    }
    if (
      betAddedSource &&
      betAddedSource !== "localStorage" &&
      betAddedSource !== "removed"
    ) {
      // we don't want to send a GA event for selections retrieved from local storage
      // or if the selection was removed from the betslip
      if (betAddedSource === "Tipster") {
        // if the user has added a bet from Tipster, then that will replace everything in the
        // betslip, so we need to send off all of the selections
        gaAddToBetSlipEvent(selections, betAddedSource);
      } else {
        // if they have not added a bet from the tipster this will be an individual selection
        // meaning we only want to send off the most recent bet selection.
        const recentlyAdded = selections.find((selection) =>
          selectionsDataAndState.every(
            (item) =>
              selection.id !== item.id ||
              (selection.id === item.id && selection.commonID !== item.commonID)
          )
        );
        if (recentlyAdded) {
          gaAddToBetSlipEvent([recentlyAdded], betAddedSource);
        }
      }
    }

    if (hasRetrievedStoredSelections) {
      localStorage.setItem(
        "betSlipSelections",
        JSON.stringify({
          activeCommonAndEventIdPairs,
        })
      );
    }

    if (oddsChanged) {
      dispatch({
        type: "SET_ODDS_HAVE_CHANGED",
        oddsChanged: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only need to retrieve odds data when the below values change
  }, [
    activeCommonAndEventIdPairs,
    locale,
    hasRetrievedStoredSelections,
    oddsChanged,
  ]);

  useEffect(() => {
    // We only want to make this request in FA as we handle odds per bookmaker in FP
    if (brand === FA_BRAND) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- legacy code
      getOddsData();
    }
    // We only want to update local storage for FP
    if (brand === FP_BRAND) {
      if (hasRetrievedStoredSelections) {
        localStorage.setItem(
          "betSlipSelections",
          JSON.stringify({
            activeCommonAndEventIdPairs,
          })
        );
      }
    }
  }, [
    activeCommonAndEventIdPairs,
    locale,
    hasRetrievedStoredSelections,
    getOddsData,
    brand,
  ]);

  useEffect(() => {
    if (!oddsChanged) return;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- legacy code
    getOddsData();
  }, [oddsChanged, getOddsData]);

  return (
    <BetSlipDispatchContext.Provider value={dispatch}>
      <BetSlipStateContext.Provider
        value={{
          isOpen,
          selectionsDataAndState,
          activeCommonAndEventIdPairs,
          bookmakers,
          portalBookmaker,
          betAddedSource,
          oddsChanged,
          oddsFormat,
          bestOdds,
        }}
      >
        {children}
      </BetSlipStateContext.Provider>
    </BetSlipDispatchContext.Provider>
  );
}

export default BetSlipProvider;
