import { ReactElement } from "react";

export const InstagramLogo = (): ReactElement => (
  <svg
    width="31px"
    height="31px"
    viewBox="0 0 31 31"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Instagram Logo</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Landing"
        transform="translate(-1637.000000, -6706.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="::-Footer" transform="translate(0.000000, 6534.000000)">
          <g id="::-Social" transform="translate(1560.000000, 100.000000)">
            <g id="Social-Logos" transform="translate(0.000000, 72.000000)">
              <g id="002-instagram" transform="translate(77.000000, -0.000000)">
                <path
                  d="M21.0259783,0 L8.92152174,0 C4.00217391,0 0,4.00217391 0,8.92152174 L0,21.0259783 C0,25.9453261 4.00217391,29.9475 8.92152174,29.9475 L21.0259783,29.9475 C25.9453261,29.9475 29.9475,25.9453261 29.9475,21.0259783 L29.9475,8.92152174 C29.9475,4.00217391 25.9452717,0 21.0259783,0 Z M26.9347826,21.0259783 C26.9347826,24.2892935 24.2892935,26.9347826 21.0259783,26.9347826 L8.92152174,26.9347826 C5.65820652,26.9347826 3.01271739,24.2892935 3.01271739,21.0259783 L3.01271739,8.92152174 C3.01271739,5.65815217 5.65820652,3.01271739 8.92152174,3.01271739 L21.0259783,3.01271739 C24.2892935,3.01271739 26.9347826,5.65815217 26.9347826,8.92152174 L26.9347826,21.0259783 L26.9347826,21.0259783 Z"
                  id="Shape"
                ></path>
                <path
                  d="M14.97375,7.22826087 C10.7028804,7.22826087 7.22826087,10.7028804 7.22826087,14.9736957 C7.22826087,19.2445109 10.7028804,22.7191848 14.97375,22.7191848 C19.2446196,22.7191848 22.7192391,19.2445652 22.7192391,14.9736957 C22.7192391,10.7028261 19.2446196,7.22826087 14.97375,7.22826087 Z M14.97375,19.7065217 C12.3598913,19.7065217 10.2409783,17.5876087 10.2409783,14.97375 C10.2409783,12.3598913 12.3599457,10.2409783 14.97375,10.2409783 C17.5876087,10.2409783 19.7065217,12.3598913 19.7065217,14.97375 C19.7065217,17.5875543 17.5875543,19.7065217 14.97375,19.7065217 Z"
                  id="Shape"
                ></path>
                <circle
                  id="Oval"
                  cx="22.7342391"
                  cy="7.28641304"
                  r="1.85597826"
                ></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
