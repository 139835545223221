import { LinkNoPrefetch } from "../LinkNoPrefetch/LinkNoPrefetch";
import styles from "./CtaLink.module.scss";

interface CtaLinkProps {
  title: string;
  href: string;
  isSecondary?: boolean;
  linkType: "internal" | "external";
  onClick?: () => void;
}

export function CtaLink({
  title,
  href,
  isSecondary = false,
  linkType,
  onClick,
}: CtaLinkProps): React.JSX.Element {
  const linkClassName = isSecondary
    ? `${styles.link} ${styles["link--secondary"]}`
    : styles.link;

  if (linkType === "external") {
    return (
      <a
        className={linkClassName}
        href={href}
        onClick={onClick}
        rel="noreferrer"
        target="_blank"
      >
        {title}
      </a>
    );
  }

  return (
    <LinkNoPrefetch className={linkClassName} href={href} onClick={onClick}>
      {title}
    </LinkNoPrefetch>
  );
}
