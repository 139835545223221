import type { ContentResponse } from "../../../types/api/content";
import { getDataFromPathAndQueryParams } from "../helpers/helpers";

export const getPageData = async (
  fullUrlPath: string
): Promise<ContentResponse> => {
  const path = "pages";
  const queryParams = { full_url_path: fullUrlPath };

  return getDataFromPathAndQueryParams({ path, queryParams });
};
