import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import useIsomorphicLayoutEffect from "@/hooks/IsomorphicLayoutEffect";
import styles from "@/components/nav/SecondaryNav/SecondaryNav.module.scss";
import { NavItem } from "@/types/api/nav";
import AngleRightIcon from "@/components/icons/AngleRightIcon";
import AngleLeftIcon from "@/components/icons/AngleLeftIcon";
import { LinkNoPrefetch } from "@/components/LinkNoPrefetch/LinkNoPrefetch";
import { gaEvent, isPredictionsLink } from "@/lib/gtag";
import { isPromotionsPage } from "@/lib/page";

interface Props {
  navItems: NavItem[];
  isMobileDevice: boolean;
}

type ScrollDirection = "left" | "right";

const SCROLL_STEP = 200;

const isThereContentToTheRight = (
  clientWidth: number,
  newScrollPosition: number,
  scrollWidth: number
): boolean => clientWidth + newScrollPosition >= scrollWidth;

const SecondaryNav = ({ navItems, isMobileDevice }: Props) => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [canStillScrollRight, setCanStillScrollRight] = useState<boolean>(true);
  const [activePath, setActivePath] = useState("");
  const ulRef = useRef<HTMLUListElement>(null);
  const router = useRouter();

  useEffect(() => {
    const path = router.asPath;
    setActivePath(path);

    // We reset the scroll position here back to 0 incase we have navigated from a page
    // where we have scrolled on the secondary nav previously to prevent unnecessary nav arrows appearing
    setScrollPosition(0);
  }, [router.isReady, router.asPath]);

  /**
   * Set whether there are elements to the right of the currently rendered content
   * @param clientWidth
   * @param scrollWidth
   * @param [newScrollPosition = 0] - allows you to calculate if there's content after scrolling, otherwise defaults to zero
   */
  const canItScrollRight = (
    clientWidth: number,
    scrollWidth: number,
    newScrollPosition = 0
  ): void => {
    const noMoreContentToScrollRight = isThereContentToTheRight(
      clientWidth,
      newScrollPosition,
      scrollWidth
    );

    if (noMoreContentToScrollRight) {
      setCanStillScrollRight(false);
    } else {
      setCanStillScrollRight(true);
    }
  };

  const scrollNav = (direction: ScrollDirection) => {
    const ref = ulRef.current;
    if (ref !== null) {
      const { scrollWidth, clientWidth, scrollLeft } = ref;

      const newScrollPosition =
        direction === "right"
          ? scrollLeft + SCROLL_STEP
          : scrollLeft - SCROLL_STEP;
      setScrollPosition(newScrollPosition);

      canItScrollRight(clientWidth, scrollWidth, newScrollPosition);
    }
  };

  // Check if the nav items need to scroll
  // This ensures we only render the right arrow when necessary
  useIsomorphicLayoutEffect(() => {
    const ref = ulRef.current;
    if (ref !== null) {
      const { scrollWidth, clientWidth } = ref;

      canItScrollRight(clientWidth, scrollWidth);
    }

    // navItems.length has been added as a dependency because in some cases when viewing a page with many navItems
    // the scroll arrows would not display
  }, [navItems.length]);

  useIsomorphicLayoutEffect(() => {
    const ref = ulRef.current;

    if (ref !== null) {
      ref.scrollLeft = scrollPosition;
    }
  }, [scrollPosition]);

  const gaLeagueSelectEvent = (url: string, name: string): void => {
    if (!isPredictionsLink(url)) return;

    const gaEventParams = {
      league: name,
    };

    gaEvent("football_predictions_league_select", gaEventParams);
  };

  return (
    <div className={styles.secondaryNavContainer}>
      <div></div>
      <nav
        className={styles.secondaryNav}
        role="navigation"
        aria-labelledby="secondaryNavHeader"
      >
        <h3 className={styles.secondaryNavHeader} id="secondaryNavHeader">
          Secondary Nav
        </h3>
        {scrollPosition > 0 && !isMobileDevice && (
          <button
            className={`${styles.secondaryAngleButton} ${styles.secondaryAngleButtonLeft}`}
            aria-hidden={true}
            data-testid="secondaryNavLeftArrow"
            onClick={() => scrollNav("left")}
          >
            <AngleLeftIcon />
          </button>
        )}
        <ul
          className={`${styles.secondaryNavUl} ${
            isPromotionsPage(router.asPath) ? styles[`secondaryNavUl--ppc`] : ""
          }`}
          ref={ulRef}
        >
          {navItems.map((navItem) => (
            <li key={navItem.id}>
              <LinkNoPrefetch
                className={`${styles.secondaryNavLink} ${
                  activePath === navItem.url ? styles.active : ""
                }`}
                href={navItem.url}
                onClick={() => gaLeagueSelectEvent(navItem.url, navItem.title)}
              >
                {navItem.title}
              </LinkNoPrefetch>
            </li>
          ))}
        </ul>
        {!isMobileDevice && canStillScrollRight && (
          <button
            className={`${styles.secondaryAngleButton} ${styles.secondaryAngleButtonRight}`}
            aria-hidden={true}
            data-testid="secondaryNavRightArrow"
            onClick={() => scrollNav("right")}
          >
            <AngleRightIcon />
          </button>
        )}
      </nav>
      <div></div>
    </div>
  );
};

export default SecondaryNav;
