export const isValidOptionalString = (
  val: string | undefined | null
): boolean => val !== undefined && val !== null && val !== "";

export const isValidUrlOrPath = (val: string): boolean => {
  // check that the string is a valid URL

  /* 
      Breakdown of the regex:
  
      - ^ Matches the start of the string, making sure it's a valid URL
  
      - https? Matches either "http" or "https" at the start of the string
  
      - :/\/ Matches "://" that separates the protocol from the rest of the URL.
  
      - [a-z\d-]+ Matches one or more letters, digits, or hyphens in the domain name, subdomains
  
      - [/\w.-]* Checks for a valid path, which starts with a forward slash / and is followed by letters, digits,
      underscores _, periods ., and hyphens -, this pattern is used to match the path of the URL
  
      - (\?[\w&=]+)* Checks for an optional query string, which starts with a question mark ? and is followed by
      ampersands & and equals =, this pattern is used to match the query string of the URL
  
      $ Matches the end of the string, making sure it's a valid URL
  
      i Matches case-insensitively.
    
    */

  const urlRegex = new RegExp(
    /^https?:\/\/[a-z\d-]+(?<domain>\.[a-z\d-]+)+[/\w.-]*(?<query>\?[\w&=]+)*$/i
  );
  // check that the string is a valid URL path
  const urlPathRegex = new RegExp(
    /^\/(?<urlSegmentWithSlash>[\w\d-]+\/)*(?<urlSegment>[\w\d-]+)?(?<query>\?[\w&=]+)*$/i
  );
  return (
    isValidOptionalString(val) && (urlRegex.test(val) || urlPathRegex.test(val))
  );
};
