import { default as unstyled } from "./unstyled";
import { default as headerTwo } from "./headerTwo";
import { default as headerThree } from "./headerThree";
import { default as headerFour } from "./headerFour";
import { default as headerFive } from "./headerFive";
import { default as unorderedListItem } from "./unorderedListItem";
import { default as orderedListItem } from "./orderedListItem";
import { default as blockquote } from "./blockquote";
import { default as atomic } from "./atomic";

export const blocks = {
  unstyled,
  headerTwo,
  headerThree,
  headerFour,
  headerFive,
  unorderedListItem,
  orderedListItem,
  blockquote,
  atomic,
};
