export const FA_BRAND = "fa";
export const TWE_BRAND = "twe";
export const FP_BRAND = "fp";
export const OC_BRAND = "oc";
export const PPC_BRAND = "ppc1";
export const CM_BRAND = "cm";
export const TBA_BRAND = "tba";

export const DEFAULT_BRAND = FA_BRAND;
export const SPORTS_BLOG_BRAND_WITH_TIPS_SHORT_NAMES = [
  DEFAULT_BRAND,
  TWE_BRAND,
] as const;
export const SPORTS_BLOG_BRAND_SHORT_NAMES = [
  ...SPORTS_BLOG_BRAND_WITH_TIPS_SHORT_NAMES,
  OC_BRAND,
  FP_BRAND,
] as const;
export const BRAND_SHORT_NAMES = [
  ...SPORTS_BLOG_BRAND_SHORT_NAMES,
  PPC_BRAND,
  CM_BRAND,
  TBA_BRAND,
] as const;

export const BRAND_DETAILS_MAP = {
  fa: {
    fullName: "Footy Accumulators",
    email: "contact@footyaccumulators.com",
    domain: "footyaccumulators.com",
  },
  fp: {
    fullName: "FlashPicks",
    email: "contact@flashpicks.com",
    domain: "flashpicks.com",
  },
  twe: {
    fullName: "The Winners Enclosure",
    email: "contact@thewinnersenclosure.com",
    domain: "thewinnersenclosure.com",
  },
  oc: {
    fullName: "Oddschanger",
    email: "contact@oddschanger.com",
    domain: "oddschanger.com",
  },
  cm: {
    fullName: "Checkd Group",
    email: "hello@checkd.media",
    domain: "checkd.media",
  },
  ppc1: {
    fullName: "The Bonus Lounge",
    email: "",
    domain: "thebonuslounge.com",
  },
  tba: {
    fullName: "The Betting Analysts",
    email: "",
    domain: "thebettinganalysts.com",
  },
} as const;

export const BRANDS_THAT_SUPPORT_API_BETTING = [FA_BRAND];
