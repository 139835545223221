import { Block } from "../../../types/draftManager";

export default function unstyled(
  block: Block,
  originalText: string | undefined
): JSX.Element {
  const { data } = block;

  return (
    <p style={{ textAlign: data?.alignment ? data.alignment : "left" }}>
      {originalText}
    </p>
  );
}
