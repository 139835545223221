import styled from "styled-components";
import { devices } from "@/constants/devices";
import { LinkNoPrefetch } from "@/components/LinkNoPrefetch/LinkNoPrefetch";

export const PpcFooterStyles = styled.footer`
  box-sizing: border-box;
  color: ${({ theme }) => theme.footer.fontColor};

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.footer.fontColor};
  }
`;

export const DisclaimerWrapper = styled.div`
  display: flex;
  place-content: center;
  flex-direction: column;
  text-align: center;
  padding: 1rem;
  background-color: ${({ theme }) => theme.footer.backgroundColor};

  @media only screen and ${devices.laptop} {
    padding: 3rem 1rem;
  }
`;

export const LegalitiesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  && span {
    max-width: 200px !important;
  }
`;

export const AgeLimitWrapper = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border: 2px solid ${({ theme }) => theme.footer.fontColor};
  border-radius: 50%;
  color: ${({ theme }) => theme.footer.fontColor};
  text-align: center;
  font-size: 1rem;
  padding: 0.5rem;
  box-sizing: border-box;
`;

export const Ppc1FooterLinks = styled.div`
  background-color: ${({ theme }) => theme.footer.navBackgroundColor};
  height: 93px;
  padding: 0 16px;
  font-size: 12px;
  text-align: center;
`;

export const Ppc1FooterUl = styled.ul`
  margin: 0;
  padding: 1rem 0;
  list-style: none;
  display: flex;
  justify-content: center;
`;

export const Ppc1FooterLink = styled(LinkNoPrefetch)`
  font-weight: bold;
  margin-left: 10px;
`;
