import type { Entity } from "../../../types/draftManager";

export default function atomicYoutube(
  entity: Entity
): React.JSX.Element | null {
  const {
    data: { youtube },
  } = entity;

  if (!youtube?.id) return null;

  return (
    <figure>
      <iframe
        allow="encrypted-media"
        allowFullScreen
        height="325"
        src={`https://www.youtube.com/embed/${youtube.id}`}
        title="Youtube Video"
        width="575"
      />
    </figure>
  );
}
