interface Props {
  styleColor?: string;
}

const BackArrowIcon = ({ styleColor = "#B6CBD8" }: Props) => (
  <svg
    width="16px"
    height="12px"
    viewBox="0 0 16 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="backArrowIcon"
    aria-hidden="true"
  >
    <title>Back</title>
    <g
      id="::-FootyAccums"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="320--Home-(Menu)"
        transform="translate(-28.000000, -50.000000)"
        fill={styleColor}
      >
        <g id="Group-3" transform="translate(16.000000, 36.000000)">
          <g id="Back">
            <path d="M12.2912619,19.1900689 L16.7200195,14.3653802 C16.9802281,14.0648907 17.3758731,13.9364428 17.7527053,14.0301141 C18.1295376,14.1237855 18.4279028,14.4247476 18.5314779,14.815665 C18.635053,15.2065823 18.5274417,15.6255709 18.2505981,15.9092806 L15.5508275,18.8783198 L26.9370982,18.8783198 C27.5241226,18.8783198 28,19.3768013 28,19.9917095 C28,20.6066177 27.5241226,21.1050992 26.9370982,21.1050992 L15.4161933,21.1050992 L18.3994044,23.9702221 C18.7399735,24.2164299 18.9131732,24.6473009 18.8433123,25.0745373 C18.7734514,25.5017736 18.4732307,25.847703 18.0738517,25.9611483 C17.6744728,26.0745936 17.2485434,25.9349303 16.9822019,25.6031937 L12.3762941,21.1496348 C12.1087218,20.9092001 11.9754033,20.5433142 12.0219935,20.1772745 C11.95166,19.8218584 12.0522924,19.452915 12.2912619,19.1900689 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default BackArrowIcon;
