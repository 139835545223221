import { ReactElement } from "react";
import { SportsBlogFooter } from "@/components/footer/SportsBlogFooter/SportsBlogFooter";
import { BrandShortNames } from "@/types/brand";
import { ContentTipLink } from "@/types/api/content";
import { CheckdMediaFooter } from "@/components/footer/CheckdMediaFooter/CheckdMediaFooter";
import { Ppc1Footer } from "@/components/footer/Ppc1Footer/Ppc1Footer";
import { UsStates } from "@/types/api/config";

interface Props {
  brand: BrandShortNames;
  colourMode: string;
  // eslint-disable-next-line no-unused-vars
  handleThemeChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  footerTipLinks?: ContentTipLink[];
  fpStates?: UsStates[];
  region?: string | null;
}

export const FooterRenderer = ({
  brand,
  footerTipLinks,
  colourMode,
  handleThemeChange,
  fpStates,
  region,
}: Props): ReactElement => {
  switch (brand) {
    case "fa":
    case "twe":
    case "fp":
    case "oc":
      return (
        <SportsBlogFooter
          brand={brand}
          tipLinks={footerTipLinks}
          colourMode={colourMode}
          handleThemeChange={handleThemeChange}
          fpStates={fpStates}
          region={region}
        />
      );
    case "cm":
      return <CheckdMediaFooter />;
    case "ppc1":
      return <Ppc1Footer />;
  }
};
