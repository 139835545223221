import { Block } from "../../../types/draftManager";

export default function HeaderFour(
  block: Block,
  originalText: string
): JSX.Element {
  const { data } = block;

  return (
    <h4 style={{ textAlign: data?.alignment ? data.alignment : "left" }}>
      {originalText}
    </h4>
  );
}
