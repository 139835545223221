import { nanoid } from "nanoid";
import type { ContentWidgetsArray } from "../../../types/api/content";
import { WidgetRenderer } from "./WidgetRenderer";

interface WidgetMapProps {
  widgets: ContentWidgetsArray | undefined;
  contentLastUpdated: number;
}

export function WidgetMap({
  widgets,
  contentLastUpdated,
}: WidgetMapProps): React.JSX.Element | null {
  return widgets ? (
    <>
      {widgets.map((widget) => (
        <WidgetRenderer
          contentLastUpdated={contentLastUpdated}
          key={nanoid()}
          widget={widget}
        />
      ))}
    </>
  ) : null;
}
