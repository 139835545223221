import type { UrlObject } from "node:url";
import Link from "next/link";

interface LinkNoPrefetchProps {
  children: React.ReactNode;
  href: string | UrlObject;
  className?: string;
  "data-testid"?: string;
  "aria-hidden"?: boolean;
  "aria-pressed"?: boolean;
  "aria-label"?: string;
  prefetch?: boolean;
  style?: Record<string, string | number>;
  target?: string;
  rel?: string;
  onClick?: () => void;
}

export function LinkNoPrefetch({ children, ...props }: LinkNoPrefetchProps) {
  return (
    <Link prefetch={false} {...props}>
      {children}
    </Link>
  );
}
