import { useEffect, useRef } from "react";

export function useDetectOutsideClick<T extends HTMLElement = HTMLElement>(
  callback: () => void,
  buttonRef?: React.RefObject<HTMLButtonElement>
): React.RefObject<T> {
  const ref = useRef<T>(null);
  useEffect(() => {
    const handleClick = (event: MouseEvent): void => {
      // Had to use a type assertion here because the event.target is a generic
      // Due to MouseEvent could refer to DOM global interface or a Node element
      if (
        ref.current &&
        !ref?.current?.contains(event.target as Node) &&
        !buttonRef?.current?.contains(event.target as Node)
      ) {
        callback();
      }
    };

    window.addEventListener("click", handleClick, true);
    return () => {
      window.removeEventListener("click", handleClick, true);
    };
  }, [ref, buttonRef, callback]);

  return ref;
}
