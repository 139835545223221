import { Block } from "../../../types/draftManager";

export default function Blockquote(
  block: Block,
  originalText: string
): JSX.Element {
  const { data } = block;

  return (
    <blockquote
      style={{ textAlign: data?.alignment ? data.alignment : "left" }}
    >
      {originalText}
    </blockquote>
  );
}
