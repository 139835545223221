import React, { forwardRef } from "react";
import { NavItem } from "@/types/api/nav";
import styles from "@/components/nav/MenuList/MenuList.module.scss";
import { getActiveBrand, getFreeBetPath } from "@/lib/brand";
import { SportBrandShortNames } from "@/types/brand";
import { LinkNoPrefetch } from "@/components/LinkNoPrefetch/LinkNoPrefetch";
import { useLocaleFromRouter } from "@/hooks/LocationFromRouter";
import { useFreeBetCount } from "@/hooks/freeBetCount";
import { FooterLegislation } from "@/components/FooterLegislation/FooterLegislation";

interface Props {
  navItems: NavItem[];
  closeMenu: () => void;
  region?: string | null;
}

const MenuList = forwardRef(
  (
    { navItems, closeMenu, region }: Props,
    ref: React.ForwardedRef<HTMLUListElement>
  ): JSX.Element => {
    function handleClick() {
      closeMenu();
    }

    const brand = getActiveBrand();
    const [locale] = useLocaleFromRouter();
    const offerCount = useFreeBetCount(brand, region ?? locale);
    const freeBetPath = getFreeBetPath(brand, region);
    return (
      <>
        <ul className={styles.menuUl} ref={ref}>
          {navItems.map((navItem) => (
            <li className={styles.menuLi} key={navItem.id}>
              <LinkNoPrefetch
                className={styles.menuLink}
                href={navItem.url}
                key={navItem.id}
                onClick={handleClick}
              >
                {navItem.title}
                {navItem.url === freeBetPath ? (
                  <span className={styles.offerCount} data-testid="offerCount">
                    {offerCount}
                  </span>
                ) : null}
              </LinkNoPrefetch>
            </li>
          ))}
        </ul>
        <div className={styles.gambleAware}>
          <FooterLegislation
            brand={brand as SportBrandShortNames}
            region={region}
            colourMode={"dark"}
            parent="menu"
          />
        </div>
      </>
    );
  }
);

MenuList.displayName = "MenuList";

export default MenuList;
