import { extractScriptTagsFromHtmlString } from "../utils/editorUtils";
import { EditorWithScript } from "./EditorWithScript";
import styles from "./Editor.module.scss";

export interface EditorProps {
  formattedContent?: string;
  id?: string;
  removePadding?: boolean;
  styleClasses?: string;
}

// Due to the content being fetched and formatted using the draftManager at build time we pass formattedContent
// as a prop to the component, while will render the widget content using dangerouslySetInnerHTML.
export function Editor({
  formattedContent,
  removePadding = false,
  styleClasses,
}: EditorProps): React.JSX.Element | null {
  if (!formattedContent) return null;
  // we want to check if the formatted content has any script tags with src attributes
  const scripts = extractScriptTagsFromHtmlString(formattedContent);

  if (scripts.length)
    return (
      <EditorWithScript
        formattedContent={formattedContent}
        removePadding={removePadding}
        scripts={scripts}
        styleClasses={styleClasses}
      />
    );

  return (
    <article
      className={`${styles.editorCard} ${
        removePadding ? styles.removePadding : ""
      } ${styleClasses || ""}`.trim()}
      dangerouslySetInnerHTML={{ __html: formattedContent }}
    />
  );
}
