import {
  Breadcrumb,
  BreadcrumbAlteration,
} from "@/components/Breadcrumbs/Breadcrumbs";
import { PredictionTeamLineupPlayer } from "@/types/api/predictions";

export const teamArrayToCommaSeperatedString = (
  team: PredictionTeamLineupPlayer[]
): string =>
  team.reduce(
    (teamString, { name }, i) => (i === 0 ? name : `${teamString}, ${name}`),
    ""
  );

export const alterBreadcrumbs = (
  breadcrumbs: Breadcrumb[],
  breadcrumbAlterations: BreadcrumbAlteration[]
): Breadcrumb[] => {
  return breadcrumbs.reduce<Breadcrumb[]>(
    (updatedBreadcrumbs, currentBreadcrumb, index) => {
      const alteration = breadcrumbAlterations.find(
        (alteration) => alteration.position === index
      );
      if (!alteration) {
        updatedBreadcrumbs.push(currentBreadcrumb);
      }
      if (alteration?.remove) {
        return updatedBreadcrumbs;
      }
      if (alteration?.updateUrl) {
        currentBreadcrumb.url = alteration.updateUrl;
        updatedBreadcrumbs.push(currentBreadcrumb);
      }
      return updatedBreadcrumbs;
    },
    []
  );
};

export const routeToArray = (
  route: string,
  region?: string | null
): string[] => {
  return (
    route
      // Split by "?" to remove queries from route path
      .split("?")[0]
      .split("/")
      .filter(
        (routePart) => !(region && routePart === region) && routePart !== ""
      )
  );
};

export const routeArrToBreadcrumbs = (routeArray: string[]): Breadcrumb[] => {
  return routeArray.map(
    (crumb, index): Breadcrumb => ({
      text: crumb,
      url: `/${routeArray.slice(0, index + 1).join("/")}`,
    })
  );
};

export const isEmptyArray = (array: unknown[] | null): array is null => {
  return !array || array.length === 0;
};

export const isEveryStringOrNumberArrayItemIdentical = (
  array: (string | number)[]
): boolean => {
  return array.every((item: string | number) => item === array[0]);
};
