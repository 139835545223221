import Image from "next/image";
import type { Dispatch } from "react";
import { useContext } from "react";
import type { Bookmaker, StatsTipsterSelection } from "../../types/api/tipster";
import type { OddsFormats } from "../../types/odds";
import { useLocaleFromRouter } from "../../hooks/LocationFromRouter";
import { selectCurrencySymbol } from "../../utils/content/content";
import { BetSlipDispatchContext } from "../../contexts/betSlipContext";
import type { BetSlipActions, PortalBookmakers } from "../../types/betslip";
import { doesBookmakerSupportAPIBetting } from "../../utils/bookmakerPortal/bookmakerPortal";
import { bannerLogoUrlFromName } from "../../utils/media/media";
import { CtaLink } from "../CtaLink/CtaLink";
import { CtaButton } from "../CtaButton/CtaButton";
import styles from "./CustomTipCta.module.scss";

export interface CustomTipCtaProps {
  bookmaker: Bookmaker["bookmaker"];
  odds?: Bookmaker["odds"];
  stakeReturns?: string;
  signUpUrl?: string;
  ctaUrl: string;
  addToBetSlipText?: string;
  isStatsTipster?: boolean;
  selections?: StatsTipsterSelection[];
  oddsFormat: OddsFormats;
  onCtaClick?: (bookmaker?: string) => void;
}

export function CustomTipCta({
  bookmaker,
  odds,
  stakeReturns,
  signUpUrl,
  ctaUrl,
  addToBetSlipText = "ADD TO BETSLIP",
  isStatsTipster,
  selections,
  onCtaClick,
}: CustomTipCtaProps): React.JSX.Element {
  const [locale] = useLocaleFromRouter();
  const currencySymbol = selectCurrencySymbol(locale);
  const dispatch = useContext(
    BetSlipDispatchContext
  ) as Dispatch<BetSlipActions>;
  // if the brand and bookmaker support API betting and selections have been passed down,
  // then we can add selections to the betslip and open the bookmaker portal
  // if no selections have been passed down then we can assume they have come from a Custom Tip
  const isSupportedAPIBookmaker =
    doesBookmakerSupportAPIBetting(bookmaker, locale) &&
    selections &&
    selections.length > 0;

  const handleCtaClick = () => {
    if (onCtaClick) {
      onCtaClick(bookmaker);
    }
  };

  function renderSignUpCTA(): React.ReactElement | null {
    if (isStatsTipster) {
      return (
        <div className={styles.signUpLinkWrapper}>
          {signUpUrl ? (
            <CtaLink
              href={signUpUrl}
              linkType="external"
              title={addToBetSlipText ? "Sign Up" : "SIGN UP"}
            />
          ) : null}
        </div>
      );
    }
    if (signUpUrl) {
      return (
        <div>
          <CtaLink
            href={signUpUrl}
            linkType="external"
            title={addToBetSlipText ? "Sign Up" : "SIGN UP"}
          />
        </div>
      );
    }
    return null;
  }

  const addSelectionsToBetslip = (): void => {
    if (!isSupportedAPIBookmaker) return;
    // we want to clear any exisiting selections in the betslip
    dispatch({ type: "CLEAR" });

    const selectionIds = selections.map((selection) => ({
      commonID: selection.commonID,
      eventId: selection.mappedEventId,
    }));

    // protect against no selectionIds
    if (selectionIds.length === 0) return;

    // add selections to betslip
    dispatch({
      type: "ADD_ACCA",
      commonAndSelectionIdPairsArray: selectionIds,
      source: "Tipster",
    });
    // open the betslip
    dispatch({ type: "OPEN" });
    // set the bookmaker so the Bookmaker Portal is opened with the correct bookmaker
    dispatch({
      type: "SET_PORTAL_BOOKMAKER",
      bookmaker: bookmaker as PortalBookmakers,
    });
  };

  const gridWrapperClassName = signUpUrl
    ? `${styles.gridWrapper} ${styles["gridWrapper--includesSignUpLink"]}`
    : styles.gridWrapper;

  return (
    <div className={gridWrapperClassName}>
      <div className={styles.imageWrapper}>
        <Image
          alt={`${bookmaker} logo`}
          fill
          sizes="50px"
          src={bannerLogoUrlFromName(bookmaker)}
        />
      </div>
      {odds ? <div>{`*${odds}`}</div> : null}
      {stakeReturns ? (
        <div className={styles.returns} data-testid="returns">
          {currencySymbol}
          {stakeReturns}
        </div>
      ) : null}
      {renderSignUpCTA()}
      <div>
        {isSupportedAPIBookmaker ? (
          <CtaButton
            clickHandler={() => {
              addSelectionsToBetslip();
            }}
            isSecondary
            title={addToBetSlipText}
          />
        ) : (
          <CtaLink
            href={ctaUrl}
            isSecondary
            linkType="external"
            onClick={handleCtaClick}
            title={addToBetSlipText}
          />
        )}
      </div>
    </div>
  );
}
