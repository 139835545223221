import { useRouter } from "next/router";
import { DEFAULT_LOCALE } from "@/constants/countries";

export const useLocaleFromRouter = (): [string] => {
  const router = useRouter();
  const locale = router.query.locale;

  if (!locale) return [DEFAULT_LOCALE];

  const localeIsArray = Array.isArray(locale);

  if (localeIsArray) {
    return locale?.length === 0 ? [DEFAULT_LOCALE] : [locale[0]];
  }

  return [locale];
};
