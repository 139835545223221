import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

interface IconProps {
  className?: string;
}

export function InformationIcon({ className }: IconProps) {
  return <FontAwesomeIcon className={className} icon={faInfo} />;
}
