import Script from "next/script";
import { useEffect } from "react";
import { nanoid } from "nanoid";
import { extractScriptSrc } from "../utils/editorUtils";
import styles from "./Editor.module.scss";

interface EditorWithScriptProps {
  formattedContent: string;
  id?: string;
  removePadding?: boolean;
  styleClasses?: string;
  scripts?: string[];
}

interface TwitterWindow extends Window {
  twttr?: {
    widgets?: {
      load: () => void;
    };
  };
}

export function EditorWithScript({
  scripts,
  formattedContent,
  removePadding = false,
  styleClasses,
}: EditorWithScriptProps) {
  useEffect(() => {
    // check if there are any script tags in the formatted content
    // if not that means that there are no twitter widgets to load
    if (
      scripts?.length &&
      scripts.some((script) => script.includes("platform.twitter.com"))
    ) {
      const twttr = (window as unknown as TwitterWindow).twttr;

      if (twttr?.widgets?.load) {
        twttr.widgets.load();
      }
    }
  }, [scripts]);

  // remove all of the script tags from the formatted content
  const formattedContentCopy = formattedContent.replace(
    /<script[^>]*?>.*?<\/script>/gs,
    ""
  );
  // extract all of the scripts src attributes
  const scriptSources = scripts
    ? scripts.map((script) => extractScriptSrc(script))
    : [];

  return (
    <>
      <article
        className={`${styles.editorCard} ${
          removePadding ? styles.removePadding : ""
        } ${styleClasses || ""}`.trim()}
        dangerouslySetInnerHTML={{ __html: formattedContentCopy }}
      />

      {scriptSources.length
        ? scriptSources.map((src) => (
            <Script
              key={nanoid()}
              src={src}
              strategy={
                // If it is a quiz script we should use the afterInteractive strategy otherwise use lazyOnload
                !src.includes("sporcle") ? "lazyOnload" : "afterInteractive"
              }
            />
          ))
        : null}
    </>
  );
}
