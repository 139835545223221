import { ReactElement } from "react";
import Image from "next/image";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { sportBrandFooterLogoMap } from "@/lib/media";
import {
  BRAND_DETAILS_MAP,
  FA_BRAND,
  FP_BRAND,
  TWE_BRAND,
  OC_BRAND,
} from "@/constants/brands";
import { SportBrandShortNames, SportsBrandThemeNames } from "@/types/brand";
import { ContentTipLink } from "@/types/api/content";
import { checkIsSportsBlogBrandWithTips } from "@/lib/brand";
import { SportsTips } from "@/components/footer/SportsBlogFooter/SportTips/SportsTips";
import { SportsBlogFooterNav } from "@/components/footer/SportsBlogFooter/SportsBlogFooterNav/SportsBlogFooterNav";
import ToggleSwitch from "@/components/ToggleSwitch/ToggleSwitch";
import { OddsFormatPicker } from "@/components/OddsFormatPicker/OddsFormatPicker";
import { UsStates } from "@/types/api/config";
import { StateSelectorProps } from "@/components/StateSelector/StateSelector";
import { FooterLegislation } from "@/components/FooterLegislation/FooterLegislation";
import DisclaimerText from "@/components/DisclaimerText/DisclaimerText";
import { getFooterConfig } from "@/lib/localisedContent";
import { getLocalisedConfig } from "@/lib/geo";
import { isEmptyObject } from "@/lib/object";
import { isPromotionsPage } from "@/lib/page";
import styles from "./SportsBlogFooter.module.scss";

interface Props {
  brand: SportBrandShortNames;
  tipLinks?: ContentTipLink[];
  colourMode: string;
  // eslint-disable-next-line no-unused-vars
  handleThemeChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  fpStates?: UsStates[];
  region?: string | null;
}

export const SportsBlogFooter = ({
  brand,
  tipLinks,
  colourMode,
  handleThemeChange,
  fpStates,
  region,
}: Props): ReactElement | null => {
  const router = useRouter();
  const brandHasTips = checkIsSportsBlogBrandWithTips(brand);
  const footerConfig = getFooterConfig(brand);
  if (!footerConfig) return null;
  const footerContent = getLocalisedConfig({
    locale: region ?? "",
    fallbackKey: "default",
    localisedConfig: footerConfig,
  });
  const promotionsPage = isPromotionsPage(router.asPath);

  // On a Promotions Page layout we should drop the Fantasy Hub and Betting Hub links from the SportsBlogFooterNav
  // otherwise we display all links.
  const footerNav = promotionsPage
    ? {
        ...footerContent.footerNav,
        links: footerContent.footerNav.links.filter(
          (link) => link.text !== "Fantasy Hub" && link.text !== "Betting Hub"
        ),
      }
    : footerContent.footerNav;

  function getBrandTheme(): SportsBrandThemeNames {
    if (brand === FA_BRAND && colourMode === "dark") {
      return "fa-dark";
    }
    return brand;
  }

  const brandFromTheme = getBrandTheme();

  const DynamicStateSelector = dynamic<StateSelectorProps>(() =>
    import("@/components/StateSelector/StateSelector").then(
      (mod) => mod.StateSelector
    )
  );

  return (
    <footer className={styles.sportsBlogFooterTag}>
      {!footerContent.timeToThinkBanner && brand === FA_BRAND && <hr />}
      {footerContent.timeToThinkBanner && (
        <div className={styles.timeToThinkContainer}>
          <a
            href="https://taketimetothink.co.uk"
            rel="noopener noreferrer"
            target="_blank"
            title="Take time to think campaign link"
          >
            <Image
              src="https://cdn.checkd.media/logos/take-time-to-think.png"
              height={40}
              width={250}
              data-testid="timeToThinkLogo"
              alt=""
            />
          </a>
        </div>
      )}
      <div className={styles.footerContainer}>
        <div></div>
        <div className={styles.footerContentContainer}>
          <div>
            <Image
              src={sportBrandFooterLogoMap[brandFromTheme]}
              width={328}
              height={brand === OC_BRAND ? 40 : 24}
              alt={`${BRAND_DETAILS_MAP[brand].fullName} logo`}
            />
            <>
              <div>
                {footerContent.description
                  ? footerContent.description.map((paragraph) => (
                      <p
                        key={paragraph.length}
                        className={styles.footerParagraph}
                      >
                        {paragraph}
                      </p>
                    ))
                  : null}
              </div>

              <FooterLegislation
                brand={brand}
                region={region}
                colourMode={colourMode}
                parent="footer"
              />

              {footerContent.themeSwitcher &&
              !isEmptyObject(footerContent.themeSwitcher) ? (
                <ToggleSwitch
                  translations={footerContent.themeSwitcher}
                  colourMode={colourMode}
                  handleThemeChange={handleThemeChange}
                />
              ) : null}

              {footerContent.oddsFormatSelector &&
              !promotionsPage &&
              (brand === FA_BRAND || brand === TWE_BRAND) ? (
                <OddsFormatPicker />
              ) : null}
            </>
          </div>
          <div>
            {brand === FP_BRAND && !promotionsPage ? (
              <OddsFormatPicker />
            ) : null}
            <div>
              {footerContent.stateSelector && !promotionsPage ? (
                <DynamicStateSelector position={"footer"} fpStates={fpStates} />
              ) : null}

              <DisclaimerText disclaimer={footerContent.disclaimer} />
            </div>
            {footerContent.showTipLinks &&
              brandHasTips &&
              tipLinks &&
              tipLinks.length > 0 &&
              !promotionsPage && (
                <SportsTips brand={brand} tipLinks={tipLinks} />
              )}
          </div>
        </div>
        <div></div>
      </div>
      <SportsBlogFooterNav footerNav={footerNav} />
    </footer>
  );
};
