import { useState } from "react";
import type { RawDraftContentState } from "draft-js";
import { Editor } from "../../Editor";
import { contentToHtml } from "../../../utils/draftManager/draftManager";
import { parseString } from "../../../utils/string/string";
import type { ContentAccordionQuestion } from "../../../types/api/content";
import { AngleDownIcon } from "../../../components/icons/AngleDownIcon";
import { AngleUpIcon } from "../../../components/icons/AngleUpIcon";
import styles from "./Accordion.module.scss";

type AccordionProps = Pick<
  ContentAccordionQuestion,
  "question" | "editorState"
>;

// make the question and editorState required
interface AccordionPropsWithKey extends AccordionProps {
  question: string;
  editorState: string;
  accordionKey: string;
}

export function Accordion({
  question,
  editorState,
  accordionKey,
}: AccordionPropsWithKey) {
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const context = parseString(editorState) as RawDraftContentState;
  const htmlString = contentToHtml(context);

  const toggleHidden = () => {
    setIsHidden(!isHidden);
  };

  return (
    <>
      {htmlString ? (
        <div>
          <div className={styles.accordionQuestionContainer}>
            <button
              aria-controls={accordionKey}
              aria-expanded={!isHidden}
              className={styles.accordionButton}
              onClick={toggleHidden}
              type="button"
            >
              <h3>{question}</h3>
              {isHidden ? <AngleDownIcon /> : <AngleUpIcon />}
            </button>
          </div>
          {isHidden ? null : (
            <Editor
              formattedContent={htmlString}
              id={accordionKey}
              removePadding
            />
          )}
        </div>
      ) : null}
    </>
  );
}
