/* eslint-disable @typescript-eslint/no-var-requires */
import { convertToCamelCase, removeEmptyElements } from "../string";
import { ContentBlock, Block, Entity } from "../../types/draftManager";
const { convertToHTML } = require("draft-convert");
const { convertFromRaw } = require("draft-js");
const blocks = require("./blocks");
const styles = require("./styles");
const entities = require("./entities");

const conversionHelpers = {
  styleToHTML(style: string): string | undefined {
    const type = style.toLowerCase();
    if (styles[type]) return styles[type](type);
    return undefined;
  },
  blockToHTML(block: Block, originalText: string): string {
    const type = convertToCamelCase(block.type.toLowerCase().split(":")[0]);
    if (blocks[type]) return blocks[type](block, originalText) || null;
    return "";
  },
  entityToHTML(entity: Entity, originalText: string): string {
    const type = entity.type.toLowerCase();
    if (entities[type]) return entities[type](entity, originalText);
    return originalText;
  },
};

export function contentToHtml(contentBlock: ContentBlock): string {
  const contentState = convertFromRaw(contentBlock);
  const renderedHtml = convertToHTML(conversionHelpers)(contentState);
  return removeEmptyElements(renderedHtml);
}
