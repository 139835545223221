import {
  ContentAccordionQuestion,
  ContentAccordionWidget,
  ContentEditorWidget,
  ContentParent,
  ContentPayload,
  ContentWidgetBase,
  ContentWidgetsArray,
} from "@/types/api/content";
import { Block } from "@/types/draftManager";
import { getTweet } from "@/lib/draftManager/blocks/atomic:twitter";
import { contentToHtml } from "@/lib/draftManager/draftManager";
import { parseString } from "@/lib/string";
import { NON_US_BET_TYPES } from "@/constants/bet";
import { AuthorData, AuthorPageData } from "@/types/api/authors";
import { PageProps, PaginatedNewsPageProps } from "@/types/page";
import { getAuthorFullNameFromAuthorInfo } from "@/lib/author";
import {
  BRANDS_SUPPORTED_LOCALISED_CONTENT_REGIONS,
  LOCALES_THAT_REQUIRE_LOCALISED_CONTENT,
} from "@/constants/brandLocalisedContent";
import { BrandShortNames } from "@/types/brand";
import { dataMapperLocalisedConfig } from "@/constants/configs/dataMapper";
import { getCurrentUnixTimestampMs } from "./date";
import { getActiveBrand } from "./brand";
import { getLocalisedConfig } from "./geo";
import { isEmptyObject } from "./object";
import { routeToArray } from "./array";

interface EditorBlock {
  type: string;
  text: string;
}

interface EditorState {
  blocks: EditorBlock[];
}

// Type guard to ensure TypeScript understands we only return EditorWidgets
function isEditorWidget(
  widget: ContentWidgetBase
): widget is ContentEditorWidget {
  return widget.component === "Editor";
}

export const isLocalisedContentRoute = (path: string): boolean => {
  // we filter here because if the path  starts with a slash then the Array looks like ['', 'br'] as an example
  const firstPathSegment = path.split("/").filter((segment) => segment)[0];
  return LOCALES_THAT_REQUIRE_LOCALISED_CONTENT.includes(firstPathSegment);
};

export function getTextFromEditorState(editor_state: string): string {
  if (editor_state === "") return "";

  const parsedEditorState: EditorState = JSON.parse(editor_state);
  return parsedEditorState.blocks.reduce((text, editorBlock) => {
    // Only text for unstyled blocks should be appended
    const blockText =
      editorBlock.type === "unstyled" ? editorBlock.text.trim() : "";

    if (blockText) {
      return text.length === 0 ? blockText : `${text} ${blockText}`;
    }

    return text;
  }, "");
}

export const createExtract = (
  widgets: ContentWidgetsArray,
  limit = 180,
  append = "..."
): string => {
  if (!widgets) return "";

  // Filter out non-Editor widgets
  const editorWidgets: ContentEditorWidget[] = widgets.filter(isEditorWidget);

  // Until limit is reached append text from Editor widgets
  const text = editorWidgets.reduce((text, editorWidget) => {
    if (text.length < limit) {
      const { editor_state } = editorWidget;
      if (editor_state) {
        const textFromEditor = getTextFromEditorState(editor_state);
        text = text.length === 0 ? textFromEditor : `${text} ${textFromEditor}`;
      }
    }
    return text.trim();
  }, "");

  return text.length > limit ? `${text.substring(0, limit)}${append}` : text;
};

export const generateFormattedEditorContent = async (
  widgets: ContentWidgetsArray
): Promise<ContentWidgetsArray> => {
  const allWidgets = widgets;
  await Promise.all(
    allWidgets.map(async (widget) => {
      // Check if the widget is an Editor widget with an editor_state
      if (widget?.component === "Editor" && widget?.editor_state) {
        // parse the editor state to get the editor blocks
        const context = parseString(widget.editor_state);
        // We map through all of the blocks in the editor state
        // and use the contentToHtml function to convert the blocks in to html strings
        const blocks = await Promise.all(
          context.blocks.map(async (block: Block) => {
            // If the block has a type of "atomic:twitter", we fetch the tweet and return the html from the api
            if (block.type === "atomic:twitter") {
              const { metadata } = block.data?.twitter || {};

              let tweetUrl = "";
              if (metadata?.url) {
                tweetUrl = metadata.url;
              } else {
                // We need to replace part of the tweet url with the screenname of the account the tweet was posted by
                // Example
                // copied from twitter: https://twitter.com/i/web/status/1522172714953416706
                // value after replacing i/web: https://twitter.com/FootyAccums/status/1522172714953416706

                // Check that the required properties are present in the metadata
                // If not we will return the block as is resulting in now formattedContent for this widget
                // Which will result the widget not being rendered
                if (
                  !metadata?.entities?.urls?.length ||
                  !metadata?.user?.screen_name
                )
                  return block;
                tweetUrl = metadata.entities.urls[0].expanded_url.replace(
                  "i/web",
                  metadata.user.screen_name
                );
              }

              const tweet = tweetUrl ? await getTweet(tweetUrl) : "";
              if (tweet) {
                block.text = tweet;
              }
            }
            return block;
          })
        );
        const newContext = { ...context, blocks };
        // We convert the editor state to HTML
        const convertBlocksToHtml = await contentToHtml(newContext);
        // assign the converted HTML to the widget
        widget.formattedContent = convertBlocksToHtml;
      }
    })
  );
  return allWidgets;
};

interface SlugParams {
  params: {
    slug: string;
    childSlug?: string;
  };
}

export const generatePathFromSlug = (
  allContent: { slug: string; expires_at?: number }[],
  isChildSlug?: boolean,
  parentSlug?: string
): SlugParams[] => {
  const filteredContent = allContent.filter(
    (post) => !post.expires_at || post.expires_at > getCurrentUnixTimestampMs()
  );

  return isChildSlug
    ? filteredContent.map(({ slug }) => ({
        params: { slug: parentSlug ? parentSlug : "news", childSlug: slug },
      }))
    : filteredContent.map(({ slug }) => ({ params: { slug } }));
};

export const checkIfQuestionAndEditorStateContentEmpty = (
  questions: { [key: string]: ContentAccordionQuestion } | undefined
): boolean => {
  // Returns false if we don't have any questions (no editorState and no question)
  if (!questions) return false;

  let hasQuestionContent = false;
  let hasSomeEditorStateContent = false;
  for (const key in questions) {
    if (questions[key].question) hasQuestionContent = true;
    const editorState = questions[key].editorState as string;
    if (editorState && typeof editorState === "string") {
      const formattedEditorState = parseString(editorState);
      hasSomeEditorStateContent = formattedEditorState.blocks.some(
        (block: Block) => {
          return block.text !== "";
        }
      );
    }

    if (hasQuestionContent || hasSomeEditorStateContent) break;
  }

  // Returns true if at least one editorState has content or if a question has content
  return hasQuestionContent || hasSomeEditorStateContent;
};

export const checkAccordionHasContent = (
  widget: ContentAccordionWidget
): boolean => {
  if (widget.questions && Object.keys(widget.questions).length > 0) {
    // Check within these questions if there's any content
    return checkIfQuestionAndEditorStateContentEmpty(widget.questions);
  }
  return false;
};

export const isLocaleInUs = (locale: string): boolean => {
  return locale.toLowerCase().startsWith("us-");
};

export const localisedBetType = ({
  selectionCount,
  locale,
  emptySelectionMessage = "No Selections",
}: {
  selectionCount: number;
  locale: string;
  emptySelectionMessage?: string;
}): string => {
  if (selectionCount === 0) return emptySelectionMessage;

  if (isLocaleInUs(locale))
    return selectionCount === 1 ? "Straight" : `${selectionCount} Leg Parlay`;

  const betType = NON_US_BET_TYPES.get(selectionCount);

  return betType || `${selectionCount} Fold`;
};

export const selectCurrencySymbol = (locale: string): string => {
  if (isLocaleInUs(locale)) {
    return "$";
  } else {
    return "£";
  }
};

export const selectRelatedContentTitle = (
  widgetTitle?: string,
  authorInfo?: AuthorData
): string => {
  if (widgetTitle) return widgetTitle;
  if (authorInfo)
    return `Related ${getAuthorFullNameFromAuthorInfo(authorInfo)} Content`;
  return "Related News";
};

interface RelatedContentLinkProps {
  data?: AuthorPageData[] | ContentPayload[];
  author?: string | null;
  authorInfo?: AuthorData;
  region?: string | null;
}

export const selectRelatedContentViewAllLink = ({
  data,
  author,
  authorInfo,
  region,
}: RelatedContentLinkProps): string => {
  const brand = getActiveBrand();
  let defaultViewAllLink = "/news";
  if (region) {
    const brandConfig = dataMapperLocalisedConfig.get(brand);
    if (brandConfig && !isEmptyObject(brandConfig)) {
      const { newsPath } = getLocalisedConfig({
        locale: region,
        fallbackKey: "default",
        localisedConfig: brandConfig,
      });
      defaultViewAllLink = `/${newsPath}`;
    }
  }

  if (author && authorInfo) return `/author/${authorInfo.slug}`;

  if (!data) return defaultViewAllLink;
  const { parent } = data[0] as ContentPayload;
  const { full_url_path } = parent as ContentParent;
  if (full_url_path) return `/${full_url_path}`;

  return defaultViewAllLink;
};

// Type guard to ensure we render the appropriate layout
export function checkIsPaginatedNewsPagePropsInsteadOfPageProps(
  data: PageProps | PaginatedNewsPageProps
): data is PaginatedNewsPageProps {
  return (
    (data as PaginatedNewsPageProps).content !== undefined &&
    (data as PaginatedNewsPageProps).content !== null
  );
}

/**
 * Sorts an array of content objects based on a provided order array and a specified property to sort by.
 * @param order - An array of string values representing the desired order of the content objects.
 * @param content - An array of content objects to be sorted.
 * @param sortBy - The property of the content objects to sort by. Defaults to "_id".
 * @returns A sorted array of content objects.
 */
export function sortContent<
  T extends Record<string, unknown>,
  K extends keyof T
>(order: string[], content: T[], sortBy: K = "_id" as K): T[] {
  const hasSortByProperty = content.every((item) => sortBy in item);
  if (!hasSortByProperty) return content;
  const orderMap = new Map(order.map((value, index) => [value, index]));
  return content.sort((a, b) => {
    // used a Map here rather than indexOf as it's more performant
    // if the value isn't in the order array, it will be added to the end
    const aIndex =
      orderMap.get(a[sortBy]?.toString() ?? "") ?? order.length + 1;
    const bIndex =
      orderMap.get(b[sortBy]?.toString() ?? "") ?? order.length + 1;
    return aIndex - bIndex;
  });
}

export const getLocalisedContentRegionByBrand = (
  brand: BrandShortNames,
  path: string
): string | null => {
  // check the route is starts with a supported region for the brand
  const getBrandSupportedRegion =
    BRANDS_SUPPORTED_LOCALISED_CONTENT_REGIONS.get(brand);
  // we want to extract the region from the path to check if it's supported
  const region = path?.split("/").filter((segment) => segment)[0];
  if (!getBrandSupportedRegion?.length || !region) return null;
  // we need to check if the region is supported for the brand,
  if (region && getBrandSupportedRegion.includes(region)) return region;
  return null;
};

interface GetViewAllContentPathProps {
  region?: string | null;
  contentPath: string;
}
export const getViewAllContentPath = ({
  region,
  contentPath,
}: GetViewAllContentPathProps) => {
  if (!contentPath) {
    return "";
  }
  const routeArray = routeToArray(contentPath);
  if (region && routeArray.length > 1) {
    return `/${region}/${routeArray[1]}`;
  } else {
    return `/${routeArray[0]}`;
  }
};
