import Image from "next/image";
import { useRouter } from "next/router";
import { getActiveBrand } from "@/lib/brand";
import MainNav from "@/components/nav/MainNav/MainNav";
import styles from "@/components/Header/Header.module.scss";
import SecondaryNav from "@/components/nav/SecondaryNav/SecondaryNav";
import { NavProps } from "@/types/api/nav";
import { UsStates } from "@/types/api/config";
import { isPromotionsPage } from "@/lib/page";
import { LinkNoPrefetch } from "../LinkNoPrefetch/LinkNoPrefetch";

interface Props {
  nav: NavProps;
  isMobileDevice: boolean;
  region: string | null;
  fpStates?: UsStates[];
}

const Header = ({
  nav,
  region,
  isMobileDevice,
  fpStates,
}: Props): JSX.Element => {
  const brand = getActiveBrand();
  const brandLogoUrl = `https://cdn.checkd.media/menu/${brand}/homepage.svg`;
  const homeLink = region ? `/${region}` : "/";
  const router = useRouter();

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.headerBackground}>
        <div
          className={`${styles.headerContainer} ${
            isPromotionsPage(router.asPath)
              ? styles[`headerContainer--ppc`]
              : ""
          }`}
        >
          <div className={styles.headerLogoContainer}>
            <LinkNoPrefetch href={homeLink} data-testid="homepage-link">
              <Image src={brandLogoUrl} alt={`${brand} logo`} fill />
            </LinkNoPrefetch>
          </div>
          {!isPromotionsPage(router.asPath) ? (
            <MainNav
              nav={nav}
              isMobileDevice={isMobileDevice}
              fpStates={fpStates}
              region={region}
            />
          ) : null}
        </div>
      </div>
      <SecondaryNav
        navItems={nav.meta.menu["sub-header"]}
        isMobileDevice={isMobileDevice}
      />
    </div>
  );
};

export default Header;
