import type { NestedBlock } from "../../../types/draftManager";

export default function UnorderedList(): NestedBlock {
  return {
    start: "<li>",
    end: "</li>",
    nestStart: "<ul>",
    nestEnd: "</ul>",
    nest: true,
  };
}
