import { FP_BRAND } from "../../constants";
import type {
  TipData,
  TipsterBrunoResponse,
  TipsterResponse,
} from "../../types/api/tipster";
import { getActiveBrand } from "../brand/brand";
import { getDataFromPathAndQueryParams } from "./helpers/helpers";

export const getCustomTipsByCategory = async (
  categoryId: string
): Promise<TipsterResponse> => {
  const path = "tips";
  const brand = getActiveBrand();
  const queryParams = { categoryId, brand };

  return getDataFromPathAndQueryParams({
    path,
    queryParams,
    disableBrand: true,
  });
};

export const getBrunoTipsterData = async (
  tip: TipData,
  locale: string
): Promise<TipsterBrunoResponse> => {
  const brand = getActiveBrand();
  const { grid, selectedBookmakers } = tip.meta;

  const matchQuery = grid.map((gridData) => {
    const { selection, stats, match } = gridData;

    if (!selection || typeof selection === "string" || !stats?.selection) {
      return;
    }

    if (brand === FP_BRAND && match?.sportType) {
      return `${selection.mappedEventId}-${selection.id}-${stats.selection}-${match.sportType}`;
    }

    return `${selection.mappedEventId}-${selection.id}-${stats.selection}`;
  });

  const matchQueryToString = matchQuery.join();

  const selectedBookmakersQueryToString =
    selectedBookmakers && selectedBookmakers.length > 0
      ? selectedBookmakers.join()
      : null;

  const path = `tipster/widget`;
  const queryParams = {
    query: matchQueryToString,
    brand,
    bookmaker: selectedBookmakersQueryToString,
    location: locale.toUpperCase(),
  };

  return getDataFromPathAndQueryParams({
    path,
    queryParams,
    isOddsPlatformRequest: true,
  });
};
