import {
  faCompetitionNameMap,
  fpCompetitionSlugToNameMap,
} from "@/constants/competitions";
import { contentToHtml } from "@/lib/draftManager/draftManager";
import { ContentForCardWithOptionalUpdatedAt } from "@/types/api/content";
import { createExtract, isLocalisedContentRoute } from "@/lib/content";
import { routeToArray } from "@/lib/array";
import { FP_BRAND, BRAND_DETAILS_MAP } from "@/constants/brands";
import { NCAAF, NCAAB } from "@/constants/competitions";
import { parentPathsByBrand } from "./sitemap";
import { getActiveBrand } from "./brand";

export const joinWordsWithHyphen = (sentence: string) =>
  sentence.split(" ").join("-").toLowerCase();

export const parseString = (text: string) => JSON.parse(text);

export const extractCategoryStringFromFullUrlPath = (fullUrlPath: string) => {
  const brand = getActiveBrand();
  const urlSegments = fullUrlPath.split("/");
  const category = urlSegments[urlSegments.length - 2];
  const isLocalisedContent = isLocalisedContentRoute(fullUrlPath);
  const competition =
    brand === FP_BRAND
      ? fpCompetitionSlugToNameMap.get(category)
      : faCompetitionNameMap.get(category);

  // If there's no competition, then the parent segment is returned
  // i.e. /news/premier-league returns Premier League
  // but /news/article returns news
  // if they are viewing localised content
  // i.e /br/noticias return Noticias
  if (competition) {
    return competition;
  }

  return isLocalisedContent ? urlSegments[1] : urlSegments[0];
};

export const extractCategoryUrlFromFullUrlPath = (fullUrlPath: string) => {
  const brand = getActiveBrand();
  const urlSegments = fullUrlPath.split("/");
  const category =
    urlSegments.length > 1
      ? urlSegments[urlSegments.length - 2]
      : urlSegments[0];
  const competition =
    brand === FP_BRAND
      ? fpCompetitionSlugToNameMap.get(category)
      : faCompetitionNameMap.get(category);

  // If category is not a competition we want to return the category
  if (!competition) {
    return `/${category}`;
  }

  // If the category is a competition we want to return that path to the competition
  return `/${urlSegments.slice(0, urlSegments.length - 1).join("/")}`;
};

// regex to extract script tags
// explanation:
// <script.*?> matches the opening script tag
// .*? match everything between the opening and closing script tag
// <\/script> matches the closing script tag
const scriptRegex = new RegExp(/<script[^>]*?>.*?<\/script>/gs);
export const extractScriptTagsFromHtmlString = (
  htmlString: string
): string[] => {
  const script = htmlString.match(scriptRegex);
  return script || [];
};

export const extractScriptSrc = (scriptTag: string): string => {
  // extract only the url from the scriptTag's src for example: https://www.sporcle.com/embed/embed.js?v=60e6e83a80c2f
  // the regex matches the src attribute and extracts the url
  const url = scriptTag.match(/src=([^ >]+)/)?.[1].replace(/['"]/g, "");
  return url || "";
};

export const capitaliseFirstCharacterInString = (string: string) =>
  `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`;

export const capitaliseFirstCharacterInEveryWord = (string: string) =>
  string
    .split(" ")
    .map((word) => capitaliseFirstCharacterInString(word))
    .join(" ");

export const convertToCamelCase = (string: string) => {
  // find characters broken up by dashes and convert the first character to uppercase
  return string.trim().replace(/(\-|_|\s)+(.)?/g, (match, seperator, char) => {
    return char ? char.toUpperCase() : "";
  });
};

export const removeEmptyElements = (htmlString: string): string => {
  // remove any elements that have a closing tag but no content unless it is an iframe or an img element
  // these are the only elements, that the Editor component uses that are self closing
  return htmlString.replace(/<[^\/>][^>]*><\/[^>]+>/g, (match) => {
    if (
      match.includes("iframe") ||
      match.includes("img") ||
      match.includes("script")
    ) {
      return match;
    }
    return "";
  });
};

export const isValidOptionalString = (
  val: string | undefined | null
): boolean => val !== undefined && val !== null && val !== "";

export const isValidUrlOrPath = (val: string): boolean => {
  // check that the string is a valid URL

  /* 
    Breakdown of the regex:

    - ^ Matches the start of the string, making sure it's a valid URL

    - https? Matches either "http" or "https" at the start of the string

    - :\/\/ Matches "://" that separates the protocol from the rest of the URL.

    - [a-z\d-]+ Matches one or more letters, digits, or hyphens in the domain name, subdomains

    - [/\w.-]* Checks for a valid path, which starts with a forward slash / and is followed by letters, digits,
    underscores _, periods ., and hyphens -, this pattern is used to match the path of the URL

    - (\?[\w&=]+)* Checks for an optional query string, which starts with a question mark ? and is followed by
    ampersands & and equals =, this pattern is used to match the query string of the URL

    $ Matches the end of the string, making sure it's a valid URL

    i Matches case-insensitively.
  
  */

  const urlRegex = new RegExp(
    /^https?:\/\/[a-z\d-]+(\.[a-z\d-]+)+[\/\w.-]*(\?[\w&=]+)*$/i
  );
  // check that the string is a valid URL path
  const urlPathRegex = new RegExp(/^\/([\w\d-]+\/)*([\w\d-]+)?(\?[\w&=]+)*$/i);
  return (
    isValidOptionalString(val) && (urlRegex.test(val) || urlPathRegex.test(val))
  );
};

export const hyphenatedToTitleCase = (hypenatedSentence: string) => {
  const brand = getActiveBrand();

  if (brand === FP_BRAND) {
    // Get competition names from fpCompetitionNameMap
    const competition = fpCompetitionSlugToNameMap.get(
      hypenatedSentence.toLowerCase()
    );

    // Check if any competition exists
    // if so we return uppercased competition in the breadcrumb
    if (competition) {
      return competition;
    }
  }

  return hypenatedSentence
    .toLowerCase()
    .split("-")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

export const codeBlockToHtml = (codeBlock: string): string => {
  const context = parseString(codeBlock);
  return contentToHtml(context);
};

const pagesAndLocaleRegex = new RegExp(/\/locale.*$|(\/pages|\/paginas)\/.*/);
export const removePagesQueryAndLocaleFromPath = (
  routerAsPath: string
): string => {
  const [pathWithoutQuery] = routerAsPath.split("?");
  // regex explanation:
  // This regex will match any string that contains either
  // /locale followed by any characters until the end of the string (.*$), or
  // /pages/ followed by any characters until the end of the string (.*).
  return pathWithoutQuery.replace(pagesAndLocaleRegex, "");
};

export const extractToDisplay = (
  content: ContentForCardWithOptionalUpdatedAt
): string | null => {
  if (content.meta.widgets && content.meta.widgets.length > 0) {
    return createExtract(content.meta.widgets);
  }
  if (content.meta.description) {
    return content.meta.description;
  }
  return null;
};

export const isValidGifDomain = (url?: string): boolean => {
  if (!url) return false;
  return url.startsWith("https://media4.giphy.com");
};

export const addLeadingSlash = (path: string): string => {
  if (path.startsWith("/")) return path;
  return `/${path}`;
};

export const buildSchemaContent = (
  schema: string | undefined
): string | null => {
  if (schema) {
    const text = schema
      .replace('<script type="application/ld+json">', "")
      .replace("</script>", "");

    try {
      const json = JSON.parse(text);
      return typeof json === "object" ? JSON.stringify(json) : null;
    } catch (error) {
      return null;
    }
  }
  return null;
};

export const removeLastSegmentFromPath = (path: string) => {
  const routeArrWithRemovedLastSegment = routeToArray(path).slice(0, -1);

  return addLeadingSlash(routeArrWithRemovedLastSegment.join("/"));
};

export function generateSitemapTitleFromPath(fullUrlPath: string): string {
  const brand = getActiveBrand();
  const supportedParentPaths = parentPathsByBrand.get(brand) || [];
  // regex: remove '-' and '/' from the path to display as title
  const regex = /\/|-/g;
  let title = fullUrlPath.replace(regex, " ");
  // check if url starts with a string in supportedParentPaths
  // eg. predictions or news for FA
  // if so, then add a - between the first path segment and the rest of the path
  // eg. predictions/world-cup => predictions - world cup predictions
  // eg. news/premier-league => news - premier league
  const firstPathSegment = title.split(" ")[0];
  const isSupportedParentPath = supportedParentPaths.includes(firstPathSegment);
  if (isSupportedParentPath && title !== firstPathSegment) {
    title = title.replace(firstPathSegment, `${firstPathSegment} -`);
  }
  return title;
}

export function isInternalLink(url: string): boolean {
  const brand = getActiveBrand();
  const brandDomain = BRAND_DETAILS_MAP[brand].domain;

  return url.includes(brandDomain);
}

export const formattedCategory = (
  authorName: string | undefined,
  category: string
) => {
  const brand = getActiveBrand();

  if (authorName) {
    return authorName.split("-").join(" ");
  }

  if (brand === FP_BRAND) {
    // Get competition names from fpCompetitionNameMap
    const competition = fpCompetitionSlugToNameMap.get(category.toLowerCase());

    // Check if any competitions matches any of the categories
    if (competition === category) {
      return `${competition} News`;
    }

    // If competition !== category and the category returns NCAAF or NCAAB
    if (category === NCAAF || category === NCAAB) {
      return `${category} News`;
    }

    // Otherwise return capitalised category
    return capitaliseFirstCharacterInEveryWord(category).split("-").join(" ");
  }

  return capitaliseFirstCharacterInEveryWord(category).split("-").join(" ");
};
