import { neutrals } from "./crossBrand";
// Rather than trying to come up with color names we're just giving
// setting the key as the next character in the alphabet

export const cmColors = {
  ...neutrals,
  devOrange: "#D74918", // Checkd Dev Orange
  mediaGreen: "#76B837", // Checkd Media Green
  groupBlue: "#009FE3", // Checkd Group Blue
  // !WARN: use the above colours for the brand, colours below are no longer being used
  white: {
    a: "#efeef1",
    b: "#fff",
    c: "rgb(255, 255, 255)",
    d: "rgba(255, 255, 255, 0.5)",
  },
  blue: {
    a: "#009EE3",
    b: "rgb(182, 203, 216)",
  },
  black: {
    a: "#181c1d",
    b: "rgb(24,28,29)",
    c: "rgb(18, 24, 29)",
  },
  grey: {
    a: "#A5A8A9",
    b: "#202729",
    c: "rgba(0,0,0,0.2)",
    d: "rgb(235, 236, 245)",
    e: "rgb(27, 32, 33)",
    f: "rgb(56, 61, 65)",
    g: "rgb(226, 226, 226)",
    h: "rgb(246, 246, 246)",
    i: "rgb(164, 164, 168)",
    j: "rgb(30, 42, 58)",
  },
  green: {
    a: "rgb(68, 219, 94)",
  },
  red: {
    a: "rgb(231, 32, 53)",
  },
  yellow: {
    a: "rgb(239, 229, 46)",
  },
};
