import type { OddsResponse } from "../../types/api/odds";
import type { CommonAndEventIdPair } from "../../types/betslip";
import { commonAndEventIdsToQueryString } from "../betslip/betslip";
import { getActiveBrand } from "../brand/brand";
import { getDataFromPathAndQueryParams } from "./helpers/helpers";

export const getOddsByCommonAndSelectionIdPairs = async (
  commonAndSelectionIds: CommonAndEventIdPair[],
  locale: string
): Promise<OddsResponse> => {
  if (!commonAndSelectionIds.length)
    return Promise.resolve({
      code: 0,
      bookmakers: [],
      selections: [],
    });
  const brand = getActiveBrand();
  const commonIdAndSelectionString = commonAndEventIdsToQueryString(
    commonAndSelectionIds
  ).join(",");
  const path = "smartacca/odds";
  const queryParams = {
    selections: commonIdAndSelectionString,
    location: locale.toUpperCase(),
    brand,
  };

  return getDataFromPathAndQueryParams({
    path,
    queryParams,
    isOddsPlatformRequest: true,
  });
};
