import { useState } from "react";
import type { RawDraftContentState } from "draft-js";
import { CM_BRAND } from "../../../constants";
import { BetStake } from "../../../components/BetStake/BetStake";
import { useLocaleFromRouter } from "../../../hooks/LocationFromRouter";
import { useCheckDesktopDevice } from "../../../hooks/Window";
import type { TipOdds } from "../../../types/api/odds";
import type {
  BookmakerForTip,
  StatsTipsterSelection,
} from "../../../types/api/tipster";
import type { OddsFormats } from "../../../types/odds";
import { getActiveBrand } from "../../../utils/brand/brand";
import { isLocaleInUs } from "../../../utils/content/content";
import { contentToHtml } from "../../../utils/draftManager/draftManager";
import { parseString } from "../../../utils/string/string";
import {
  bookmakersToDisplay,
  validTipsterSignUpLink,
} from "../utils/tipsterUtils";
import { getFormattedOdds } from "../../../utils/odds/odds";
import { CustomTipCta } from "../../../components/CustomTipCta/CustomTipCta";
import { twoDecimalFloor } from "../../../utils/number/number";
import { AngleUpIcon } from "../../../components/icons/AngleUpIcon";
import { AngleDownIcon } from "../../../components/icons/AngleDownIcon";
import styles from "./TipBookmakers.module.scss";

interface TipBookmakersProps {
  bookmakers: BookmakerForTip[];
  odds: TipOdds[];
  selections?: StatsTipsterSelection[];
  bookmakerReason?: string;
  oddsFormat: OddsFormats;
  onClick?: (bookmaker?: string) => void;
}

export function TipBookmakers({
  bookmakers,
  odds,
  selections,
  bookmakerReason,
  oddsFormat,
  onClick,
}: TipBookmakersProps): React.JSX.Element {
  const brand = getActiveBrand();
  const [locale] = useLocaleFromRouter();
  const isInUs = isLocaleInUs(locale);
  const [stake, setStake] = useState(isInUs ? "100.00" : "10.00");
  const [viewMore, setViewMore] = useState(false);
  const isDesktopDevice = useCheckDesktopDevice();

  const handleStakeClick = (event: React.MouseEvent): void => {
    const value = (event.target as HTMLButtonElement).value;
    setStake(value);
  };

  const displayedBookmakers = bookmakersToDisplay(
    odds,
    viewMore,
    isDesktopDevice
  );

  const bookmakerReasonString = bookmakerReason
    ? contentToHtml(parseString(bookmakerReason) as RawDraftContentState)
    : undefined;

  const stakeAmounts = isInUs
    ? ["20.00", "100.00", "250.00", "500.00"]
    : ["1.00", "5.00", "10.00", "20.00", "50.00"];

  return (
    <div>
      <div className={styles.betStakeContainer}>
        <BetStake
          handleStakeClick={handleStakeClick}
          isStatsTipster
          stakeAmounts={stakeAmounts}
          stakeInput="button"
          stakeLabel={isLocaleInUs(locale) ? "Wager" : "Stake"}
          stakeValue={stake}
        />
      </div>

      <div>
        <div className={styles.bookmakers}>
          {displayedBookmakers.map(
            ({
              bookmaker,
              oddsDecimal,
              betslipURL,
              odds: bookmakerOdds,
              oddsAmerican,
            }) => {
              const bookmakerData = bookmakers.find(
                (data) => data.name_cleansed === bookmaker
              );
              const validatedSignUpLink = validTipsterSignUpLink(
                brand,
                bookmakerData,
                locale
              );

              return (
                <CustomTipCta
                  addToBetSlipText={getFormattedOdds(
                    { odds: bookmakerOdds, oddsAmerican, oddsDecimal },
                    oddsFormat
                  ).toString()}
                  bookmaker={bookmaker}
                  ctaUrl={betslipURL}
                  isStatsTipster
                  key={bookmaker}
                  oddsFormat={oddsFormat}
                  onCtaClick={onClick}
                  selections={selections}
                  signUpUrl={
                    brand !== CM_BRAND && validatedSignUpLink
                      ? validatedSignUpLink
                      : undefined
                  }
                  stakeReturns={twoDecimalFloor(Number(stake) * oddsDecimal)}
                />
              );
            }
          )}
        </div>

        {odds.length > (isDesktopDevice ? 4 : 2) ? (
          <div className={styles.viewMoreButtonContainer}>
            <button
              className={styles.viewMoreButton}
              onClick={() => {
                setViewMore(!viewMore);
              }}
              type="button"
            >
              <p>{`View ${viewMore ? "Less" : "More"} Odds`}</p>
              {viewMore ? <AngleUpIcon /> : <AngleDownIcon />}
            </button>
          </div>
        ) : null}

        {bookmakerReasonString ? (
          <div
            className={styles.bookmakerReason}
            dangerouslySetInnerHTML={{ __html: bookmakerReasonString }}
          />
        ) : null}
      </div>
    </div>
  );
}
