const FreeBetIcon = () => (
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
  >
    <title>Icon/Free Bet</title>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="FA_desktop_header"
        transform="translate(-1116.000000, -45.000000)"
        stroke="#FFFFFF"
        strokeWidth="1.6"
      >
        <g id="Nav" transform="translate(244.000000, 27.000000)">
          <g id="Free-Bets" transform="translate(861.000000, 3.000000)">
            <g id="Icon/Free-Bet" transform="translate(12.000000, 16.000000)">
              <polyline
                id="Path"
                points="14.4 8 14.4 16 1.6 16 1.6 8"
              ></polyline>
              <rect id="Rectangle" x="0" y="4" width="16" height="4"></rect>
              <line x1="8" y1="16" x2="8" y2="4" id="Path"></line>
              <path
                d="M8,4 L4.4,4 C3.2954305,4 2.4,3.1045695 2.4,2 C2.4,0.8954305 3.2954305,0 4.4,0 C7.2,0 8,4 8,4 Z"
                id="Path"
              ></path>
              <path
                d="M8,4 L11.6,4 C12.7045695,4 13.6,3.1045695 13.6,2 C13.6,0.8954305 12.7045695,0 11.6,0 C8.8,0 8,4 8,4 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default FreeBetIcon;
