import MobileNav from "@/components/nav/MobileNav/MobileNav";
import DesktopNav from "@/components/nav/DesktopNav/DesktopNav";
import { NavProps } from "@/types/api/nav";
import { UsStates } from "@/types/api/config";

interface Props {
  nav: NavProps;
  isMobileDevice: boolean;
  fpStates?: UsStates[];
  region: string | null;
}

const MainNav = ({
  nav,
  isMobileDevice,
  fpStates,
  region,
}: Props): JSX.Element => {
  return (
    <nav role="navigation" aria-label="Main Nav">
      {isMobileDevice ? (
        <MobileNav
          navItems={nav.meta.menu["mobile-menu"] || nav.meta.menu["header"]}
          fpStates={fpStates}
          region={region}
        />
      ) : (
        <DesktopNav
          navItems={nav.meta.menu.header}
          fpStates={fpStates}
          region={region}
        />
      )}
    </nav>
  );
};

export default MainNav;
