import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { SplitShortDate } from "@/types/date";
import { FP_BRAND } from "@/constants/brands";
import { getActiveBrand } from "./brand";

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const fullDateToShortDate = (date: string): string =>
  dayjs(date).format("DD MMM YYYY");

export const fullDateToShortDateWithTime = (date: string): string =>
  dayjs(date).format("ddd DD MMM HH:mm");

export const fullDateToShortDateWithAMorPM = (date: string): string => {
  const brand = getActiveBrand();
  const timeFormat = brand !== FP_BRAND ? "H:mm" : "h:mmA";
  return dayjs(date).format(`ddd Do MMM ${timeFormat}`);
};

export const fullDateToShortDateWithTheMonthInFull = (
  date: string | number
): string => dayjs(date).format("ddd Do MMMM - HH:mm");

export const fullDateToAmericanShortDateWithAMorPM = (date: string): string =>
  dayjs(date).format("ddd MM/DD, h:mm A");

export const fullDateToSplitShortDate = (fullDate: string): SplitShortDate => {
  const writtenDate = dayjs(fullDate).format("ddd DD MMM");
  const numericalDate = dayjs(fullDate).format("DD/MM/YY");
  const time = dayjs(fullDate).format("HH:mm");

  return {
    writtenDate,
    numericalDate,
    time,
  };
};

export const unixTimestampMsToYearMonthDateHourMinAndSeconds = (
  date: number
): string => dayjs(date).format("YYYY-MM-DDTHH:mm:ss");

export const unixTimestampMsToDayDateMonthYearHourMinAndSeconds = (
  date: number | undefined
): string => dayjs(date).format("ddd DD MMM YYYY HH.mm");

export const unixTimestampMsToDateMonthYear = (date: number): string =>
  dayjs(date).format("DD-MM-YYYY");

export const unixTimestampToDateMonthYearHourAndMins = (
  date: number
): string => {
  const brand = getActiveBrand();
  const timeFormat = brand !== FP_BRAND ? "HH:mm" : "hh:mmA";
  return dayjs(date).format(`DD-MM-YYYY ${timeFormat}`);
};

export const unixTimestampMsFromNow = (date: number): string =>
  dayjs(date).fromNow();

export const getCurrentYearMonthDate = (): {
  year: number;
  month: number;
  date: number;
} => {
  const now = new Date();
  const date = now.getDate();
  // Month is zero-indexed so adding 1 to offset this
  const month = now.getMonth() + 1;
  const year = now.getFullYear();

  return { date, month, year };
};

export const getCurrentUnixTimestampMs = (): number => dayjs().valueOf();

// We can't use `isToday` dayjs plugin as this uses local time
// We need to compare against utc time as this is the time the races are stored in
export const isYyyyMmDdStringTodayInUtc = (yyyyMmDdString: string): boolean => {
  const convertedDate = dayjs().utc().format("YYYY-MM-DD");

  return convertedDate === yyyyMmDdString;
};

// We can't use `isTomorrow` dayjs plugin as this uses local time
// We need to compare against utc time as this is the time the races are stored in
export const isYyyyMmDdStringTomorrowInUtc = (
  yyyyMmDdString: string
): boolean => {
  const convertedTomorrow = dayjs().add(1, "day").utc().format("YYYY-MM-DD");

  return convertedTomorrow === yyyyMmDdString;
};

export const timeStringToLondonTimezone = (isoDateString: string): string =>
  dayjs(isoDateString).tz("Europe/London").format("YYYY-MM-DD");

export const ISODateStringToTimeString = (isoDateString: string) =>
  dayjs(isoDateString).format("HH:mm");

export const shortFormatDate = (date: string) => dayjs(date).format("DD/MM/YY");
export const isoDateStringToDayNameAndTime = (isoDateString: string): string =>
  dayjs(isoDateString).format("ddd h:mma");
