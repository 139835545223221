import { BrandShortNames } from "@/types/brand";
import { FA_BRAND, FP_BRAND, OC_BRAND, TWE_BRAND } from "../brands";
import { BRAZIL_LOCALE } from "../countries";

type DataMapperConfig = {
  default: Record<string, string>;
} & Partial<{ [locale: string]: Record<string, string> }>;

const defaultDataMapperConfig = {
  newsPath: "news",
};

export const dataMapperLocalisedConfig = new Map<
  BrandShortNames,
  DataMapperConfig
>([
  [
    FA_BRAND,
    {
      [BRAZIL_LOCALE]: {
        newsPath: "br/noticias",
      },
      default: defaultDataMapperConfig,
    },
  ],
  [
    FP_BRAND,
    {
      default: defaultDataMapperConfig,
    },
  ],
  [
    TWE_BRAND,
    {
      default: defaultDataMapperConfig,
    },
  ],
  [
    OC_BRAND,
    {
      default: defaultDataMapperConfig,
    },
  ],
]);
