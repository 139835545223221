import type { Entity } from "../../../types/draftManager";

import media from "./atomic:media";
import script from "./atomic:script";
import youtube from "./atomic:youtube";
import twitter from "./atomic:twitter";
import giphy from "./atomic:giphy";

export default function atomic(entity: Entity): React.JSX.Element | null {
  switch (entity.type) {
    case "atomic:media":
      return media(entity);
    case "atomic:script":
      return script(entity);
    case "atomic:youtube":
      return youtube(entity);
    case "atomic:twitter":
      return twitter(entity);
    case "atomic:giphy":
      return giphy(entity);
    default:
      return null;
  }
}
