import { ReactElement } from "react";
import { SportBrandWithTipsShortNames } from "@/types/brand";
import { ContentTipLink } from "@/types/api/content";
import { addLeadingSlash } from "@/lib/string";
import { LinkNoPrefetch } from "@/components/LinkNoPrefetch/LinkNoPrefetch";
import styles from "./SportsTips.module.scss";

interface Props {
  brand: SportBrandWithTipsShortNames;
  tipLinks: ContentTipLink[];
}

export const SportsTips = ({ brand, tipLinks }: Props): ReactElement => {
  const sport = brand === "fa" ? "Football" : "Racing";

  function generateHeadingLink(): ReactElement {
    const link =
      brand === "twe"
        ? "/daily-horse-racing-tips"
        : `/${sport.toLowerCase()}-tips`;
    return (
      <h4 className={styles.footerH4}>
        <LinkNoPrefetch href={link}>{`${sport} Tips`}</LinkNoPrefetch>
      </h4>
    );
  }

  return (
    <div>
      {generateHeadingLink()}
      <ul className={styles.footerTipUl}>
        {tipLinks.map((tipLink) => (
          <li className={styles.footerTipLi} key={`listItem-${tipLink._id}`}>
            <LinkNoPrefetch
              href={addLeadingSlash(tipLink.full_url_path)}
              key={`tipLink-{tipLink._id}`}
            >
              {tipLink.title}
            </LinkNoPrefetch>
          </li>
        ))}
      </ul>
    </div>
  );
};
