import { useCallback, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import styles from "@/components/nav/DesktopNav/DesktopNav.module.scss";
import { NavItem } from "@/types/api/nav";
import DropdownIcon from "@/components/icons/DropdownIcon";
import MenuList from "@/components/nav/MenuList/MenuList";
import NavIcons from "@/components/nav/NavIcons/NavIcons";
import { getActiveBrand } from "@/lib/brand";
import themeSelector from "@/brands/theme";
import { useDetectOutsideClick } from "@/hooks/DetectOutsideClick";
import { LinkNoPrefetch } from "@/components/LinkNoPrefetch/LinkNoPrefetch";
import { UsStates } from "@/types/api/config";

interface Props {
  navItems: NavItem[];
  freeBetsCount?: number;
  fpStates?: UsStates[];
  region: string | null;
}

const brand = getActiveBrand();
const theme = themeSelector(brand);

const DesktopNav = ({ navItems, fpStates, region }: Props): JSX.Element => {
  const secondaryMenu = navItems;
  const [showMoreMenuButton, toggleShowMoreMenuButton] =
    useState<boolean>(true);
  const [moreMenuItems, setMoreMenuItems] = useState<NavItem[]>([]);
  const [showMoreMenu, toggleShowMoreMenu] = useState<boolean>(false);
  const [moreMenuOffsetLeft, setMoreMenuOffsetLeft] = useState<number>(0);
  const [activePath, setActivePath] = useState("");
  const innerMenuRef = useRef(null);
  const moreMenuRef = useRef<HTMLButtonElement>(null);
  const router = useRouter();

  useEffect(() => {
    const path = router.asPath;
    setActivePath(path);
  }, [router.isReady, router.asPath]);

  // Detect Wrapped Items
  const startDetection = useCallback(() => {
    const menuContainer = innerMenuRef.current;
    // More button offset is used to position the popup
    const moreButton = moreMenuRef.current;
    const moreButtonOffsetValue = moreButton ? moreButton.offsetLeft : 0;
    setMoreMenuOffsetLeft(moreButtonOffsetValue);

    // Here is where we handle the wrapped items
    const handleWrappedItems = (menuItems: HTMLUListElement | null) => {
      const menuItemChildren = menuItems ? Array.from(menuItems.children) : [];
      const wrappedMenuItems = menuItemChildren.filter((menuItem) =>
        menuItem.classList.contains("wrapped")
      );

      if (wrappedMenuItems.length === 0) {
        toggleShowMoreMenuButton(false);
      } else {
        toggleShowMoreMenuButton(true);
      }

      // We store the wrapped items into the moreMenu state if the pathname and url match
      setMoreMenuItems([
        ...secondaryMenu.filter((menuItem) =>
          wrappedMenuItems.some(
            (wrappedItem) =>
              wrappedItem.children[0].getAttribute("href") === menuItem.url
          )
        ),
      ]);
    };

    // Function which detects which menu items wrap onto the next line
    const detectWrap = (menuContainer: HTMLUListElement | null) => {
      const links = menuContainer ? Array.from(menuContainer.children) : [];

      links.map((listItem) => {
        // If they wrap then we add a wrapped className otherwise we remove it
        if (
          menuContainer &&
          listItem instanceof HTMLLIElement &&
          listItem.offsetTop > menuContainer.offsetTop
        ) {
          if (
            secondaryMenu.some(
              (menuItem) =>
                menuItem.url === listItem.children[0].getAttribute("href")
            )
          ) {
            listItem.classList.add("wrapped");
          }
        } else {
          listItem.classList.remove("wrapped");
        }
        return null;
      });
      handleWrappedItems(menuContainer);
    };

    detectWrap(menuContainer);
  }, [secondaryMenu]);

  useEffect(() => {
    startDetection();

    window.addEventListener("resize", startDetection);

    return () => {
      window.removeEventListener("resize", startDetection);
    };
  }, [startDetection]);

  function closeMenu() {
    toggleShowMoreMenu(!showMoreMenu);
  }

  const menuListRef = useDetectOutsideClick(closeMenu, moreMenuRef);

  const isActive = (url: string) => {
    const activePathSegments = activePath.split("/");
    const urlSegments = url.split("/");

    // activePathSegments[0] is always an empty string ''
    // We check activePathSegments[1] because this contains the top level page name
    return activePathSegments[1] === urlSegments.pop();
  };

  return (
    <div className={styles.desktopMenuContainer}>
      <ul className={styles.desktopMenuUl} ref={innerMenuRef}>
        {secondaryMenu.map((navItem) => (
          <li className={styles.desktopMenuLi} key={navItem.id}>
            <LinkNoPrefetch
              className={`${styles.desktopMenuLink} ${
                isActive(navItem.url) ? `${styles.active}` : ""
              }`}
              href={navItem.url}
              key={navItem.id}
            >
              {navItem.title}
            </LinkNoPrefetch>
          </li>
        ))}
      </ul>
      {showMoreMenuButton && (
        <button
          className={styles.desktopMenuMoreButton}
          onClick={() => {
            toggleShowMoreMenu(!showMoreMenu);
          }}
          aria-pressed={showMoreMenu}
          ref={moreMenuRef}
        >
          <span className={styles.desktopMenuMoreSpan}>
            More <DropdownIcon fill={theme?.header?.fontColor} />
          </span>
        </button>
      )}
      {showMoreMenu && (
        <div
          className={styles.desktopMenuMorePopupContainer}
          style={{ left: `${moreMenuOffsetLeft - 275}px` }}
        >
          <div
            className={styles.desktopMenuMorePopup}
            data-testid="moreMenuContainer"
          >
            <MenuList
              navItems={moreMenuItems}
              closeMenu={closeMenu}
              ref={menuListRef as React.RefObject<HTMLUListElement>}
              region={region}
            />
          </div>
        </div>
      )}
      <NavIcons fpStates={fpStates} region={region} />
    </div>
  );
};

export default DesktopNav;
