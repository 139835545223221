import type { PredictionResult } from "../../types/api/predictions";
import styles from "./FormIcons.module.scss";

interface FormIconProps {
  form: PredictionResult[];
  height?: string;
  heightForLastMatch?: string;
  width?: string;
  widthForLastMatch?: string;
  margin?: string;
  marginForLastMatch?: string;
  enlargeForLaptops?: boolean;
}

const ariaLabels = new Map<PredictionResult, string>([
  ["W", "win"],
  ["D", "draw"],
  ["L", "loss"],
  ["LW", "win (last season)"],
  ["LD", "draw (last season)"],
  ["LL", "loss (last season)"],
]);

// Default values for MatchOverview widget
export function FormIcons({
  form,
  height = "4px",
  heightForLastMatch = "8px",
  width = "4px",
  widthForLastMatch = "8px",
  margin = "0 0 0 2px",
  marginForLastMatch = "0 2px",
  enlargeForLaptops = true,
}: FormIconProps): React.JSX.Element {
  return (
    <>
      {form.map((formResult, index) => {
        const isLastMatch = index === form.length - 1;
        const className = `${styles.icon} ${styles[`icon--${formResult}`]} ${
          enlargeForLaptops
            ? styles[`icon--${isLastMatch ? "lastLaptopIcon" : "laptopIcon"}`]
            : null
        }`;
        const styling = {
          height: isLastMatch ? heightForLastMatch : height,
          width: isLastMatch ? widthForLastMatch : width,
          margin: isLastMatch ? marginForLastMatch : margin,
        };

        return (
          <li
            aria-label={ariaLabels.get(formResult)}
            className={className}
            key={index}
            style={styling}
          />
        );
      })}
    </>
  );
}
