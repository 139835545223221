import { SportsBrandThemeNames } from "@/types/brand";

export type MediaSize =
  | "original"
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "x2l"
  | "x3l";

export function mediaUrlFromSlug(slug: string, size: MediaSize = "original") {
  if (!slug) throw new Error("Slug cannot be an empty string");
  if (slug.endsWith("-gif"))
    return `https://cdn.checkd.media/images/${slug}.gif`;
  return `https://cdn.checkd.media/images/${slug}-${size}.jpg`;
}

export const bannerLogoUrlFromName = (name = "") => {
  return `https://cdn.checkd-dev.com/bettinghub/bookies/web/${name
    .toLowerCase()
    .replace(/\s/g, "_")}.png`;
};

export const sportBrandFooterLogoMap: Record<SportsBrandThemeNames, string> = {
  fa: "https://cdn.checkd.media/logos/fa-logo-light.svg",
  "fa-dark": "https://cdn.checkd.media/logos/fa-logo-dark.svg",
  fp: "https://cdn.checkd.media/logos/fp-logo-full.png",
  twe: "https://cdn.checkd.media/logos/twe-logo-full.png",
  oc: "https://cdn.checkd.media/logos/oc-logo-full-new.png",
};

export const sportBrandFooterLegislationLogoMap: Record<
  SportsBrandThemeNames,
  string
> = {
  fa: "https://cdn.checkd.media/logos/gambleaware-helpline-logo-blue.png",
  "fa-dark":
    "https://cdn.checkd.media/logos/gambleaware-helpline-logo-white.png",
  fp: "https://cdn.checkd.media/logos/fp_1800_gambler.png",
  twe: "https://cdn.checkd.media/logos/gambleaware-helpline-logo-white.png",
  oc: "https://cdn.checkd.media/logos/gambleaware-helpline-logo-white.png",
};

export const idToTeamBadgeImageUrl = (id: string): string =>
  `https://cdn.checkd-dev.com/football/teams/150x150/${id}.png`;

export const idToCompetitionBadgeImageUrl = (id: string): string =>
  `https://cdn.fantasyiteam.com/bethub/competitions/150x150/${id}.png`;

export const competitionLogoSVG = (id: string): string =>
  `https://cdn.fantasyiteam.com/bethub/competitions/svg/${id}.svg`;

export const racingRunnerSilkUrl = (id: string): string =>
  `https://cdn.checkd-dev.com/racinghub/silks/${id}.png`;

export const getBookmakerLogo = (bookmaker: string): string =>
  `https://static.teamfa.com/accatracker/bookmaker/${bookmaker}.png`;

export const fpTeamLogoUrlFromId = (id: string) => {
  return `https://cdn.checkd-dev.com/bettinghub/teams/${id}.png`;
};

export const hideBrokenImage = (event: HTMLImageElement) => {
  return (event.style.opacity = "0");
};
