import type { ChangeEvent, ReactElement } from "react";
import React, { useState } from "react";
import Image from "next/image";
import type { BookmakerForTip, TipData } from "../../../types/api/tipster";
import type { OddsFormats } from "../../../types/odds";
import { getActiveBrand } from "../../../utils/brand/brand";
import { FP_BRAND } from "../../../constants";
import {
  calculateStakeReturnsFromOdds,
  isValidIntegerOrDecimal,
  twoDecimalFloor,
} from "../../../utils/number/number";
import { useLocaleFromRouter } from "../../../hooks/LocationFromRouter";
import {
  isLocaleInUs,
  selectCurrencySymbol,
} from "../../../utils/content/content";
import {
  betBuilderTipsterGaEventParamsFromTip,
  gaOfferPressedEvent,
  getBestOddsFromBookmakers,
  validTipsterSignUpLink,
} from "../utils/tipsterUtils";
import {
  getCurrentUnixTimestampMs,
  unixTimestampToDateMonthYearHourAndMins,
} from "../../../utils/date/date";
import { getFormattedOdds } from "../../../utils/odds/odds";
import { BetStake } from "../../../components/BetStake/BetStake";
import { bannerLogoUrlFromName } from "../../../utils/media/media";
import styles from "./BetBuilderTipster.module.scss";
import { BetBuilderTipsterBrunoMatchDetails } from "./BetBuilderTipsterBrunoMatchDetails";
import { BetBuilderTipsterNonBrunoMatchDetails } from "./BetBuilderTipsterNonBrunoMatchDetails";

export interface BetBuilderTipsterProps {
  tip: TipData;
  bookmakers: BookmakerForTip[];
  oddsFormat: OddsFormats;
}

export function BetBuilderTipster({
  tip,
  bookmakers,
  oddsFormat,
}: BetBuilderTipsterProps): ReactElement {
  const brand = getActiveBrand();
  const initialVisibleReasons = tip.meta.grid.map(() => false);

  const [visibleReasons, setVisibleReasons] = useState<boolean[]>(
    initialVisibleReasons
  );

  const [extraBookmakersVisible, setExtraBookmakersVisible] =
    useState<boolean>(false);

  const [stakeInput, setStakeInput] = useState<"button" | "input">("button");
  const [stake, setStake] = useState<string>(
    brand === FP_BRAND ? "100.00" : "10.00"
  );

  const handleStakeClick = (event: React.MouseEvent): void => {
    const value = (event.target as HTMLButtonElement).value;
    setStakeInput("button");
    setStake(value);
  };

  const handleStakeChange = (event: ChangeEvent): void => {
    setStakeInput("input");

    const value = (event.target as HTMLInputElement).value;

    if (!isValidIntegerOrDecimal(value)) {
      return;
    }

    setStake(value);
  };

  const updateReasonVisibility = (reasonIndex: number): void => {
    const newArr = [...visibleReasons];
    newArr[reasonIndex] = !visibleReasons[reasonIndex];
    setVisibleReasons(newArr);
  };

  const { meta, starts_at: startsAt, expires_at: expiresAt } = tip;
  const isPoweredByBruno = meta.hasPoweredByBruno ?? false;

  const slicedBookmakers = extraBookmakersVisible
    ? meta.bookmakers
    : meta.bookmakers.slice(0, 3);

  const [locale] = useLocaleFromRouter();
  const currencySymbol = selectCurrencySymbol(locale);

  const stakeAmounts =
    brand === FP_BRAND
      ? ["20.00", "100.00", "250.00", "500.00"]
      : ["1.00", "5.00", "10.00", "20.00", "50.00"];

  const bookmakersSortedByBestOdds = getBestOddsFromBookmakers(meta.bookmakers);

  const handleOfferClick = (bookmaker?: string) => {
    if (meta.event) {
      const { competition, event, tipster, bettingTip } =
        betBuilderTipsterGaEventParamsFromTip({
          event: meta.event,
          grid: meta.grid,
          title: meta.title,
          isPoweredByBruno,
        });

      gaOfferPressedEvent({
        bookmaker,
        competition,
        event,
        tipster,
        bettingTip,
      });
    }
  };

  return (
    <>
      {meta.event && expiresAt > getCurrentUnixTimestampMs() ? (
        <div className={styles.container} data-testid="tipContainer">
          <div className={styles.containerHeader}>
            <div className={styles.title}>
              <div>
                <span>{meta.title}</span>
              </div>
              <div className={styles.oddsBox} data-testid="oddsBox">
                <span>
                  {getFormattedOdds(
                    {
                      odds: `${bookmakersSortedByBestOdds[0].odds}`,
                      oddsAmerican: bookmakersSortedByBestOdds[0].oddsAmerican,
                      oddsDecimal: bookmakersSortedByBestOdds[0].oddsDecimal,
                    },
                    oddsFormat
                  )}
                </span>
              </div>
            </div>
            <span className={styles.startDate}>
              *Odds available as of{" "}
              {unixTimestampToDateMonthYearHourAndMins(startsAt)}
            </span>
          </div>

          <div className={styles.matchInfo}>
            {/* Extra conditions used for correct type inference */}
            {isPoweredByBruno &&
            ("competition_name" in meta.event ||
              "competition_id" in meta.event) ? (
              <BetBuilderTipsterBrunoMatchDetails brunoEventData={meta.event} />
            ) : null}

            {!isPoweredByBruno && "competition" in meta.event ? (
              <BetBuilderTipsterNonBrunoMatchDetails eventData={meta.event} />
            ) : null}
          </div>

          {meta.tipReason ? (
            <div className={styles.tipReason}>
              <p>{meta.tipReason}</p>
            </div>
          ) : null}

          <div
            className={styles.allSelections}
            data-testid="selectionsContainer"
          >
            {meta.grid.map(({ selection, reason }, reasonIndex) => {
              const isVisible = visibleReasons[reasonIndex];

              if (typeof selection !== "string") return;

              return (
                <div className={styles.selection} key={reasonIndex}>
                  <span>{selection}</span>
                  {reason ? (
                    <button
                      aria-pressed={isVisible}
                      onClick={() => {
                        updateReasonVisibility(reasonIndex);
                      }}
                      type="button"
                    >
                      Reason
                    </button>
                  ) : null}
                  {isVisible ? (
                    <span className={styles.reason}>{reason}</span>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>

          <BetStake
            handleStakeChange={handleStakeChange}
            handleStakeClick={handleStakeClick}
            isBetBuilderTipster
            stakeAmounts={stakeAmounts}
            stakeInput={stakeInput}
            stakeLabel={isLocaleInUs(locale) ? "Wager" : "Stake"}
            stakeValue={stake}
          />

          <div className={styles.bookmakers}>
            <div className={styles.bookmakersHeader}>
              <span>Sportsbook & Returns</span>
              <span>Odds</span>
              <span>Offers</span>
            </div>

            {slicedBookmakers.map(
              ({ odds, oddsAmerican, oddsDecimal, bookmaker, url }, index) => {
                const formattedOdds = getFormattedOdds(
                  { odds: `${odds}`, oddsAmerican, oddsDecimal },
                  oddsFormat
                );
                const [numerator, denominator] = `${odds}`
                  .split("/")
                  .map(Number);

                const chosenBookmaker = bookmakers.find((b) => {
                  return b._id === bookmaker;
                });

                const stakeReturns = twoDecimalFloor(
                  calculateStakeReturnsFromOdds(
                    parseFloat(stake),
                    numerator,
                    denominator
                  )
                );

                const validatedTipsterSignupLink = validTipsterSignUpLink(
                  brand,
                  chosenBookmaker,
                  locale
                );
                const signUpLink = validatedTipsterSignupLink
                  ? validatedTipsterSignupLink
                  : "";

                return chosenBookmaker ? (
                  <div
                    className={styles.bookmakersRow}
                    data-testid="bookmakerRow"
                    key={index}
                  >
                    <div className={styles.bookmakersLogoAndReturns}>
                      <div>
                        <Image
                          alt={`${chosenBookmaker.name} logo`}
                          height={40}
                          src={bannerLogoUrlFromName(
                            chosenBookmaker.name_cleansed
                          )}
                          width={40}
                        />
                      </div>
                      <div
                        className={styles.returns}
                        data-testid="stakeReturns"
                      >
                        <span>
                          {currencySymbol}
                          {stakeReturns}
                        </span>
                      </div>
                    </div>
                    <div>
                      <a
                        data-testid="bookmakerLink"
                        href={url}
                        onClick={() => {
                          handleOfferClick(chosenBookmaker.name);
                        }}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <button className={styles.bookmakerLink} type="button">
                          {formattedOdds}
                        </button>
                      </a>
                    </div>
                    <div>
                      {signUpLink !== "" ? (
                        <a data-testid="signUpLink" href={signUpLink}>
                          <button
                            className={styles.bookmakerLink}
                            type="button"
                          >
                            Sign up
                          </button>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : null;
              }
            )}

            {meta.bookmakers.length > 3 ? (
              <div className={styles.moreOddsContainer}>
                <button
                  onClick={() => {
                    setExtraBookmakersVisible(!extraBookmakersVisible);
                  }}
                  type="button"
                >
                  {!extraBookmakersVisible ? "More odds" : "Show less odds"}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
}
